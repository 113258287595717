import { useEntityFullBySlug } from '../modules/entity/Entity';
import { typeConfigs } from "../components/DataTypes";

const Render = (props) => {
  const typeConfig = typeConfigs[props?.taxonomyType?.data?.type];

  if (!typeConfig || !typeConfig.Render) {
    return null;
  }

  return typeConfig.Render(props);
};

export const EntityTaxonomyForm = (props) => {
  const {
    taxonomyTypesDocList
  } = props;

  return (
    <>
      {taxonomyTypesDocList?.map((taxonomyTypeDoc) => {
        const { id, data } = taxonomyTypeDoc;
        if (data?.deleted) { return null; }
        return (
          <div key={id} className='p-1'>
            <Render  
              {...props}
              taxonomyType={taxonomyTypeDoc}
            />
          </div>
        );
      })}
    </>
  );
};

const useEntityTaxonomyForm = ({ entitySlug, fieldsRequired=[], setFieldsRequired }) => {
  const onFetch = ({ taxonomyTypesDocList }) => {
    const addFieldsRequired = [];
    taxonomyTypesDocList.forEach((taxonomyType) => {
      if (taxonomyType.data.required && !taxonomyType.data.deleted) {
        addFieldsRequired.push(taxonomyType.data.nameSlug);
      }
    });
    setFieldsRequired([ ...fieldsRequired, ...addFieldsRequired ]);
  }
  
  const { taxonomyTypesDocList, ...fns } = useEntityFullBySlug({ entitySlug, onFetch });

  return {
    taxonomyTypesDocList,
    ...fns,
    EntityTaxonomyForm: (props) => (
      <EntityTaxonomyForm 
        entitySlug={entitySlug}
        taxonomyTypesDocList={taxonomyTypesDocList} 
        fieldsRequired={fieldsRequired} 
        {...props}
      />
    )
  };
}

export default useEntityTaxonomyForm;