import _ from 'lodash';
import { typeConfigs } from "../DataTypes";

const RenderFilter = (props) => {
  const typeConfig = typeConfigs[props?.taxonomyType?.type];
  
  if (!typeConfig || !typeConfig.RenderFilter) {
    return null;
  }

  return typeConfig.RenderFilter(props);
};

const FormFiltersRender = (props) => {
  let {
    form,
    values,
    filterMenuTaxonomyTypes,
    renderFilterParams,
    showClearBtns = true,
    classes,
    paramsByFieldSlug
  } = props;

  const getFilterClasses = (filterPopulated) => {
    let styleDef = _.assign({}, filterPopulated?.taxonomyType?.style, filterPopulated?.taxonomyType?.filter?.style, filterPopulated?.filter?.style);
    return `
      ${styleDef.classes?.filterContainer || ''} ${props?.classes?.filterContainer || ''} ${(values?.hasOwnProperty(filterPopulated?.taxonomyType?.nameSlug)) ? classes?.selectedFilter : ''}
    `;
  };

  const canShow = (filterPopulated) => {
    if (paramsByFieldSlug && paramsByFieldSlug[filterPopulated.taxonomyType.nameSlug] && paramsByFieldSlug[filterPopulated.taxonomyType.nameSlug].hasOwnProperty('isShow')) {
      return paramsByFieldSlug[filterPopulated.taxonomyType.nameSlug].isShow;
    }
    return true;
  };

  return (
    filterMenuTaxonomyTypes ? (
      filterMenuTaxonomyTypes.map((filterPopulated) => (
        filterPopulated?.id && filterPopulated?.taxonomyType && canShow(filterPopulated) ? (
          // filter container
          <div key={filterPopulated.id}
          className={getFilterClasses(filterPopulated)}>
            {/* form filter fields */}
            <RenderFilter {...{
              ...filterPopulated, // adds { id, filter, taxonomyType }
              values,
              overrideParams: renderFilterParams,
              paramsByFieldSlug
            }} />
          </div>
        ) : ''
      ))
    ) : ''
  );
}

export default FormFiltersRender;