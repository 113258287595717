import EntityFilterMenuDisplay from '../EntityFilterMenuDisplay';

const entitySlug = "lotes";
const filterMenuSlug = "home";

export default function HeaderHeroBlock({ data, UnderText }) { 
  return ( 
    <header id='inicio' className="md:mt-16 mt-12 bg-white"> 

      {/* hero */}
      <div className="flex flex-col lg:flex-row gap-10">
        {/* Imágen de portada para pantallas pequeñas */}
        <div className="flex lg:basis-1/2 lg:hidden">
          <img 
            src={data.backgroundImageUrl} 
            alt="plato de comida"
            className=" h-[30vh] w-full sm:h-[40vh] md:h-[45vh] object-cover"
          />
        </div>

        {/* Texto principal de la portada */}
        <div className="flex-col lg:basis-2/3 p-8 xl:px-20 2xl:px-24">

          <div className="sm:px-20 md:px-10 lg:pt-10 xl:pt-16">
            {/* <img src="/logo-icono-color.svg" alt={data.mainTitle} className="h-44 mx-auto relative md:h-60 " />
            <h1 className="text-4xl">{data.mainTitle}</h1> */}
            <h2 className="text-left text-gray-900 font-bold font-brand-montserrat lg:pr-8 xl:pr-24 text-4xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] leading-tight mb-4 sm:mb-5 animate-fadeIn">{data.mainTitle}</h2>
            <p className="text-left font-brand-montserrat text-xl md:text-[22px] 2xl:text-2xl lg:pr-20 xl:pr-48 mb-4 animate-fadeIn">{data.mainSubTitle}</p>
          </div>
          
          {UnderText ? (
            <UnderText />
          ) : null}
        </div>
        
        {/* Imágen de portada para pantallas grandes */}
        <div className="lg:basis-1/2 hidden lg:block">
          <img 
          src={data.backgroundImageUrl} 
          alt="plato de comida"
          className="w-full h-[85vh] object-cover "
          />
        </div>
      </div>

      {/* filters  
      <div className="px-5 lg:px-20 pt-5 md:pt-10 mx-auto relative z-10 container-width">
      <EntityFilterMenuDisplay
        entitySlug={entitySlug}
        filterMenuSlug={filterMenuSlug}
        style={'search-compact'}
      />
      </div>
      */}

    </header>
  )
}


// assets 

// - logo principal work food con sus distintas variaciones ( color y texto extra)
// - botones oficiales de android y iOS