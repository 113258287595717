import { useEffect,useMemo,useState } from "react";
import _ from 'lodash';
import { FaCheck, FaPlus } from "react-icons/fa";
import { priceFormat, getImageURL } from "../../libs/utils";
import EntityDocView from '../../components/EntityDocView';
import config from "../../config";
import BtnLoading from "../../components/ui/BtnLoading";
import dayjs from 'dayjs';
import toast from "react-hot-toast";
import { IonButton, IonDatetime } from "@ionic/react";
import SwitchInputB from "../../components/Form/SwitchInputB";


export const CartItemForPlanning = ({ 
  selectedPlan, institutionsDocsById, onSave,
  index, items, itemsTypes
}) => {
  const [dateSetted, setDate] = useState();
  const [itemsBag, setItemsBag] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLimit, setLimit] = useState();
  const [specialPricesById, setSpecialPricesById] = useState({});
  const [filterName, setFilterName] = useState('');
  const [filterBySelected, setFilterBySelected] = useState(false);

  useEffect(() => {
    const itemDate = dayjs(selectedPlan?.data?.startDate).utc().toISOString();
    setDate(itemDate);
    setItemsBag( selectedPlan?.data?.itemsBag || [] );
    setLimit( selectedPlan?.data?.isLimit );
    setSpecialPricesById(selectedPlan?.data?.specialPricesById || {});
  }, [selectedPlan]);

  const getDocsByType = (itemType) => {
    return items.filter((item) => {
      return item.data.itemType === itemType.id;
    })
  };

  const isItemInBag = (item) => {
    return itemsBag?.find(itemInBag => itemInBag.id === item.id);
  };

  const dataItemsByType = useMemo(() => {
    let dataItemsByType = [];
    let filterNameLower = filterName.toLowerCase();
    itemsTypes?.forEach(itemType => {
      let itemsByType = getDocsByType(itemType);
      if (filterName) {
        itemsByType = itemsByType.filter(item => item.data.name.toLowerCase().includes(filterNameLower));
      }
      if (itemsByType.length) {
        dataItemsByType.push({
          id: itemType.id,
          name: itemType.data.name,
          items: itemsByType
        });
      }
    });
    return dataItemsByType;
  }, [items, itemsTypes, selectedPlan, filterName]);

  const dataItemsByTypeFiltered = useMemo(() => {
    let dataItemsByTypeFiltered = [];
    dataItemsByType?.forEach(itemType => {
      let itemsByType = itemType.items.filter(item => {
        return isItemInBag(item);
      });
      if (itemsByType.length) {
        dataItemsByTypeFiltered.push({
          id: itemType.id,
          name: itemType.name,
          items: itemsByType
        });
      }
    });
    return dataItemsByTypeFiltered;
  }, [dataItemsByType, itemsBag, filterBySelected]);

  const save = async () => {
    if (selectedPlan?.data) {
      if (!verifyExtra()) {
        selectedPlan.data.startDate = dayjs(dateSetted).utc().startOf('day').toISOString();
        selectedPlan.data.itemsBag = itemsBag;
        selectedPlan.data.specialPricesById = specialPricesById;
        selectedPlan.data.isLimit = isLimit;
        onSave && await onSave(selectedPlan);
      }
      else {
        toast.error('Debe seleccionar una guarnición');
      }
    }
  };

  const verifyExtra = () => {
    let needExtra = false;
    itemsBag.forEach(itemInBag => {
      const doc = items.find(item => item.id === itemInBag.id);
      if (doc?.data?.withExtra) {
        needExtra = true;
      }
    });
    if (needExtra) {
      const hasExtras = itemsBag.filter(itemInBag => {
        const doc = items.find(item => item.id === itemInBag.id);
        return doc?.data?.itemType === config.modules.cart.withExtraItemTypeId;
      });
      needExtra = !hasExtras?.length;
    }
    return needExtra;
  };

  const toggleToBag = (item) => {
    if (isItemInBag(item)) {
      const updatedItemsBag = itemsBag.filter(itemInBag => itemInBag.id !== item.id);
      setItemsBag(updatedItemsBag);
    } else {
      setItemsBag([ ...itemsBag, {
        id: item.id,
      }]);
    }
  };

  const handleAccordionClick = (index, e) => {
    setActiveIndex(index === activeIndex ? null : index);
    setTimeout(() => {
      e.target.scrollIntoView({ behavior: 'smooth' });
    }, 600);
  };

  const getItemsToShow = () => {
    if (filterBySelected) {
      return dataItemsByTypeFiltered;
    }
    return dataItemsByType;
  };

  return (
    <div className="flex flex-col max-h-full">

      <div className="px-4 py-3 lg:py-4 bg-white shadow flex flex-row place-content-between pr-14 gap-2">
        <div className="flex flex-row gap-4 items-center leading-3">
          <div>
            <span className="text-gray-400 mr-1 text-2xl font-semibold">#</span>
            <span className="text-4xl font-bold tracking-tighter">{selectedPlan?.data?.num}</span>
          </div>
          <div className="-mt-0.5">
            <div className="text-xs text-gray-400 font-semibold uppercase">Desde</div>
            <div className="text-md font-semibold">{dayjs(dateSetted).utc().format('LL')}</div>
          </div>
        </div>
        <div>
          <BtnLoading
            label="Guardar"
            onClickAsync={save}
            withLoading
            disabled={!dateSetted}
            colorClass="text-white"
            className="mt-1.5 px-2 py-1 text-xs text-white hover:scale-105 bg-brand-red shadow-none"
          />
        </div>
      </div>

      <div className="overflow-y-scroll px-4 pt-4 pb-32">
        <div className="mb-4 text-center text-lg font-semibold">
          {selectedPlan?.data.institutionId ? (
            <div className="text-gray-700">
              {institutionsDocsById[selectedPlan.data.institutionId].data.name}
            </div>
          ) : (
            <div className="text-gray-400">
              GENERAL
            </div>
          )}
        </div>
        <div className="mb-10">
          <IonDatetime
            value={dateSetted}
            onIonChange={e => setDate(new Date(e.target.value).toISOString())}
            locale="es-ES"
            presentation="date"
            className="mx-auto"
          />
        </div>

        <div className="space-x-2 text-center">
          <span>Fecha máxima</span>
          <SwitchInputB
            value={isLimit} 
            onChange={() => setLimit(!isLimit)}
            {...{
              colorTrue: "blue-700",
              // colorFalse,
              textTrue: 'si',
              textFalse: 'no',
              // disabled
            }}>
          </SwitchInputB>
        </div>

        {/* si no es fecha máxima */}
        {!isLimit ? (<>
          <hr className="border border-gray-200 mx-6 my-4" />

          <div className="mb-4 flex flex-row items-center gap-2 place-content-center">
            {filterBySelected ? (
              <IonButton onClick={() => setFilterBySelected(false)} fill="solid" color="medium" size="small" className="text-xs">
                Todos
              </IonButton>
            ) : (
              <IonButton onClick={() => setFilterBySelected(true)} fill="solid" color="secondary" size="small" className="text-xs">
                Ver
              </IonButton>
            )}
            <span className="font-extrabold text-sm rounded-md px-2 py-0.5 bg-gray-500 text-white">{itemsBag.length}</span>
            seleccionados
            <IonButton onClick={() => { setItemsBag([]); setSpecialPricesById({}) }} fill="solid" color="light" size="small" className="text-xs">
              Vaciar
            </IonButton>
          </div>

          <div className="mb-4">
            <div className="w-64 max-w-64 mx-auto flex flex-row items-center gap-1 place-content-center shadow border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              <input
                type="text"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                className="py-2 px-3 appearance-none focus:outline-none text-center"
                placeholder="Buscar por nombre"
              />
              {filterName ? (
                <button type="button" onClick={() => setFilterName('')} className="">
                  <svg
                    class="fill-current h-6 w-6 text-gray-500"
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                  </svg>
                </button>
              ) : null}
            </div>
          </div>

          {getItemsToShow()?.map((itemType, index) => (
            <div key={itemType.id}>
              <button 
                onClick={(e) => handleAccordionClick(index, e)}
                className={`w-full text-center text-xl font-semibold py-2 ${ index === activeIndex ? 'text-brand-red' : 'text-gray-500' }`}
              >
                {itemType.name}
              </button>
              
              {index === activeIndex ? (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-5 mb-10 mt-4">
                  {itemType.items.map((item) => (
                    <EntityDocView
                    key={item.id}
                    doc={item}
                    entitySlug={config.modules.cart.cartItemEntitySlug}
                    classes={{
                      fieldLabel: "hidden"
                    }}
                    render={({ ViewData, mainAttr, mainImgAttr }) => (
                      <div className={`
                        flex-1 rounded-xl overflow-hidden shadow hover:shadow-md grow
                        ${isItemInBag(item) ? 'border border-brand-red shadow-brand-red bg-brand-red' : 'border border-gray-300'}
                      `}>
                        {/* image xs */}
                        <img src={item?.data && item.data[mainImgAttr] && item.data[mainImgAttr].length && getImageURL(item.data[mainImgAttr][0], 'xs')} 
                          className="h-[150px] lg:h-[200px] w-full object-cover" 
                        /> 
                        <div className="relative bg-white">
                          {/* data */}
                          <div className="py-4 px-2.5 md:px-3 lg:px-4 text-start">
                            <div className="text-xs uppercase text-gray-600">
                              {item?.data?.itemType ? itemsTypes.find(itemType => itemType.id === item.data.itemType)?.data?.name : null}
                            </div>
                            <ViewData field="mainAttr" classes={{ fieldContainer: 'my-1 pr-12 lg:my-0 md:text-lg lg:text-xl font-semibold leading-tight' }} />
                            {item.data.withExtra ? (
                              <div className="text-xs mb-1 px-1.5 py-0.5 -ml-1 font-regular leading-tight rounded-full bg-gray-100 text-gray-600 inline-block">
                                con guarnición
                              </div>
                            ) : null}
                            {specialPricesById[item.id] ? (<>
                              <ViewData field="price" classes={{ fieldContainer: 'text-sm sm:text-md md:text-lg text-gray-600 lg:my-0 line-through' }} decorator={priceFormat} />
                              <div className="text-sm sm:text-md md:text-lg font-semibold text-red-600 lg:my-0">{priceFormat(specialPricesById[item.id])}</div>
                            </>) : (
                              <ViewData field="price" classes={{ fieldContainer: 'text-sm sm:text-md md:text-lg text-red-600 lg:my-0' }} decorator={priceFormat} />
                            )}
                          </div>
                          <button onClick={() => toggleToBag(item)} className={`
                            top-3 lg:top-3 right-3 absolute text-white content-center rounded-full h-8 w-8
                            ${isItemInBag(item) ? 'bg-brand-red' : 'bg-brand-yellow'}
                          `}>
                            {isItemInBag(item) ? (
                              <FaCheck className="ml-2"/>
                              ) : (
                              <FaPlus className="ml-2"/>
                            )}
                          </button>
                        </div>
                        {isItemInBag(item) ? (
                          <div className="bg-brand-red px-1 py-1.5">
                            <input
                              className={`border-gray-200 border h-[40px] px-2 py-1.5 rounded-md`}
                              type={'number'}
                              placeholder={'Precio especial'}
                              value={specialPricesById[item.id]}
                              onChange={(e) => {
                                setSpecialPricesById({ ...specialPricesById, [item.id]: e.target.value });
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    )}
                  />
                  ))}
                </div>
              ) : null}

            </div>
          ))}
        </>) : null}
        
      </div>
    </div>
  );
};