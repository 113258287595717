import {
  FormField,
  FormFieldSelect,
  FormFieldCheckbox,
  FormFieldCheckboxToggle
} from '../Form';

import {
  // Boolean
  FilterRenderBoolean,
  FilterCreatorBoolean,
} from '../Filters';

export default {
  Render(props) {
    const {
      fieldName,
      taxonomyType,
      overrideParams = {},
      values,
      fieldsRequired
    } = props;
    const _taxonomyType = taxonomyType?.data || taxonomyType;
    const title = _taxonomyType?.name;
    // const design = _taxonomyType?.style?.design
    const _fieldName = fieldName || _taxonomyType?.nameSlug;
    const param = { ..._taxonomyType?.param, ...overrideParams };
    const style = param?.style;
    param.disabled = _taxonomyType?.readOnly;

    return (
      <FormFieldCheckboxToggle 
        name={_fieldName}
        title={title}
        fieldsRequired={fieldsRequired}
        {...param}
        {...style}
        {...props}
      />
    );
  },

  RenderFilter(props) {
    return <FilterRenderBoolean {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorBoolean {...props} />
  },

  RenderInputParams: ({ values, param }) => (<>
    <div className="mt-4 mb-4 border-b border-gray-400">
      <FormFieldCheckbox
        name="param.showCondition"
        title="Condición de visibilidad en vistas"
      />
    </div>
    <FormFieldCheckboxToggle
      name="param.value"
      title="Valor inicial"
      switchType="b" // "b"
      colorFalse="gray-400"
      textTrue={values.param?.textTrue || "Activo"}
      textFalse={values.param?.textFalse || "Inactivo"}
    />
    <FormField
      name="param.textTrue"
      title="Texto activado"
    />
    <FormField
      name="param.textFalse"
      title="Texto inactivo"
    />
  </>),

  RenderShowConditionParams: ({ values, taxonomyToWatch }) => {
    let label = values.show?.condition === 'show' 
                  ? 'Mostrar' 
                  : values.show?.condition === 'hide'
                    ? 'Ocultar' : '';
    return (
      <FormFieldCheckboxToggle
        name="show.boolean.value"
        title={`${label} si`}
        switchType="b" // "b"
        colorFalse="gray-400"
        textTrue={taxonomyToWatch.data?.param?.textTrue || "Activo"}
        textFalse={taxonomyToWatch.data?.param?.textFalse || "Inactivo"}
      />
    );
  },

  RenderStyleParams: ({ values }) => (
    <FormFieldSelect
      name="style.design"
      title="Diseño"
      placeholder="Diseño"
      options={[
        { value: 'toggle', label: 'Switch' },
        { value: 'checkbox', label: 'checkbox' }
      ]}
    />
  ),

  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => {
    if (values.style?.design === 'toggle') {
      return (<FormFieldCheckboxToggle {...formFieldProps} />);
    }
    if (values.style?.design === 'checkbox') {
      return (<FormFieldCheckbox {...formFieldProps} />);
    }
    return '';
  },
 
  getDefaultTaxonomyTypeDesign: () => ('toggle')
}