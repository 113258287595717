import React from 'react';
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import BtnLoading from './BtnLoading';


export default function ModalCongrats({
  title,
  subtitle,
  onConfirm,
  confirmLabel = 'Confirmar'
}) {
  const { width, height } = useWindowSize();
  return (
    <div className="fixed inset-0 flex items-center justify-center !z-[1000]">
      <div className="fixed inset-0 bg-black opacity-50"></div> {/* Overlay */}
      <div className="bg-white rounded-lg p-6 shadow-md relative mx-5 max-w-sm text-center">
        {title ? (
          <p className="my-10 text-2xl font-bold text-brand-red">{title}</p>
        ) : null}
        {subtitle ? (
          <p className="mb-4 text-sm text-gray-500">{subtitle}</p>
        ) : null}
        <BtnLoading
          label={confirmLabel}
          onClickAsync={onConfirm}
          className="py-1.5 px-3 bg-brand-yellow text-sm hover:scale-105 shadow-lg mx-auto"
          colorClass="text-black"
        />
      </div>
      <Confetti width={width} height={height} recycle={false} />
    </div>
  );
}
