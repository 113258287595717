import { useEffect, useMemo, useState } from "react";
import _ from 'lodash';
import dayjs from 'dayjs';
import Model from "../../libs/ModelClass";
import config from "../../config";
import { chunkArray, numberFormat, priceFormat } from "../../libs/utils";
import BadgeLoading from "../../components/ui/BadgeLoading";

const UsersModel = Model.extend('usersProfiles');
const CartsModel = Model.extend(config?.modules?.cart?.cartsEntitySlug);
const ProductsModel = Model.extend(config?.modules?.cart?.cartItemEntitySlug);

// export const BillingProductsCardMemo = memo(BillingProductsCard, (prev, next) => {
//   return prev.dateFrom === next.dateFrom
//       || prev.dateTo === next.dateTo
//       || prev?.institutionDoc?.data?.id === next?.institutionDoc?.data?.id;
// });

export const extractProductsAndQtys = (daysSelected, carts) => {
  const itemsInBags = [];
  carts.forEach((cartDoc) => {
    daysSelected.forEach((day) => {
      const bagOfDay = cartDoc.data.itemsInBags.find(bag => bag.date === day);
      bagOfDay?.bagItems.forEach((itemInBag) => {
        if (!itemInBag.deleted) {
          itemsInBags.push({ ...itemInBag, day, userId: cartDoc.data.userId, createdAt: cartDoc.data.createdAt });
        }
      });
    });
  });
  return itemsInBags;
};

export const extractProductsCanceledAndQtys = (daysSelected, carts) => {
  const itemsInBags = [];
  carts.forEach((cartDoc) => {
    daysSelected.forEach((day) => {
      const bagOfDay = cartDoc.data.itemsInBags.find(bag => bag.date === day);
      bagOfDay?.bagItems.forEach((itemInBag) => {
        if (itemInBag.deleted) {
          itemsInBags.push({ ...itemInBag, day, userId: cartDoc.data.userId, createdAt: cartDoc.data.createdAt, deletedAt: cartDoc.data.deletedAt });
        }
      });
    });
  });
  return itemsInBags;
};

export const attachDocsAndGetTotal = (itemsInBags, products, users, includeDeleted) => {
  let totalTotal = 0;
  itemsInBags.forEach(item => {
    if (includeDeleted ? true : !item.deleted) {
      item.userDoc = users.find(doc => doc.id === item.userId);
      if (item.userDoc) {
        totalTotal += item.price * item.qty;
        item.itemDoc = products.find(doc => doc.id === item.id);
        if (item.withExtra) {
          item.itemExtraDoc = products.find(doc => doc.id === item.withExtra);
        }
      }
    }
  });
  return totalTotal;
};

export const useBillingDetails = (props) => {
  const {
    dateFrom,
    dateTo,
    institutionDoc,
    setTotal
  } = props;
  const [ productsDocs, setProductsDocs ] = useState([]);
  const [ usersDocs, setUsersDocs ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ productsOfRange, setProductsOfRange ] = useState([]);
  
  useEffect(() => {
    institutionDoc && dateTo && dateFrom && fetchFilteredDocs();
  }, [institutionDoc, dateFrom, dateTo]);
  
  const fetchFilteredDocs = async () => {
    setLoading(true);
    const daysSelected = generateDateRange();
    const daysSelectedBatch = chunkArray(daysSelected, 30); // for firebase limitations
    let cartsDocs = [];
    for (const daysChunk of daysSelectedBatch) {
      let filteredCarts = await CartsModel.filterByAttributes({
        institutionId: institutionDoc.id,
        daysSelected: { 'in-array': daysChunk }
      });
      filteredCarts = filteredCarts.filter(doc => !doc.data?.deleted);
      cartsDocs = cartsDocs.concat(filteredCarts);
    }
    // fetch related documents
    const products = productsDocs.length ? productsDocs : await fetchAllProducts();
    const users = usersDocs.length ? usersDocs : await fetchUsers();
    // process data by day
    let itemsInBags = extractProductsAndQtys(daysSelected, cartsDocs);
    itemsInBags = _.reverse(_.sortBy(itemsInBags, 'day'));
    const totalTotal = attachDocsAndGetTotal(itemsInBags, products, users);
    itemsInBags = itemsInBags.filter(itemInBag => itemInBag.userDoc);
    let itemsInBagsByUser = _.groupBy(itemsInBags, 'userId');
    const totalsByUser = {};
    _.forEach(itemsInBagsByUser, group => {
      if (group && group[0] && group[0].userDoc) {
        totalsByUser[group[0].userDoc.id] = {
          userDoc: group[0].userDoc,
          total: group.reduce((total, doc) => total + (doc.price * doc.qty), 0),
          qty: group.length
        };
      }
    });
    setProductsOfRange({
      dateFrom,
      dateTo,
      totalTotal,
      itemsInBags,
      itemsInBagsByUser,
      totalsByUser,
      usersDocs,
      cartsDocs
    });
    setLoading(false);
    setTotal && setTotal(totalTotal);
  };
  
  const generateDateRange = () => {
    const startDate = dayjs(dateFrom);
    const endDate = dayjs(dateTo);
    const daysArray = [];
    let currentDate = startDate;
    while (currentDate.isSameOrBefore(endDate, 'day')) {
      daysArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }
    return daysArray;
  }  
  
  const fetchAllProducts = async () => {
    let docs = await ProductsModel.getAll();
    docs = docs.filter(docs => docs.data.deleted !== true);
    setProductsDocs(docs);
    return docs;
  };
  
  const fetchUsers = async () => {
    let docs = await UsersModel.filterByAttributes({ institutionId: institutionDoc?.id });
    docs = docs.filter(docs => docs.data.deleted !== true);
    setUsersDocs(docs);
    return docs;
  };

  return {
    productsDocs,
    usersDocs,
    loading,
    productsOfRange
  };
};

export const BillingListGroupedWithTabs = ({ groupedList }) => {
  const [ selectedGroupId, setSelectedGroupId ] = useState();
  const groupsSorted = useMemo(() => _.sortBy(_.values(groupedList?.totalsByUser), (item) => item.userDoc.data.firstName), [groupedList]);

  return (
    <div className="flex flex-row border border-gray-300 rounded-md">
      <div className="p-1.5 h-[60vh] w-[130px] xs:w-[150px] md:w-[200px] lg:w-[350px] overflow-y-scroll bg-gray-200 border-r border-gray-300">
        <div className="gap-2 grid grid-cols-1 lg:grid-cols-2">
          {_.map(groupsSorted, ({ userDoc, total, qty }, index) => (
            <div className="" key={index}>
              <a onClick={() => setSelectedGroupId(userDoc.id)} 
                className={`block cursor-pointer text-left p-1 bg-white rounded-sm border ${selectedGroupId === userDoc.id ? 'shadow-md border-gray-600' : 'border-gray-200'}`}
              >
                <div className="text-black text-sm">
                  {(userDoc.data.firstName || '') + ' ' + (userDoc.data.lastName || '')}
                </div>
                <div className="text-black text-xs">
                  CI: {numberFormat(userDoc.data.ci)}
                </div>
                <div className="mt-1 pt-1 text-gray-500 text-sm font-mono border-t border-gray-300">
                  {priceFormat(total)}
                </div>
                <div className="mt-1 pt-1 text-gray-500 text-sm font-mono border-t border-gray-300">
                  Cantidad: {qty}
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="p-1.5 h-[60vh] flex-1 overflow-y-scroll space-y-2">
        {/* datos del user */}
        {selectedGroupId ? (
          <div className="mb-10">
            <div className="text-black text-sm">
              {(groupedList?.totalsByUser[selectedGroupId]?.userDoc?.data.firstName || '') + ' ' + (groupedList?.totalsByUser[selectedGroupId]?.userDoc?.data.lastName || '')}
            </div>
            <div className="text-black text-xs">
              CI: {numberFormat(groupedList?.totalsByUser[selectedGroupId]?.userDoc?.data.ci)}
            </div>
            <div className="mt-1 pt-1 text-gray-500 text-sm font-mono border-t border-gray-300">
              Total: {priceFormat(groupedList?.totalsByUser[selectedGroupId]?.total)}
            </div>
            <div className="mt-1 pt-1 text-gray-500 text-sm font-mono border-t border-gray-300">
              Cantidad: {groupedList?.totalsByUser[selectedGroupId]?.qty}
            </div>
          </div>
        ) : null}
        {_.map(groupedList?.itemsInBagsByUser[selectedGroupId], ({ price, qty, itemDoc, itemExtraDoc, day }, index) => (
          <div key={index}>
            <span className="text-black text-sm">
              {dayjs(day).utc().format('DD-MM-YYYY')}
            </span>
            <span className="text-black text-sm ml-2">
              {qty}
            </span>
            <span className="text-black text-sm ml-2">
              {priceFormat(price * qty)}
            </span>
            <div className="text-gray-500 text-sm">
              {itemExtraDoc ? (
                itemDoc?.data?.name + ' con ' + itemExtraDoc?.data?.name
              ) : (
                itemDoc?.data?.name
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const BillingProductsCardWithTabs = (props) => {
  const {
    loading,
    productsOfRange
  } = props;

  return (<>
    {(loading) ? (
      <div className="mt-20 -mb-20 h-0 relative mx-auto  w-full flex place-content-center content-center items-center">
        <BadgeLoading size="md" />
      </div>
    ) : null}

    {(productsOfRange?.itemsInBags?.length) ? (<>
      <div className={`flex flex-col ${loading ? 'opacity-40' : ''}`}>
        <h2 className="mb-1 text-xs font-semibold">Total por Usuario</h2>
        <BillingListGroupedWithTabs groupedList={productsOfRange} />
      </div>
    </>) : null}
  </>);
};

export const BillingListGrouped = ({ groupedList }) => {
  const groupsSorted = useMemo(() => _.sortBy(_.values(groupedList?.totalsByUser), (item) => item.userDoc.data.firstName), [groupedList]);

  return (
    <div className="mt-4 text-black text-sm">
      {_.map(groupsSorted, ({ userDoc, total }, index) => (
        <div key={index} className="mb-6">
          <div
            className="px-1 space-x-2 text-base"
          >
            <span className="">
              {(userDoc.data.firstName || '') + ' ' + (userDoc.data.lastName || '')}
            </span>
            <span className="">
              CI: {numberFormat(userDoc.data.ci)}
            </span>
          </div>
          <div className="pb-2 px-1 space-x-4">
            <span>
              Total: {priceFormat(total)}
            </span>
            <span>
              Cantidad: {groupedList?.itemsInBagsByUser[userDoc.id].length}
            </span>
          </div>
          <div className="pb-2">
            <table className="space-y-2 w-full">
              {_.map(groupedList?.itemsInBagsByUser[userDoc.id], ({ price, qty, itemDoc, itemExtraDoc, day }, index) => (
                <tr key={index}>
                  <td className="px-1 w-[100px]">
                    {dayjs(day).utc().format('DD-MM-YYYY')}
                  </td>
                  <td className="px-1 text-right w-[100px]">
                    {qty}
                  </td>
                  <td className="px-1 text-right w-[100px]">
                    {priceFormat(price)}
                  </td>
                  <td className="px-1 text-right w-[100px]">
                    {priceFormat(price * qty)}
                  </td>
                  <td className="px-1">
                    {itemExtraDoc ? (
                      itemDoc?.data?.name + ' con ' + itemExtraDoc?.data?.name
                    ) : (
                      itemDoc?.data?.name
                    )}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export const BillingProductsCardPrintable = (props) => {
  const {
    loading,
    productsOfRange
  } = props;

  return (<>
    {(!loading && productsOfRange?.itemsInBags?.length) ? (<>
      <div className={`flex flex-col ${loading ? 'opacity-40' : ''}`}>
        <h2 className="mb-1 text-xs font-semibold">Total por Usuario</h2>
        <BillingListGrouped groupedList={productsOfRange} />
      </div>
    </>) : null}
  </>);
};