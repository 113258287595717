import { useEffect, useRef } from "react";
import { FaHistory, FaRegCalendarPlus } from "react-icons/fa";
import useSmoothHorizontalScroll from "use-smooth-horizontal-scroll";


export const HorizontalCardPicker = ({ 
  itemWidth,
  itemHeight,
  openIndex,
  prevBag,
  nextBag,
  items,
  className
}) => {
  const { scrollContainerRef, handleScroll, scrollTo, isAtStart, isAtEnd } = useSmoothHorizontalScroll();
  const trackRef = useRef();
  
  useEffect(() => {
    if (scrollContainerRef?.current && trackRef.current?.childNodes?.length && openIndex > -1) {
      const elementX = trackRef.current?.childNodes[openIndex].getBoundingClientRect().x;
      scrollTo( elementX  );
    }
  }, [openIndex, scrollContainerRef]);
  
  return (
    <div className={`relative overflow-y-hidden ${className || ''}`} style={{height: itemHeight}}>
      <div className="overflow-x-scroll" ref={scrollContainerRef} onScroll={handleScroll}>
        {items?.length ? (
          <div
            ref={trackRef}
            className="flex flex-row"
            style={{ width: itemWidth * items?.length, marginRight: (trackRef.current?.clientWidth - itemWidth) || 0 }}
          >
            {/* Your scrolling content here */}
            {items?.map((item, index) => (
              item
            ))}
          </div>
        ) : null}
      </div>
      <div className="absolute z-10 w-[120px] top-0 right-0 bg-gradient-to-r from-transparent via-white to-white" style={{height: itemHeight}}>
        <div className="w-auto absolute top-[25px] right-4 flex flex-row border bg-white border-brand-red items-center gap-2 rounded-full shadow-md">
          <button type="button" onClick={prevBag} className="w-11 h-8 p-1.5 pl-3 mr-3 rounded-full text-base font-bold text-gray-400 hover:bg-gray-200">
            <FaHistory alt="Atrás" className="w-5 h-5" />
          </button>
          <button type="button" onClick={nextBag} className="w-11 h-11 p-3 -m-2 bg-brand-red rounded-full text-base font-bold text-white">
            <FaRegCalendarPlus alt="Siguiente" className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};