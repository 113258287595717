import { IonIcon } from "@ionic/react";
import { 
  logoGoogle
} from 'ionicons/icons';
import { useAuth } from "../../modules/user/AuthContext";
import { useLoginUtils } from "../../modules/user/Login";
import BtnLoading from "../ui/BtnLoading";
import config from "../../config";

export default function CtaDownloadBlock({ data, history, classes }) {
  const { user, loginWithGoogleAndVerify } = useAuth();
  const { handleGoogleSignin } = useLoginUtils(user, loginWithGoogleAndVerify, history);

  return (
    <div id="descarga" className={`font-brand-montserrat ${classes?.blockContainer}`}> 
      
      {/* cta */}
      <div className="relative z-10 container-width text-center px-4">
        <h2 className="text-white font-semibold text-xl mb-8">
          {data?.ctaTitle}
        </h2>

        <div className="flex flex-col md:flex-row gap-10 md:items-start place-content-center">
          <div className="flex flex-col gap-4 justify-center">
            <h2 className="text-white font-semibold text-sm">
              {data?.webCTAtitle}
            </h2>
            <BtnLoading
              label="Acceder con Gmail"
              icon={<IonIcon icon={logoGoogle} className="!-mb-1 w-5 h-5 text-red-600" />}
              onClickAsync={handleGoogleSignin}
              className="block mx-auto font-semibold !items-start !justify-start px-3 py-2 w-[220px] !bg-white !text-gray-900 shadow-md !no-underline hover:underline hover:scale-105 transition duration-400 ease-in-out"
              colorClass="text-gray-400 hover:text-gray-700"
            />
          </div>

          <div className="flex flex-col gap-4 justify-center" >
            <h2 className="text-white font-semibold text-sm">
              {data?.appCTATitle}
            </h2>
            
            <a href={config.googlePlayLink} target="_blank" rel="noreferrer" className="hover:scale-105 transition duration-400 ease-in-out">
              <img src="/play-store-2.png" className="h-12 mx-auto" alt="play-store" />
            </a>
            
            <div className="">
              <div className="mb-1 opacity-50" href="/" target="_blank" rel="noreferrer">
                <img src="/app-store-2.png" className="h-12 mx-auto" alt="app-store" />
              </div>
              <h3 className="text-white tracking-[5px] uppercase font-semibold text-[9px]">
                Próximamente
              </h3>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}