import _ from "lodash";
import { Link } from "react-router-dom";
import HeaderHeroBlock from "../../components/Blocks/HeaderHero";
import CtaDownloadBlock from "../../components/Blocks/CtaDownloadBlock";
import FooterBlock from "../../components/Blocks/FooterBlock";
import { blocks } from "../../stringsMap";
import { LayoutHome } from "../../components/LayoutHome";
import { IonContent, IonPage } from "@ionic/react";
import config from "../../config";

const Content = () => (
  <div className="mt-8 md:mt-24 px-3 py-16 prose prose-xl max-w-lg mx-auto text-justify">

    <h1 className="text-2xl font-bold">Política de Privacidad de Work Food App</h1>
    <br />
    <h2 className="text-lg font-semibold mb-2">Fecha de entrada en vigor: 11-Noviembre-2023.</h2>
    <p className="mb-1.5">Work Food ("nosotros", "nuestro" o "nosotros") opera la aplicación móvil Work Food (en adelante, la "Aplicación"). Esta página se utiliza para informar a los usuarios sobre nuestras políticas con la recopilación, uso y divulgación de la Información Personal si alguien decide utilizar nuestra Aplicación.</p>
    <p className="mb-1.5">Si elige utilizar nuestra Aplicación, acepta la recopilación y uso de información de acuerdo con esta política. La Información Personal que recopilamos se utiliza para proporcionar y mejorar la Aplicación. No utilizaremos ni compartiremos su información con nadie, excepto según se describe en esta Política de Privacidad.</p>
    <br />
    <h2 className="text-lg font-semibold mb-2">Recopilación y Uso de Información</h2>
    <p className="mb-1.5">Para una mejor experiencia mientras utiliza nuestra Aplicación, es posible que le solicitemos que nos proporcione cierta información de identificación personal, incluidos, entre otros, su nombre, número de teléfono y dirección de correo electrónico. La información que recopilamos se utilizará para contactarlo o identificarlo.</p>
    <br />
    <h2 className="text-lg font-semibold mb-2">Datos de Registro</h2>
    <p className="mb-1.5">Queremos informarle que cada vez que utiliza nuestra Aplicación, en caso de un error en la aplicación, recopilamos datos e información (a través de productos de terceros) en su teléfono llamados Datos de Registro. Estos Datos de Registro pueden incluir información como la dirección del Protocolo de Internet ("IP") de su dispositivo, el nombre del dispositivo, la versión del sistema operativo, la configuración de la aplicación cuando utiliza nuestra Aplicación, la hora y fecha de su uso de la aplicación y otras estadísticas.</p>
    <br />
    <h2 className="text-lg font-semibold mb-2">Cookies</h2>
    <p className="mb-1.5">Las cookies son archivos con una pequeña cantidad de datos que se utilizan comúnmente como identificadores únicos anónimos. Estos se envían a su navegador desde los sitios web que visita y se almacenan en la memoria interna de su dispositivo.</p>
    <p className="mb-1.5">Esta Aplicación no utiliza estas "cookies" explícitas. Sin embargo, la aplicación puede utilizar código de terceros y bibliotecas que utilizan "cookies" para recopilar información y mejorar sus servicios. Tiene la opción de aceptar o rechazar estas cookies y saber cuándo se envía una cookie a su dispositivo. Si elige rechazar nuestras cookies, es posible que no pueda utilizar algunas partes de esta Aplicación.</p>
    <br />
    <h2 className="text-lg font-semibold mb-2">Proveedores de Servicios</h2>
    <p className="mb-1.5">Podemos emplear empresas y personas de terceros debido a las siguientes razones:</p>
    <ul className="mb-1.5">
      <li>- Facilitar nuestra Aplicación;</li>
      <li>- Proporcionar la Aplicación en nuestro nombre;</li>
      <li>- Realizar servicios relacionados con la Aplicación; o</li>
      <li>- Ayudarnos a analizar cómo se utiliza nuestra Aplicación.</li>
    </ul>
    <p className="mb-1.5">Queremos informar a los usuarios de esta Aplicación que estos terceros tienen acceso a su Información Personal. La razón es realizar las tareas asignadas en nuestro nombre. Sin embargo, están obligados a no divulgar ni utilizar la información para ningún otro propósito.</p>
    <br />
    <h2 className="text-lg font-semibold mb-2">Seguridad</h2>
    <p className="mb-1.5">Valoramos su confianza al proporcionarnos su Información Personal; por lo tanto, estamos esforzándonos por utilizar medios comercialmente aceptables de protección de la misma. Pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro y confiable, y no podemos garantizar su seguridad absoluta.</p>
    <br />
    <h2 className="text-lg font-semibold mb-2">Cambios en esta Política de Privacidad</h2>
    <p className="mb-1.5">Podemos actualizar nuestra Política de Privacidad de vez en cuando. Por lo tanto, se recomienda revisar esta página periódicamente para cualquier cambio. Le notificaremos de cualquier cambio publicando la nueva Política de Privacidad en esta página. Estos cambios son efectivos inmediatamente después de que se publiquen en esta página.</p>
    <br />
    <h2 className="text-lg font-semibold mb-2">Contacto</h2>
    <p className="mb-1.5">Si tiene alguna pregunta o sugerencia sobre nuestra Política de Privacidad, no dude en contactarnos en <a className="underline" href={`mailto:${config?.email}`}>{config?.email}</a>.</p>

  </div>
);

const HeaderMenu = ({ menuHeight }) => (<>
  <Link
    className="pl-10 sm:pl-2 sm md:px-3 text-black hover:text-brand-yellow md:font-medium text-sm md:text-base  cursor-pointer"
    to="/"
  >
    Inicio
  </Link>
</>)

export function PoliticaPrivacidad () {  
  return (
    <IonPage id="main">
      <IonContent className="">
        <LayoutHome mainSectionClass="px-0" HeaderMenu={HeaderMenu} >
          {/* Header Hero */}
        
          <Content />

          <CtaDownloadBlock data={blocks.ctaDownload} />

          <FooterBlock data={blocks.footerSection} />

          {/* <FooterMenuBlock data={blocks.menuFooter} /> */}

          {/* fixed footer 
          <footer className="footer fixed bottom-0 left-0 w-full z-50">
            <div className="flex flex-row-reverse place-content-between mx-auto mb-4 mr-4">
              <ContactBTN className={'ml-2 mb-1 lg:mb-2 bg-brand-dark'} />
            </div>
          </footer> */}
        </LayoutHome>
      </IonContent>
    </IonPage>
  );
}