import { useEffect, useState } from "react";
import slugify from "slugify";
import _ from "lodash";
import Model from "../../libs/ModelClass";
import { getImageURL } from "../../libs/utils";

const InstitutionModel = Model.extend('institutions');

export const InstitutionSelector = ({
  selectedId,
  selectedInstitution,
  setSelectedInstitution,
  title = "Selecciona tu institución"
}) => {
  const [ institutionName, setInstitutionName ] = useState();
  const [ institutionList, setInstitutionList ] = useState([]);

  useEffect(() => {
    !institutionList?.length && getAllDocs();
  }, []);

  const getAllDocs = async () => {
    if (institutionList.length) { return; }
    const allDocs = await InstitutionModel.getAll();
    let filteredDocs = [];
    allDocs.forEach((doc) => {
      if (!doc.data.deleted && doc.data.available) {
        doc.data.nameSlug = slugify(doc.data.name, { lower: true, replacement: ' ' });
        filteredDocs.push(doc);
      }
    });
    filteredDocs = _.sortBy(filteredDocs, ['data.nameSlug']);
    if (selectedId) {
      let selectedDoc = filteredDocs.find(doc => doc.id === selectedId);
      setSelectedInstitution(selectedDoc);
    }
    setInstitutionList(filteredDocs);
  };

  const setNameValue = (e) => {
    setInstitutionName(e.target.value);
  };

  const filterList = () => {
    if (institutionName) {
      return institutionList.filter(doc => doc.data.nameSlug.includes( slugify(institutionName, { lower: true, replacement: ' ' }) ));
    }
    return institutionList;
  };

  const Card = ({ className, selectedInstitution, item }) => (
    <div className={`flex flex-row items-stretch w-full ${selectedInstitution?.id === item.id ? 'border border-brand-red' : 'border-b border-gray-200'} ${className}`}>
      {/* image xs */}
      <div className="w-[80px] h-[70px]">
        <img src={item?.data?.images?.length && getImageURL(item.data.images[0], 'xs')}
          className="h-full w-full object-cover" 
        /> 
      </div>
      {/* data */}
      <div className="px-1.5 py-1.5 text-left leading-tight flex-1">
        <div className={`mb-0.5 text-sm font-semibold text-black ${selectedInstitution?.id === item.id ? 'text-brand-red' : ''}`}>{item?.data?.name}</div>
        <div className="text-xs text-gray-500">{item?.data?.location}</div>
        <div className="text-xs text-gray-500">{item?.data?.city}</div>
      </div>
    </div>
  );

  return (
    <div className="">
      {/* input */}
      <div className="mb-4">
        <h3 className='mb-2 text-sm font-semibold'>
          {title}
        </h3>
        <input
          type="text"
          value={institutionName}
          onChange={setNameValue}
          className="shadow appearance-none text-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Buscar por nombre"
        />
      </div>
      {selectedInstitution ? (
        <Card className="mb-4 rounded-md overflow-hidden" {...{ selectedInstitution, item: selectedInstitution }} />
      ) : null}
      {/* list */}
      <div className="h-[40vh] overflow-y-scroll rounded-md border border-gray-300">
        <div className="flex flex-col">
          {filterList().map(item => (
            <button 
              key={item.id}
              onClick={() => setSelectedInstitution(item)}
            >
              <Card {...{ selectedInstitution, item }} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}