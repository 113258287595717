import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from "../modules/user/AuthContext";
import config from "../config";
import { useScrollTo } from "../libs/utils";
import { animateScroll as scroll } from 'react-scroll';
import { FaBars, FaRegWindowClose } from 'react-icons/fa';


export function LayoutHome({ HeaderMenu, mainSectionClass, children }) {
  const { user } = useAuth();
  const [menuHeight, setMenuHeight] = useState();
  const ref = useRef();
  const location = useLocation();
  const { scrollToDelay } = useScrollTo();
  const [navbar, setNavbar] = useState(false)

  
  useEffect(() => {
    const handleResize = () => {
      setMenuHeight(ref.current.getBoundingClientRect().height);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);


  useEffect(() => {
    scrollToDelay('main');
  }, [location.pathname]);


  return (
    <div id="main" className="w-full text-black">
      {/* header */}
      <header id="menu" ref={ref} className="fixed w-full top-0 font-brand-montserrat bg-white/[.98]  shadow-sm z-50 overflow-hidden">

        <div className="flex justify-between place-items-center px-3 lg:px-4 py-2 w-full">

          {/* logos  */}
          <div className="">
            <Link
              to="/"
              title={config.brand.name}
              onClick={() => scroll.scrollToTop()}
              className="flex flex-row items-center"
            >
              <img src="/logo-icono-color.svg" alt={config.brand.name} className="h-8 md:h-12 mr-1" />
              <img src="/logo-texto-negro.svg" alt={config.brand.name} className="h-5 sm:h-8 justify-self-center" />
            </Link>
          </div>

          {/* links de la izquierda */}
          {HeaderMenu ? (
            <div className="text-xs hidden sm:block">
              <HeaderMenu menuHeight={menuHeight}></HeaderMenu>
              {user?.userDoc?.data?.name ? (
                <Link
                className="bg-zinc-200 sm:hidden ml-2 hover:bg-zinc-300 rounded py-1.5 px-1.5 text-black text-sm"
                to="/admin">
                  Admin
                </Link>
              ) : ''}
            </div>
          ) : ''}

          {/* botón de menú móbil hamburger */}
          <div className="sm:hidden justify-self-end">
              <button
                className="p-2 text-gray-700 outline-none"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? <FaRegWindowClose size={23} /> : <FaBars size={21} />}
              </button>
            </div>
        </div>

        {/* menu móbil desplegable  */}
        <div
          className={`md:hidden sm:block bg-transparent ${
            navbar ? "block" : "hidden"
          }`}
        >
          <div className="">
          {HeaderMenu ? (
            <div className="text-xs flex flex-col space-y-3 sm:hidden py-4 mb-2 border-t w-full border-gray-400">
              <HeaderMenu menuHeight={menuHeight}></HeaderMenu>
              {user?.userDoc?.data?.name ? (
                <Link
                className="bg-zinc-200 sm:hidden hover:bg-zinc-300 mx-9 rounded py-1.5 px-1.5 text-black text-sm"
                to="/admin">
                  Admin
                </Link>
              ) : ''}
            </div>
          ) : ''}
          </div>
        </div>

      </header>
      
      {/* content */}
      <section className="layout-main-section-container min-h-screen">
        <div className={`layout-main-section z-10 min-h-screen ${mainSectionClass ? mainSectionClass : `px-5`}`}>
          {children}
          {/* <div className="max-w-none -mx-5 flex place-content-center pt-[100px] pb-[150px] relative overflow-hidden">
            <img src="/[logo].png" alt="logo" className="max-w-none spin-slow object-cover w-[600px] xs:w-[700px] sm:w-[800px] md:w-[800px]" />
          </div> */}
        </div>
      </section>
    </div>
  );
}