import _ from 'lodash';
import ViewGPS from './ViewGPS';
import SliderGallery from './ui/SliderGallery';
import EntityGetOneDocument from './EntityGetOneDocument';
import Entity, { useEntityFullBySlug } from '../modules/entity/Entity';
import { stackClasses, getImageURL, numberFormat } from '../libs/utils';
import PartOfModule from './Module/PartOfModule';
import ReactMarkdown from 'react-markdown';
import { ShowRelatedListMaterials } from './Form/RawInputRelatedListMaterials';


const getComponent = ({ entitySlug, doc, field, classes, decorator, outstandingOnly, taxonomyTypesDocList }) => {
  if (!doc) return null;

  let mainAttr = Entity.getMainAttr(taxonomyTypesDocList);
  let mainImgAttr = Entity.getMainImgAttr(taxonomyTypesDocList);
  if (field === 'mainAttr') {
    field = mainAttr;
  }
  if (field === 'mainImgAttr') {
    field = mainImgAttr;
  }
  
  const taxonomyType = taxonomyTypesDocList?.find(taxonomyType => taxonomyType.data?.nameSlug === field);
  if (!taxonomyType) {
    return null;
  }

  const { type, name, nameSlug, outstanding, param, style } = taxonomyType?.data;

  const displayedValue = decorator ? decorator(doc.data[nameSlug]) : getDisplayedValue(
    doc.data[nameSlug],
    type,
    taxonomyType
  );

  const FieldLabel = () => (
    <span className={`pr-2 font-semibold ${classes?.fieldLabel}`}>
      {name}
    </span>
  );

  if (outstandingOnly && !outstanding) {
    // Si se requieren solo elementos destacados y este no es destacado, no mostrarlo
    return null;
  }

  if (type === 'gps') {
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <ViewGPS value={displayedValue} classes={classes} />
        </div>
      </div>
    ) : null;
  }

  if (type === 'gallery') {
    if (!displayedValue || !_.isArray(displayedValue)) {
      return null;
    }
    const slidersData = displayedValue.map(filePath => {
      return {
        key: filePath,
        title: filePath,
        imageUrl: getImageURL(filePath, 'xl'),
        thumbUrl: getImageURL(filePath, 'xs')
      }
    });
    return displayedValue ? (
      slidersData && slidersData.length && (
        <div key={nameSlug} className={classes?.fieldContainer}>
          <FieldLabel />
          <div className={classes?.fieldValue}>
            <SliderGallery
              slidersData={slidersData}
              // showSliderAtInit={showSliderAtInit}
              // gridPlace={gridPlace}
              showGrid={true}
              gridClass="products-grid xs mt-3"
              thumbBtnClass="product-card">
            </SliderGallery>
          </div>
        </div>)
    ) : null;
  }

  if (type === 'selectOneEntityDocument') {
    return displayedValue ? (
      <EntityGetOneDocument
      key={nameSlug}
      entitySlug={param?.entitySlug || param?.entityNameSlug} // retrocompatible
      docId={doc?.data[ nameSlug ]}>
        {({ doc, mainAttr, mainImgAttr }) => {
          return (<>
            <div key={nameSlug} className={classes?.fieldContainer}>
              <FieldLabel />
              <div className={classes?.fieldValue}>
                {doc.data[mainAttr]}
                {doc.data.deleted ? (<span className="px-1 py-0.5 ml-2 text-xs text-red-600 bg-red-100 rounded-full">eliminado</span>) : null}
              </div>
            </div>
          </>);
        }}
      </EntityGetOneDocument>
    ) : null;
  }

  if (type === 'textArea') {
    // TODO: agregar parser de markdown al mostrar, botones WYSIWYG para markdown en FormFieldTextArea
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {style?.design === 'simple' ? (
            displayedValue
          ) : null}
          {style?.design === 'richMarkdown' ? (
            <div className="prose lg:prose-sm">
              <ReactMarkdown>{displayedValue}</ReactMarkdown>
            </div>
          ) : null}
          {style?.design === 'richWYSIWYG' ? (
            displayedValue
          ) : null}
        </div>
      </div>
    ) : null;
  }

  if (type === 'coded') {
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <PartOfModule
            type="codedDataType"
            fieldName={nameSlug}
            action="RenderShow"
            entitySlug={entitySlug}
            param={{ displayedValue, doc, field }} 
          />
        </div>
      </div>
    ) : null;
  }

  if (type === 'relatedListMaterials') {
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <ShowRelatedListMaterials relatedListId={displayedValue} />
        </div>
      </div>
    ) : null;
  }

  if (type === 'boolean') {
    return (<div key={nameSlug} className={classes?.fieldContainer}>
      <FieldLabel />
      <div className={classes?.fieldValue}>
        {!!doc?.data[nameSlug] ? (
          <span className="text-gray-900">{param?.textTrue}</span>
        ) : (
          <span className="text-gray-400">{param?.textFalse}</span>
        )}
      </div>
    </div>);
  }

  return (
    displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {displayedValue}
        </div>
      </div>
    ) : null
  );
}

/**
 * Obtiene el valor de visualización de una taxonomía según su tipo y valor almacenado.
 *
 * @param {any} value - El valor almacenado de la taxonomía.
 * @param {string} type - El tipo de taxonomía.
 * @param {object} taxonomyType - El objeto que representa el tipo de taxonomía.
 * @returns {string} - El valor de visualización correspondiente.
 */
const getDisplayedValue = (value, type, taxonomyType) => {
  if (type === 'select' || type === 'multiselect') {
    let _val = value;
    if (!Array.isArray(value)) {
      _val = [value];
    }
    // Obtiene las etiquetas correspondientes a los valores seleccionados
    return _val.map((val) => taxonomyType.data?.param?.options?.find(option => option.value === val)?.label || val).join(', ');
  } else if (type === 'number') {
    return numberFormat(value);
  }
  return value;
};

const EntityDocView = (props) => {
  let { entitySlug, render } = props;
  let { taxonomyTypesDocList, mainAttr, mainImgAttr } = useEntityFullBySlug({ entitySlug });

  return (
    taxonomyTypesDocList?.length
    ? render({
      ViewData({ field, classes, decorator }) {
        return taxonomyTypesDocList?.length ? getComponent({
          ...props,
          taxonomyTypesDocList,
          field,
          classes: stackClasses(props?.classes, classes),
          decorator
        }) : null;
      },

      taxonomyTypesDocList, mainAttr, mainImgAttr
    })
    : null
  );
};

export const EntityDocListView = (props) => {
  let { render, taxonomyTypesDocList, mainAttr, mainImgAttr } = props;

  return (
    taxonomyTypesDocList?.length
    ? render({
      ViewData({ field, classes, decorator }) {
        return taxonomyTypesDocList?.length ? getComponent({
          ...props,
          taxonomyTypesDocList,
          field,
          classes: stackClasses(props?.classes, classes),
          decorator
        }) : null;
      },

      taxonomyTypesDocList, mainAttr, mainImgAttr
    })
    : null
  );
};

export default EntityDocView;
