import { Link } from 'react-router-dom';
import { LayoutAdmin } from "../components/LayoutAdmin";


export default function Page404() {
  return (
    <LayoutAdmin metaTitle="404">      
      {/* path */}
      <div className="flex mb-5">
        <Link to="/admin"><h2 className="text font-thin mb-4 mr-3">Panel</h2></Link>
        <h2 className="text font-semibold mb-4">404</h2>
      </div>

      <div className="mb-5 text-2xl text-bold">
        La página no existe
      </div>

      <Link to="/admin" className="bg-zinc-200 hover:bg-zinc-300 rounded py-1.5 px-2 text-black text-xs xsm:text-sm">
        Volver
      </Link>
    </LayoutAdmin>
  );
}
