import {
  FormFieldSelectOneEntityDocument
} from '../Form';

import {
  // Select
  FilterRenderSelectOneEntityDocument,
  FilterCreatorSelectOneEntityDocument,
} from '../Filters';

export default {
  Render(props) {
    const {
      fieldName,
      taxonomyType,
      overrideParams = {},
      fieldsRequired,
      isFilter
    } = props;
    const _taxonomyType = taxonomyType?.data || taxonomyType;
    const title = _taxonomyType?.name;
    // const design = _taxonomyType?.style?.design
    const _fieldName = fieldName || _taxonomyType?.nameSlug;
    let param = { ..._taxonomyType?.param, ...overrideParams };
    const style = param?.style;
    param.disabled = isFilter ? false : _taxonomyType?.readOnly;
    if (props.paramsByFieldSlug && props.paramsByFieldSlug[_fieldName]) {
      param = {...param, ...props.paramsByFieldSlug[_fieldName]};
    }

    return (
      <FormFieldSelectOneEntityDocument 
        name={_fieldName}
        title={title}
        fieldsRequired={fieldsRequired}
        {...param}
        {...style}
        {...props}
        entitySlug={param?.entityNameSlug} // retrocompatible
      />
    );
  },

  RenderFilter(props) {
    return <FilterRenderSelectOneEntityDocument {...props} DataTypeRender={this.Render} isFilter={true} />
  },

  RenderFilterParam(params) {
    return <FilterCreatorSelectOneEntityDocument {...params} />
  },

  RenderFilterPreview({ values }) {
    return <FilterRenderSelectOneEntityDocument values={values} DataTypeRender={this.Render} />
  },

  RenderInputParams: ({ values, param }) => (
    <FormFieldSelectOneEntityDocument
      name="param.entityNameSlug" // TODO migrate entitySlug to entityNameSlug
      entitySlug="entities"
      title="Entidad"
      optionsMapper={
        (docs) => 
          docs.map((option) => ({
            value: option?.data?.nameSlug,
            label: option?.data?.name,
          }))
      }
    />
  ),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderStyleParams: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => {
    const entitySlug = values?.param?.entitySlug || values?.param?.entityNameSlug; // retrocompatible
    return (
      <FormFieldSelectOneEntityDocument
        entitySlug={entitySlug}
        {...formFieldProps}
      />
    );
  }
}