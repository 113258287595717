import { LayoutAdmin } from "../components/LayoutAdmin";
import { useModule } from "../context/ModuleContext";
import PartOfModule from "../components/Module/PartOfModule";
import { useAuth } from "../modules/user/AuthContext";


export function AdminHome(props) {
  const { isAllowed } = useModule();
  const { user } = useAuth();

  return (
    <LayoutAdmin history={props.history}>
      <PartOfModule
        type="main"
        action="AdminHomeBeforeLinksList"
        param={{ isAllowed, user }}
      />

      <PartOfModule
        type="main"
        action="AdminHomeAfterLinksList"
        param={{ isAllowed, user }}
      />
    </LayoutAdmin>
  );
}