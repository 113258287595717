import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";

import { ListDocumentsByState } from "./RouteCustomerBillingList";
import BtnLoading from '../../components/ui/BtnLoading';

export const RouteCustomerBillingGroupList = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {
  const onShow = (doc) => {
    history.push('/admin/customerBilling/groupShow/id/' + doc?.id);
  };

  const onShowCurrent = (doc) => {
    history.push('/admin/customerBilling/groupShowCurrent');
  };

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin`}
      title="Pagos de la Institutición"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        title: 'Pagos'
      }]}
    >
      <div className="ion-padding">
        <BtnLoading label="Ver consumición actual" onClick={onShowCurrent} className="px-2 py-1 text-white hover:scale-105 bg-brand-red" />

        <ListDocumentsByState
          {...{ user, isAllowed, onShow, history }}
          showFilters={false}
          fixFilters={{
            institutionId: user?.userDoc?.data?.institutionId
          }}
        />
      </div>
    </LayoutAdmin>
  );
};