import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { LayoutAdmin } from "../components/LayoutAdmin";
import TaxonomyType from '../modules/entity/TaxonomyType';
import SectionCrudModel from "../components/SectionCrudModel";
import { typeOptions, typeConfigs } from "../components/DataTypes";
import _ from "lodash";

import {
  FormField,
  FormFieldSelect,
  FormFieldCheckbox,
  FormFieldSelectDocumentModel
} from '../components/Form';

const RenderFilter = (props) => {
  const typeConfig = typeConfigs[props?.taxonomyType?.type];
  
  if (!typeConfig || !typeConfig.RenderFilter) {
    return null;
  }

  return typeConfig.RenderFilter(props);
};

const RenderFilterParam = (props) => {
  const type = props?.taxonomyType?.type;
  const typeConfig = typeConfigs[type];
  if (!typeConfig || !typeConfig.RenderFilterParam) {
    return <b>No disponible</b>;
  }
  return typeConfig.RenderFilterParam(props);
};

const RenderInputParams = ({ values }) => {
  const { type, param } = values;
  const typeConfig = typeConfigs[type];

  if (!typeConfig || !typeConfig.RenderInputParams) {
    return null;
  }

  return typeConfig.RenderInputParams({ values, param });
};

const RenderShowConditionParams = ({ values, taxonomyToWatch }) => {
  const type = taxonomyToWatch?.data?.type;
  const typeConfig = typeConfigs[type];

  if (!typeConfig || !typeConfig.RenderShowConditionParams) {
    return null;
  }

  return typeConfig.RenderShowConditionParams({ values, taxonomyToWatch });
};

const RenderStyleParams = ({ values }) => {
  const type = values.type;
  const typeConfig = typeConfigs[type];

  if (!typeConfig || !typeConfig.RenderStyleParams) {
    return null;
  }

  return typeConfig.RenderStyleParams({ values });
};

const RenderTaxonomyTypePreview = ({ values }) => {
  const { name, nameSlug, type, param } = values;
  const formFieldProps = {
    name: 'style.example',
    type,
    title: name,
    placeholder: name,
    ...param
  };

  const typeConfig = typeConfigs[type];

  if (!typeConfig || !typeConfig.RenderTaxonomyTypePreview) {
    return null;
  }

  return typeConfig.RenderTaxonomyTypePreview({ values, formFieldProps });
};

// default => default
const getDefaultTaxonomyTypeDesign = (values) => {
  const typeConfig = typeConfigs[values.type];

  if (!typeConfig || !typeConfig.getDefaultFormField) {
    return null;
  }

  return typeConfig.getDefaultFormField(values);
};

const TaxonomyTypeForm = ({ doc, onClose, onSave }) => {
  const fieldsRequired = ['name', 'type'];
  const [isSaving, setIsSaving] = useState(false);
  const [taxonomyToWatch, setTaxonomyToWatch] = useState();

  useEffect(() => {
    fetchTaxonomyTypes();
  }, [])

  const fetchTaxonomyTypes = async () => {
    if (doc.data?.show?.taxonomyToWatch && !taxonomyToWatch) {
      const taxonomyToWatch = await TaxonomyType.findById(doc.data?.show?.taxonomyToWatch);
      setTaxonomyToWatch( taxonomyToWatch );
    }
  };

  const handleSubmit = async (values) => {
    if (!values.nameSlug) {
      values.nameSlug = _.camelCase(values.name || '');
    }
    setIsSaving(true);
    await onSave(values);
    setIsSaving(false);
    toast.success('Los datos se han guardado correctamente');
  };

  const options = _.map(typeOptions, ({ key, name }) => ({ value: key, label: name }));

  const validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = ' '; // Requerido
    }
    if (!values.type) {
      errors.type = ' '; // Requerido
    }
    return errors;
  };

  const getDefaultValue = () => {
    let values = { ...doc?.data }
    values.show = values.show || {};
    values.show.condition = values.show.condition || 'always';
    values.style = values.style || {};
    return values;
  }
  

  return (
    <div key={doc.id} className="rounded bg-gray-100 p-5">
      <Form
        onSubmit={handleSubmit}
        initialValues={getDefaultValue()}
        validate={validateForm}
        render={({ form, handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              {/* Basic */}
              <FormField name="name" title="Nombre" placeholder="Nombre" fieldsRequired={fieldsRequired} />
              <FormField name="nameSlug" title="ID" fieldsRequired={fieldsRequired} 
                onChange={(value) => {
                  form.change('nameSlug', _.camelCase(value || ''));
                }}
              />
              <FormFieldSelect name="type" title="Tipo de dato" 
                options={options} fieldsRequired={fieldsRequired} 
                onSelect={(newType) => form.change('style.design', getDefaultTaxonomyTypeDesign({...values, type: newType}) || 'default' )}
              />
              
              {values?.nameSlug ? (<>
                
                {/* Type and Params */}
                <div className="rounded bg-gray-200 p-5">
                  <FormFieldCheckbox name="outstanding" title="Destacado en vistas" options={options} fieldsRequired={fieldsRequired} />
                  <FormFieldCheckbox name="required" title="Requerido en formularios" options={options} fieldsRequired={fieldsRequired} />
                  <FormFieldCheckbox name="hidden" title="Ocultar de vistas y formularios" options={options} fieldsRequired={fieldsRequired} />
                  <FormFieldCheckbox name="readOnly" title="Sólo lectura en formularios" options={options} fieldsRequired={fieldsRequired} />
                  <div className="my-2 border-b border-gray-400"></div>
                  <RenderInputParams values={values} />
                </div>

                {/* Show style */}
                {values.type ? (
                  <div key={values.type} className="rounded bg-gray-200 p-5 mt-5">
                    {/* Params */}
                    <RenderStyleParams values={values} />
                    {/* Example */}
                    <div className="bg-white rounded p-3">
                      <RenderTaxonomyTypePreview values={values} />
                    </div>
                  </div>
                ) : null}

                {/* Show conditions */}
                {/* <div className="rounded bg-gray-200 p-5 mt-5">
                  <FormFieldSelect
                    name="show.condition"
                    title="Visibilidad"
                    options={[
                      { value: 'always', label: 'Ver siempre' },
                      { value: 'hide', label: 'Ocultar según atributo' },
                      { value: 'show', label: 'Mostrar según atributo' }
                    ]}
                  />
                  {(values.show?.condition === 'hide' || values.show?.condition === 'show') ? (<>
                    <FormFieldSelectDocumentModel
                      name="show.taxonomyToWatch"
                      modelName="taxonomyTypes"
                      onSelect={(selectedId, selectedItem) => setTaxonomyToWatch(selectedItem)}
                      queryGetter={(ExtendedModel) => TaxonomyType.findByEntityId(values.entityId)}
                      title="Atributo"
                      placeholder="Atributo"
                    />
                    <RenderShowConditionParams values={values} taxonomyToWatch={taxonomyToWatch} />
                  </>) : ''}
                </div> */}

                {/* Filter */}
                <div className="rounded bg-gray-200 p-2 mt-5">
                  <h3 className="mb-2 pb-2 border-b border-gray-300 font-semibold">Filtro</h3>
                  {/* Params */}
                  <RenderFilterParam {...{form, values, taxonomyType: values}} />
                  {/* Example */}
                  <div className="mt-4 p-3 bg-white rounded">
                    <div className="pb-2 border-b border-gray-200">
                      <h3 className=" font-semibold">Vista previa</h3>
                    </div>
                    <div className="my-10">
                      <RenderFilter {...{
                        form,
                        values,
                        taxonomyType: values
                      }} />
                    </div>
                  </div>
                </div>
              </>) : null}

              {/* Actions */}
              <div className="mt-5 grid grid-cols-2 gap-4">
                <button type='button' className="bg-gray-400 text-gray-100 rounded px-4 p-2 text-xl" onClick={onClose}>
                  Cerrar
                </button>
                <button
                  type="submit"
                  className={`${
                    isSaving ? 'bg-blue-500 text-gray-100 opacity-50' : 'bg-blue-500 text-gray-100'
                  } rounded px-4 p-2 text-xl`}
                  disabled={submitting || isSaving}
                >
                  {isSaving ? 'Guardando...' : 'Guardar'}
                </button>
              </div>
            </form>
          )
        }}
      />
    </div>
  );
};

export function Content({ entityId }) {
  const handleBeforeSave = (doc) => {
    doc.entityId = entityId;
  };

  const ListItem = ({ doc }) => (<div className=''>
    <span className="block font-semibold text-gray-700">{doc.data?.name}</span>
    <span className="text-sm inline text-sky-600">{doc.data?.nameSlug}</span>
    <span className="text-sm inline text-gray-400 ml-2">[ {doc.data?.type} ]</span>
  </div>);

  const fetchItems = async (setItem) => {
    let docs = await TaxonomyType.findByEntityId(entityId);
    docs = docs.filter((doc) => doc.data?.deleted !== true);
    setItem(docs);
  };

  return (
    <div className="">
      <SectionCrudModel
        model={TaxonomyType}
        entitySlug={'taxonomyTypes'}
        editStyle="modal"
        reorder={true}
        title="Atributos"
        navigateTo={(doc) => (`/admin/config/entity-creator/${doc ? doc.id : 'new'}/form`)}

        // callbacks 
        fetchItems={fetchItems}
        handleBeforeSave={handleBeforeSave}

        // add UI
        ListItem={ListItem}
        FormSection={TaxonomyTypeForm}
        // ListBtns={}
      />
    </div>
  );
}

export function AdminEntityCreatorTaxonomy() {
  const { entityId } = useParams();

  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-4">
        <Link to="/admin"><h2 className="text-xl font-thin mr-3">Admin</h2></Link>
        <Link to={`/admin/config/entity-creator/${entityId}/form`}><h2 className="text-xl font-thin mr-3">Entidad</h2></Link>
        <h2 className="text-xl font-semibold">Atributos</h2>
      </div>

      <Content entityId={entityId}></Content>
    </LayoutAdmin>
  );
}