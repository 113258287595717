import { RouteCustomerBillingForm } from './RouteCustomerBillingForm';
import { RouteCustomerBillingGroupList } from './RouteCustomerBillingGroupList';
import { RouteCustomerBillingGroupShow } from './RouteCustomerBillingGroupShow';
import { RouteCustomerBillingGroupShowCurrent } from './RouteCustomerBillingGroupShowCurrent';
import { RouteCustomerBillingList } from './RouteCustomerBillingList';
import { RouteCustomerBillingShow } from './RouteCustomerBillingShow';
// import entities from './entities.json';

// let {
//   institutions
// } = entities;


export default function ({ setPermissions }) {
  return {
    name: 'Cobro a Clientes',
    slug: 'customerBilling',

    permissions: [
      setPermissions({ slug: 'customerBillings', label: 'Cobro a Clientes', type: 'coded', actionsToAdd: ['group:list', 'group:read'] }),
    ],

    entities: {
      // institutions
    },

    routesAdmin: {
      'customerBilling': {
        'list': {
          permission: { resource: 'customerBillings', action: 'list' },
          Content: RouteCustomerBillingList
        },
        'form': {
          permission: { resource: 'customerBillings', action: 'create' },
          Content: RouteCustomerBillingForm
        },
        'show': {
          permission: { resource: 'customerBillings', action: 'read' },
          Content: RouteCustomerBillingShow
        },
        // group
        'groupList': {
          permission: { resource: 'customerBillings', action: 'group:list' },
          Content: RouteCustomerBillingGroupList
        },
        'groupShow': {
          permission: { resource: 'customerBillings', action: 'group:read' },
          Content: RouteCustomerBillingGroupShow
        },
        'groupShowCurrent': {
          permission: { resource: 'customerBillings', action: 'group:read' },
          Content: RouteCustomerBillingGroupShowCurrent
        },
      }
    },
    
    components: {
      // 'institutions': {
      //   crud: {}
      // },
    }
  };
};