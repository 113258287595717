import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";

import dayjs from 'dayjs';
import Model from "../../libs/ModelClass";
import EntityGetOneDocument from "../../components/EntityGetOneDocument";
import BadgeLoading from "../../components/ui/BadgeLoading";
import MapRouteWithPlaces from "./MapRouteWithPlaces";

const DeliveryPacksModel = Model.extend('deliveryPacks');

export const RouteDeliveryShowOrder = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {  
  
  const [deliveryPackDoc, setDeliveryPackDoc] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchFilteredDocs();
  }, [parsedParams?.id]);

  const fetchFilteredDocs = async () => {
    setLoading(true);
    let filteredDocs = await DeliveryPacksModel.findById(parsedParams?.id);
    setDeliveryPackDoc(filteredDocs);
    setLoading(false);
  };
  
  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin/delivery/history`}
      title="Órden de entrega"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        url: '/admin/delivery/history',
        title: 'Planificación de Entregas'
      }, {
        title: 'Ver órden'
      }]}
    >
      <div className="ion-padding">
        {(loading || !deliveryPackDoc) ? (
          <div className="mt-4 mx-auto w-24">
            <BadgeLoading size="md" />
          </div>
        ) : (<>
          <div className="p-2 border border-gray-200 rounded">
            <h2 className="text-xs font-semibold text-black">Id de la órden de entrega</h2>
            <span className="text-sm font-mono text-gray-900">{deliveryPackDoc.id}</span>

            <h2 className="mt-2 text-xs font-semibold text-black">Fecha de la entrega</h2>
            <div className="text-sm text-gray-900">{dayjs(deliveryPackDoc.data?.createdAt).utc().format('LL')}</div>
            {/* User */}
            <h2 className="mt-2 text-xs font-semibold text-black">Conductor</h2>
            <EntityGetOneDocument
              entitySlug={'usersProfiles'}
              docId={deliveryPackDoc.data?.userId}>
              {({ doc, entityDoc }) => (
                isAllowed('usersProfiles', ['list']) ? (
                  <Link to={`/admin/entity/${entityDoc?.id}/${doc?.id}`} className="block text-sm text-gray-900 underline">{doc.data?.name}</Link>
                ) : (
                  <div className="text-sm text-gray-900">{doc.data?.name}</div>
                )
              )}
            </EntityGetOneDocument>
          </div>
          {/* Packs to deliver */}
          <div className="mt-4">
            <MapRouteWithPlaces deliveryPackDoc={deliveryPackDoc} />
          </div>
        </>)}
      </div>
    </LayoutAdmin>
  );
};