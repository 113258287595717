import { useCallback, useEffect, useMemo, useState } from "react";
import _ from 'lodash';
import dayjs from 'dayjs';
import config from "../../config";
import Model from "../../libs/ModelClass";
import BadgeLoading from "../../components/ui/BadgeLoading";
import MapPlanningRoute from "../../components/ui/MapPlanningRoute";
import { PacksPlacesListForPlanning, InfoWindowRender } from "./PacksPlaces";
import SwitchInputA from "../../components/Form/SwitchInputA";

const InstitutionsModel = Model.extend('institutions');
const CartsModel = Model.extend(config?.modules?.cart?.cartsEntitySlug);
const ProductsModel = Model.extend(config?.modules?.cart?.cartItemEntitySlug);
const DeliveryPacksModel = Model.extend('deliveryPacks');

const updateSortedPositions = (sortedList) => {
  sortedList?.forEach((item, index) => {
    item.order = index;
  });
  return _.sortBy(sortedList, ['order']);
};

export const calcTotalDistanceAndDuration = (packs) => {
  if (!Array.isArray(packs) || packs.length === 0) {
    return null; // Devuelve null si no se proporciona un array válido
  }
  // Inicializa variables para acumular la distancia y la duración
  let totalDistanceValue = 0;
  let totalDurationValue = 0;
  // Recorre los "packs" y acumula la distancia y la duración
  packs.forEach((pack) => {
    if (pack.distance && pack.distance.value) {
      totalDistanceValue += pack.distance.value;
    }
    if (pack.duration && pack.duration.value) {
      totalDurationValue += pack.duration.value;
    }
  });
  // Convierte la distancia de metros a kilómetros
  const totalDistanceKilometers = totalDistanceValue / 1000;  
  // Convierte la duración de segundos a minutos
  const totalDurationMinutes = totalDurationValue / 60;
  // Crea objetos "distance" y "duration" con los valores acumulados en km y min
  const totalDistance = {
    text: `${totalDistanceKilometers.toFixed(2)} km`,
    value: totalDistanceKilometers,
  };
  const totalDuration = {
    text: `${totalDurationMinutes.toFixed(2)} min`,
    value: totalDurationMinutes,
  };
  return { distance: totalDistance, duration: totalDuration };
};

const PlanningDeliveryMap = ({ daySelected, packsSelected, onSelectPack }) => {
  const [institutionsDocs, setInstitutionsDocs] = useState([]);
  const [productsDocs, setProductsDocs] = useState([]);
  const [packsByInstitution, setPacksByInstitution] = useState({});
  const [optimizeWaypoints, setOptimizeWaypoints] = useState(false);
  const [packsAssignedOptimized, setPacksAssignedOptimized] = useState([]);
  const [packsAssigned, setPacksAssigned] = useState([]);
  const [packsUnassigned, setPacksUnassigned] = useState([]);
  const [loading, setLoading] = useState(false);

  const totalDistanceAndDuration = useMemo(() => {
    return calcTotalDistanceAndDuration(packsAssignedOptimized);
  }, [packsAssignedOptimized]);

  useEffect(() => {
    fetchAndSpreadPacks();
  }, [daySelected]);

  // update selected packages
  useEffect(() => {
    if (packsAssignedOptimized.length || packsAssigned.length) {
      const packsSelected = (packsAssignedOptimized || packsAssigned).map(
        ({ institutionDoc, productsWithQtyAndDocs, cartsOfInstitution, assigned, delivered, order, distance, duration }) => {
          const carts = cartsOfInstitution.map((cartDoc) => cartDoc.id);
          return {
            order,
            institutionId: institutionDoc.id,
            carts, assigned, delivered,
            productsQty: productsWithQtyAndDocs.length,
            distance, duration
          };
        }
      );
      onSelectPack(packsSelected);
    }
  }, [packsAssigned, packsAssignedOptimized]);

  ////////////////////////////////
  // DATA
  ////////////////////////////////

  const fetchAndSpreadPacks = async () => {
    if (!daySelected) { return; }
    setLoading(true);
    const dayToFilter = dayjs(daySelected).format('YYYY-MM-DD'); // local time to use as input
    let filteredCarts = await CartsModel.filterByAttributes({ daysSelected: { 'in-array': [ dayToFilter ] } });
    filteredCarts = filteredCarts.filter(doc => !doc.data?.deleted);
    let previousPacks = await DeliveryPacksModel.filterByAttributes({ date: dayToFilter });
    previousPacks = previousPacks.filter(doc => !doc.data?.deleted);
    // fetch related documents
    const institutions = institutionsDocs.length ? institutionsDocs : await fetchAllInstitutions();
    const products = productsDocs.length ? productsDocs : await fetchAllProducts();
    // process data by institution
    const cartsByInstitution = _.groupBy(filteredCarts, (cart) => cart.data.institutionId);
    const allPacksByInstitution = _.map(cartsByInstitution, (cartsOfInstitution, institutionId) => {
      const productsWithQtyAndDocs = extractAndFetchProductsAndQtysFromCarts(dayToFilter, cartsOfInstitution, products);
      return {
        institutionDoc: institutions.find(institution => institution.id === institutionId),
        productsWithQtyAndDocs,
        cartsOfInstitution,
        assigned: false,
        delivered: false,
        previouslyAssigned: previousPacks.find(pack => pack.data.institutionsIds?.find(inPackinstitutionId => inPackinstitutionId === institutionId)),
      };
    });
    // spread previously assigned packs of the day
    const pending = allPacksByInstitution.filter(pack => !pack.previouslyAssigned);
    const previouslyAssigned = allPacksByInstitution.filter(pack => pack.previouslyAssigned);
    setPacksByInstitution({
      pending,
      previouslyAssigned
    });
    setPacksAssigned([]);
    setPacksAssignedOptimized([]);
    setPacksUnassigned([ ...pending ]);
    setLoading(false);
  };

  const extractAndFetchProductsAndQtysFromCarts = (daySelected, carts, products) => {
    let productsIdsWithQty = [];
    const addItemToList = (itemId, qty) => {
      const itemInList = productsIdsWithQty.find(itemWithQty => itemWithQty.id === itemId);
      if (itemInList) {
        itemInList.qty += qty;
      } else {
        productsIdsWithQty.push({ 
          productId: itemId,
          qty,
          productDoc: products.find(product => product.id === itemId)
        });
      }
    };
    carts.forEach((cartDoc) => {
      const bagOfDay = cartDoc.data.itemsInBags.find(bag => bag.date === daySelected);
      bagOfDay.bagItems.forEach((itemInBag) => {
        // count main products only
        addItemToList(itemInBag.id, itemInBag.qty);
      });
    });
    productsIdsWithQty = _.sortBy(productsIdsWithQty, ['productDoc.data.name']);
    return productsIdsWithQty;
  };

  const fetchAllInstitutions = async () => {
    let docs = await InstitutionsModel.getAll();
    docs = docs.filter(docs => docs.data.deleted !== true);
    setInstitutionsDocs(docs);
    return docs;
  };

  const fetchAllProducts = async () => {
    let docs = await ProductsModel.getAll();
    docs = docs.filter(docs => docs.data.deleted !== true);
    setProductsDocs(docs);
    return docs;
  };

  ////////////////////////////////
  // LIST
  ////////////////////////////////

  const onCheck = (item, newValueToSet) => {
    const packToUpdate = packsByInstitution.pending.find(pack => pack === item);
    packToUpdate.assigned = newValueToSet;
    // add to Assigned
    if (newValueToSet) {
      packToUpdate.order = packsAssigned.length;
      packsAssigned.push(packToUpdate);
      _.remove(packsUnassigned, packToUpdate);
      setPacksAssigned([ ...packsAssigned ]);
      setPacksUnassigned([ ...packsUnassigned ]);
    } 
    // add to Unassigned
    else {
      packToUpdate.order = null;
      packsUnassigned.unshift(packToUpdate);
      _.remove(packsAssigned, packToUpdate)
      setPacksAssigned( updateSortedPositions(packsAssigned) );
      setPacksUnassigned([ ...packsUnassigned ]);
    }
  };

  const onSetOrder = (sortedList) => {
    const list = updateSortedPositions(sortedList);
    setPacksAssigned( list );
  };

  ////////////////////////////////
  // MAP
  ////////////////////////////////

  const getPointPosition = (place) => {
    return place?.institutionDoc?.data?.gps;
  };

  const processDirecctionsData = (directions) => {
    let packsAssignedUpdate = [...packsAssigned];
    if (!directions?.routes || !packsAssignedUpdate?.length) { return; }
    if (optimizeWaypoints && packsAssignedUpdate.length > 2 && directions.routes && directions.routes[0]) {
      // assign the new sort for waypoints
      const startTravel = packsAssignedUpdate[0];
      const endTravel = packsAssignedUpdate[packsAssignedUpdate.length - 1];
      const waypoints = packsAssignedUpdate.slice(1, -1);
      const newWaypointsOrder = directions.routes[0].waypoint_order.map(newIndex => {
        return waypoints[newIndex];
      });
      packsAssignedUpdate = [startTravel, ...newWaypointsOrder, endTravel];
    }
    // assign distance and duration
    packsAssignedUpdate.forEach((pack, index) => {
      packsAssignedUpdate[ index ].distance = null;
      packsAssignedUpdate[ index ].duration = null;
    });
    directions.routes[0]?.legs?.forEach(({ distance, duration }, index) => {
      if (packsAssignedUpdate[ index + 1 ]) {
        packsAssignedUpdate[ index + 1 ].distance = distance;
        packsAssignedUpdate[ index + 1 ].duration = duration;
      }
    });
    setPacksAssignedOptimized( updateSortedPositions(packsAssignedUpdate) );
  };

  const setDirecctionsRendered = useCallback((directions) => {
    processDirecctionsData(directions);
  }, [packsAssigned]);


  return (<>
      <div className="mt-4">
        <MapPlanningRoute
          placesPoints={packsByInstitution?.pending}
          placesSelected={packsAssigned}
          classes={{
            mapRender: '',
            mapHeight: 'h-96'
          }}
          InfoWindowRender={InfoWindowRender(onCheck)}
          getPointPosition={getPointPosition}
          optimizeWaypoints={optimizeWaypoints}
          setDirecctionsRendered={setDirecctionsRendered}
        />
      </div>
      {loading ? (
        <div className="mt-4 mx-auto w-24">
          <BadgeLoading size="md" />
        </div>
      ) : (<>
        {(packsByInstitution?.pending?.length || packsByInstitution?.previouslyAssigned?.length) ? (<>
          {packsAssigned?.length && (<>
            <div className="flex flex-col place-content-start xs:flex-row xs:place-content-between items-center">
              <h2 className="mt-4 mb-0 xs:mb-2 text-lg font-semibold text-amber-600 text-left w-full xs:w-auto">Asignados</h2>
              <div className="flex flex-row gap-4 items-center place-content-between w-full xs:w-auto mb-2 xs:mb-0">
                {totalDistanceAndDuration?.distance ? (
                  <div className="pt-2 text-xs font-semibold font-mono text-gray-500">
                    {totalDistanceAndDuration.distance?.text} / {totalDistanceAndDuration.duration?.text}
                  </div>
                ) : null}
                <div>
                  <span className="mr-2 text-xs uppercase font-semibold text-gray-500">Optimizar ruta</span>
                  <SwitchInputA
                    value={optimizeWaypoints}
                    onChange={() => setOptimizeWaypoints(!optimizeWaypoints)}
                    {...{
                      colorTrue: "amber-600",
                      colorFalse: "gray-300",
                      textTrue: "SI",
                      textFalse: "NO"
                    }}>
                  </SwitchInputA>
                </div>
              </div>
            </div>
            {packsAssignedOptimized?.length ? (
              <PacksPlacesListForPlanning items={packsAssignedOptimized} onCheck={onCheck} setOrder={onSetOrder} />
            ) : (
              <PacksPlacesListForPlanning items={packsAssigned} onCheck={onCheck} setOrder={onSetOrder} />
            )}
          </>) || null}
          {packsUnassigned?.length && (<>
            <h2 className="mt-4 mb-2 text-lg font-semibold text-brand-red">Pendientes</h2>
            <PacksPlacesListForPlanning items={packsUnassigned} onCheck={onCheck} />
          </>) || null}
          {packsByInstitution?.previouslyAssigned?.length && (<>
            <h2 className="mt-4 mb-2 text-lg font-semibold text-gray-900">Asignados previamente</h2>
            <PacksPlacesListForPlanning items={packsByInstitution?.previouslyAssigned} showSwitch={false} />
          </>) || null}
        </>) : (
          <div className="mt-4 text-center">
            No hay pedidos para el día
          </div>
        )}
      </>)}
  </>);
};

export default PlanningDeliveryMap;