import { IonBadge, IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';
import _ from 'lodash';
import { 
  infiniteOutline
} from 'ionicons/icons';
import { compareVersions } from 'compare-versions';
import entities from './entities.json';
import { AdminHomeBeforeLinksList } from './AdminHome';

let { version } = entities;

export const sortDocsVersions = (docs) => {
  return docs.sort((a, b) => {
    return compareVersions(a?.data?.versionCode || '0', b?.data?.versionCode || '0', '>') === 1 ? 1 : -1;
  })
  .reverse();
};

const AdminConfigAfterLinksListForVersions = ({ isAllowed }) => (<>
  {isAllowed('systemVersions', ['list']) ? (
    <IonItem routerLink="/admin/entity/lCzJQLdmfUBGMEnyj9SR">
      <IonIcon icon={infiniteOutline}></IonIcon>
      <IonLabel className="ml-2">Versiones del sistema</IonLabel>
    </IonItem>
  ) : null}
</>);

const UnderListItemTitleForSystemVersions = ({ ViewData }) => (<>
  <ViewData field="announcedBranchs" classes={{ fieldContainer: 'text-sm text-gray-800' }} />
  <ViewData field="upgradeLevel" classes={{ fieldContainer: 'text-xs text-gray-800' }} />
</>);

export default function ({ setPermissions }) {
  return {
    slug: 'panel',
    label: 'Panel del sistema',
    entities: {
      version
    },
    permissions: [
      setPermissions({ slug: 'systemVersions', label: 'Versiones del sistema', type: 'system' })
    ],
    components: {
      'main': {
        AdminHomeBeforeLinksList,
        AdminConfigAfterLinksList: AdminConfigAfterLinksListForVersions
      },
      'systemVersions': {
        crud: {
          settings: () => ({
            addButtonLabel: 'Anunciar nueva versión',
            sortDocs: sortDocsVersions
          }), 
          UnderListItemTitle: UnderListItemTitleForSystemVersions
        }
      },
    },
  };
};