import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { useEffect, useState } from 'react';
import _ from 'lodash';


const useGPS = () => {
  const [gpsLocation, setLocation] = useState(null);
  const [gpsError, setError] = useState(null);

  useEffect(() => {
    const checkAndRequestPermissions = async () => {
      const permissionStatus = await Geolocation.checkPermissions();

      if (permissionStatus.location === 'granted') {
        getCurrentPosition();
      } else if (permissionStatus.location === 'prompt') {
        requestGPSPermissions();
      } else {
        setError('GPS permission denied');
      }
    };

    const requestGPSPermissions = async () => {
      const result = await Geolocation.requestPermissions();

      if (result.location === 'granted') {
        getCurrentPosition();
      } else {
        setError('GPS permission denied');
      }
    };

    const getCurrentPosition = async () => {
      try {
        const position = await Geolocation.getCurrentPosition();
        setLocation(position);
      } catch (error) {
        setError('Error getting current position');
      }
    };

    if (Capacitor.isNativePlatform()) {
      checkAndRequestPermissions();
    }

    // Cleanup function
    return () => {
      // Perform any cleanup if needed
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return {
    gpsLocation,
    gpsError
  };
};

export default useGPS;
