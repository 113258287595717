import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
} from "@ionic/react";
import { useLocation } from "react-router-dom";
import { CurrentUserItem, UserList } from "./MenuOfUserSession";
import config from "../config";
import { useModule } from "../context/ModuleContext";
import { usePanel } from "../modules/panel/PanelContext";
import { useMemo } from "react";

export function MenuMainContent({ 
  history,
  triggerId,
  appPages
}) {
  const location = useLocation();
  const { isAllowed } = useModule(); 
  const { versionCurrent } = usePanel();
  const version = useMemo(() => {
    return versionCurrent ? (
      `${versionCurrent?.bundle?.version} / ${versionCurrent?.native}`
    ) : (
      config?.version
    )
  }, [versionCurrent]);

  return (
    <IonContent>
      <div className="flex flex-col place-content-center pt-6 pb-10">
        <img src="/logo-icono-color.svg" className="h-16 md:h-12 mb-3" />
        <img src="/logo-texto-negro.svg" className="h-8 sm:h-8" />
      </div>

      <IonItem>
        <CurrentUserItem />
      </IonItem>

      <IonList>
        {appPages.map((appPage, index) => {
          return (
            (appPage?.permissions 
              ? isAllowed(appPage?.permissions?.resource, appPage?.permissions?.actions)
              : true
            ) ? (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            ) : null
          );
        })}
      </IonList>

      <UserList history={history} showCurrentUser={false} triggerMode="menu" triggerId={triggerId} />

      <div className="absolute bottom-4 font-normal text-xs w-full text-center mt-14 text-gray-400">
        {version}
      </div>
    </IonContent>
  );
}