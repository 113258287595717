import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaSort } from "react-icons/fa";
import _ from 'lodash';
import SwitchInputB from "../../components/Form/SwitchInputB";
import { useMemo } from 'react';
import dayjs from 'dayjs';

const unitsLabel = 'paquetes';
const cartsLabel = 'pedidos';

export const InfoWindowRender = (onCheck) => ({ point }) => {
  const { institutionDoc, productsWithQtyAndDocs, cartsOfInstitution, assigned, delivered, order, distance, duration } = point;

  return (
    <div className="">
      <div className="flex flex-row items-center gap-3">
        {assigned ? (
          <span className="text-brand-red uppercase font-semibold text-lg">{order+1}</span>
        ) : null}
        <div className="-mt-1.5 w-[115px]">
          <SwitchInputB
            value={assigned}
            onChange={() => onCheck(point, !assigned)}
            {...{
              colorTrue: "amber-600",
              colorFalse: "gray-300",
              textTrue: "asignado",
              textFalse: "pendiente"
            }}>
          </SwitchInputB>
        </div>
      </div>
      <div className="mt-2 max-w-[200px]">
        {distance?.text ? (
          <div className="text-xs font-semibold font-mono text-gray-500">
            {distance?.text} / {duration?.text}
          </div>
        ) : null}
        <div className="mt-1.5 text-base font-semibold text-black">
          {institutionDoc.data.name}
        </div>
        <div className="">
          <span className="mr-3 text-sm text-gray-700 font-mono">
            {productsWithQtyAndDocs.length} {unitsLabel}
          </span>
          <span className="text-sm text-gray-700 font-mono">
            {cartsOfInstitution.length} {cartsLabel}
          </span>
        </div>
        <div className="text-gray-600 text-xs space-x-2">
          {institutionDoc.data.city ? (
            <span>{institutionDoc.data.city}</span>
          ) : null}
          {institutionDoc.data.location ? (
            <span>{institutionDoc.data.location}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const PacksPlacesListForPlanning = ({ items, setOrder, onCheck, showSwitch=true }) => {
  const handleDragEnd = (items) => async (result) => {
    if (!result.destination) return;
    const newOrder = Array.from(items);
    const [movedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedItem);
    setOrder(newOrder);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd(items)}>
      <Droppable droppableId="docs">
        {(provided) => (
          <ul className="px-2 border border-gray-200 rounded-md" {...provided.droppableProps} ref={provided.innerRef}>
            {items.map(({ institutionDoc, productsWithQtyAndDocs, cartsOfInstitution, assigned, delivered, order, distance, duration }, index) => (
              <Draggable key={institutionDoc?.id} draggableId={institutionDoc?.id} index={order}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}
                  className={`flex flex-col items-start gap-2 py-2 border-b border-gray-200 last:border-none`}>
                    {(setOrder || onCheck) ? (
                      <div className="flex flex-row items-center">
                        {/* sorter handler */}
                        {setOrder ? (
                          <div className="text-gray-400 w-10 flex flex-row items-center" {...provided.dragHandleProps}>
                            <div>
                              <FaSort className="w-4 h-4" />
                            </div>
                            {assigned ? (
                              <span className="text-brand-red uppercase font-semibold">{order+1}</span>
                            ) : null}
                          </div>
                        ) : null}
                        {/* status */}
                        {delivered ? (
                          <div className="w-30 text-sm text-gray-500">Entregado</div>
                        ) : (
                          showSwitch ? (
                            <div className="-mt-1 w-[115px]">
                              <SwitchInputB
                                value={assigned}
                                onChange={() => onCheck(items[index], !assigned)}
                                {...{
                                  colorTrue: "amber-600",
                                  colorFalse: "gray-300",
                                  textTrue: "asignado",
                                  textFalse: "pendiente"
                                }}>
                              </SwitchInputB>
                            </div>
                          ) : null
                        )}
                        {distance?.text ? (
                          <div className="text-xs font-semibold font-mono text-gray-500">
                            {distance?.text} / {duration?.text}
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    {/* data */}
                    <div className="">
                      <div className="text-base font-regular">
                        <span className="text-black">
                          {institutionDoc?.data?.name}
                        </span>
                        <span className="ml-3 text-sm text-gray-700 font-mono">
                          {productsWithQtyAndDocs.length} {unitsLabel}
                        </span>
                        <span className="ml-3 text-sm text-gray-700 font-mono">
                          {cartsOfInstitution.length} {cartsLabel}
                        </span>
                      </div>
                      <div className="text-gray-600 text-xs space-x-2">
                        {institutionDoc?.data?.city ? (
                          <span>{institutionDoc?.data?.city}</span>
                        ) : null}
                        {institutionDoc?.data?.location ? (
                          <span>{institutionDoc?.data?.location}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
};

export const InfoWindowRenderForDriving = (controls) => ({ point, index }) => {
  const { institutionDoc, productsQty, carts, assigned, delivered, order, distance, duration } = point;

  return (
    <div className="">
      <div className="flex flex-row gap-2 items-center">
        {/* status */}
        {delivered ? (<>
          <div className="text-gray-500 font-semibold text-lg">{order+1}</div>
          <div className="text-xs text-gray-500">Entregado</div>
        </>) : (<>
          <div className="text-brand-red font-semibold text-lg">{order+1}</div>
          <div className="text-xs text-brand-red">Pendiente</div>
        </>)}
      </div>
      <div className="max-w-[200px]">
        {distance?.text ? (
          <div className="text-xs font-semibold font-mono text-gray-500">
            {distance?.text} / {duration?.text}
          </div>
        ) : null}
        <div className="mt-1.5 text-base font-semibold text-black">
          {institutionDoc.data.name}
        </div>
        <div className="">
          <span className="mr-3 text-sm text-gray-700 font-mono">
            {productsQty} {unitsLabel}
          </span>
          <span className="text-sm text-gray-700 font-mono">
            {carts.length} {cartsLabel}
          </span>
        </div>
        <div className="text-gray-600 text-xs space-x-2">
          {institutionDoc.data.city ? (
            <span>{institutionDoc.data.city}</span>
          ) : null}
          {institutionDoc.data.location ? (
            <span>{institutionDoc.data.location}</span>
          ) : null}
        </div>
      </div>
      {/* controls */}
      {/* {controls ? (
        <div className={`flex my-6 flex-col w-36 mx-auto gap-6 place-content-center`}>
          <button type="button" className="px-2.5 py-1 rounded bg-gray-200 text-gray-700 text-xs" onClick={() => controls.openMaps(index)}>Mapa</button>
          <button type="button" className="px-2.5 py-1 rounded bg-gray-200 text-gray-700 text-xs" onClick={() => controls.setObservations(index)}>Observaciones</button>
          <button type="button" className="px-2.5 py-1 rounded bg-amber-600 text-white text-xs" onClick={() => controls.setAsDelivered(index)}>Entregado</button>
        </div>
      ) : null} */}
    </div>
  );
};

export const PacksPlacesListForDriving = ({
  items,
  isShow = () => true,
  controls
}) => {
  const firstShowing = useMemo(() => {
    return items.findIndex(item => item.delivered === false);
  }, [items]);

  return (
    <ul className="px-2 border border-gray-200 rounded-md">
      {items.map(({ institutionDoc, productsQty, carts, assigned, delivered, deliveredDate, order, distance, duration }, index) => (
        isShow(index) ? (
          <div key={index} className={`py-4 border-b border-gray-200 last:border-none`}>
            <div className="flex flex-row gap-2 items-center">
              {/* status */}
              {delivered ? (<>
                <div className="text-gray-500 font-semibold text-lg">{order+1}</div>
                <div className="text-xs text-gray-500">
                  Entregado a las {dayjs(deliveredDate).format('hh:mm')}
                </div>
              </>) : (<>
                <div className="text-brand-red font-semibold text-lg">{order+1}</div>
                <div className="text-xs text-brand-red">Pendiente</div>
              </>)}
              {distance?.text ? (
                <div className="pt-0.5 text-xs font-semibold font-mono text-gray-500">
                  {distance?.text} / {duration?.text}
                </div>
              ) : null}
            </div>
            {/* data */}
            <div className="">
              <div className="text-base font-regular">
                <span className="text-black">
                  {institutionDoc.data.name}
                </span>
                <span className="ml-3 text-sm text-gray-700 font-mono">
                  {productsQty} {unitsLabel}
                </span>
                <span className="ml-3 text-sm text-gray-700 font-mono">
                  {carts.length} {cartsLabel}
                </span>
              </div>
              <div className="text-gray-600 text-xs space-x-2">
                {institutionDoc.data.city ? (
                  <span>{institutionDoc.data.city}</span>
                ) : null}
                {institutionDoc.data.location ? (
                  <span>{institutionDoc.data.location}</span>
                ) : null}
              </div>
            </div>
            {/* controls */}
            {controls ? (
              <div className={`flex ${firstShowing === index ? 'my-6 flex-col w-36 mx-auto gap-6' : 'mt-4 flex-row gap-4'} place-content-center`}>
                <button type="button" className="px-2.5 py-1 rounded bg-gray-200 text-gray-700 text-xs" onClick={() => controls.openMaps(index)}>Mapa</button>
                <button type="button" className="px-2.5 py-1 rounded bg-gray-200 text-gray-700 text-xs" onClick={() => controls.setObservations(index)}>Observaciones</button>
                <button type="button" className="px-2.5 py-1 rounded bg-amber-600 text-white text-xs" onClick={() => controls.setAsDelivered(index)}>Entregado</button>
              </div>
            ) : null}
          </div>
        ) : null
      ))}
    </ul>
  )
};