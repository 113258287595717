import { LayoutAdmin } from "../components/LayoutAdmin";
import { useModule } from "../context/ModuleContext";
import PartOfModule from "../components/Module/PartOfModule";
import { MenuOfEntityCrud } from "../components/MenuOfEntityCrud";
import { IonTitle } from "@ionic/react";

export function AdminTabEntity(props) {
  const { isAllowed } = useModule();  

  return (
    <LayoutAdmin 
      history={props.history}
      title="Listados"
    >
      <div className="ion-padding">
        <PartOfModule
          type="main"
          action="AdminEntityBeforeLinksList"
          param={{ isAllowed }}
        />

        <MenuOfEntityCrud></MenuOfEntityCrud>

        <PartOfModule
          type="main"
          action="AdminEntityAfterLinksList"
          param={{ isAllowed }}
        />
      </div>

      {/* <div className="mt-[300px]">
        <button className="p-2 bg-red-100 text-red-700 rounded-sm" type="button" 
        onClick={async () => {
          await updateallFilesMetadata();
        }}>
          Actualizar metadatos
        </button>
      </div> */}
    </LayoutAdmin>
  );
}