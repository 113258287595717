import { useEffect, useState } from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
// import MetaTags from 'react-meta-tags';
import Model from "../libs/ModelClass";
import _ from "lodash";
import { LayoutPublic } from "../components/LayoutPublic";
import config from "../config";
import { parseAttrValParams } from '../libs/utils';
import useBreakpoints from '../libs/useBreakpoints';
import { blocks } from "../stringsMap";
import CtaDownloadBlock from "../components/Blocks/CtaDownloadBlock";
import { animateScroll as scroll } from 'react-scroll';
import { usePartOfModule } from "../components/Module/PartOfModule";
import Page404 from './Page404';
import { useModule } from "../context/ModuleContext";

export function PublicModulePage({ defaultPath, history }) {
  const { isAllowed } = useModule();
  const { isMinBreakpointActive } = useBreakpoints();
  const location = useLocation();

  const { entitySlug, action, '*': attrValParams } = useParams();
  const [parsedParams, setParsedParams] = useState();

  useEffect(() => {
    if (!parsedParams) {
      setParsedParams( parseAttrValParams( attrValParams ) ); 
    }
  }, [location]);

  const routeOfModule = usePartOfModule({
    type: 'routePublic',
    entitySlug,
    action,
    param: { 
      parsedParams,
      entitySlug, 
      action,
      config,
      isAllowed, 
      Model,
      isMinBreakpointActive, 
      location, 
      history, 
     }
  });

  const HeaderMenu = () => (<>
    <Link
      to="/"
      title={config.brand.name}
      className="ml-1 py-2 px-2 mr-2 md:px-6 text-gray-200 hover:text-gray-400 md:text-lg lg:text-xl text-sm cursor-pointer"
      onClick={() => scroll.scrollToTop()}
    >
      Inicio
    </Link>
  </>)

  return (
    <LayoutPublic HeaderMenu={HeaderMenu}>
      {/* <MetaTags>
        <title>{getPageTitle(config.projectName)}</title>
        <meta name={getPageTitle()} content={config.projectName} />
      </MetaTags> */}

      {routeOfModule?.Content ? (
        <routeOfModule.Content />)
      : (
        <Page404 />
      )}

      <div className="-mx-5">
        <CtaDownloadBlock data={blocks.ctaFooter} />
      </div>
    </LayoutPublic>
  );
}
