import { useRef, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonLabel, IonMenu, IonModal, IonPage, IonTitle, IonToolbar, useIonModal } from "@ionic/react";
import Drawer from 'react-modern-drawer'
import { FormChangeSubscriber } from '../Form';
import { SelectedValuesList, FormFiltersRender } from '.';
import useBreakpoints from '../../libs/useBreakpoints';


const MenuStyleCollapsiblePanel = (props) => {
  let {
    filterMenuTaxonomyTypes,
    form,
    values,
    handleSubmit,
    classes,
    isAlwaysExpanded = false,
    Components,
    history
  } = props;
  const { isMinBreakpointActive } = useBreakpoints();
  const [isOpen, setIsOpen] = useState(false)

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }
  
  let renderFilterParams = {
    showLabel: true,
    showClearBtn: true,
    style: {
      classes: { 
        fieldContainer: '',
        fieldLabel: 'mb-2 flex flex-row place-content-between items-center h-6 text-sm',
        fieldInput: 'block w-full',
      }
    }
  };
  
  const onFormChange = (formValues) => {
    if (formValues) {
      handleSubmit(formValues);
    }
  };
  
  return (
    // sm
    <div className={`
      ${classes?.filterMenuContainer || 'px-2 py-2 border rounded'} 
      border-gray-100 bg-gray-50
    `}>
      <form onSubmit={handleSubmit}>
        <FormChangeSubscriber onFormChange={onFormChange} />

        <div className="flex flex-wrap gap-2">
          {isMinBreakpointActive('lg') ? (
            <IonLabel expand="block" color="primary" size="small" className="inline-block m-0">
              Filtros
            </IonLabel>
          ) : (
            <IonButton onClick={toggleDrawer} expand="block" color="primary" size="small" className="inline-block m-0">
              Filtros
            </IonButton>
          )}
          {/* show selected values */}
          <SelectedValuesList {...{form, values, filterMenuTaxonomyTypes }} />
        </div>

        {/* Filters list */}
        {isMinBreakpointActive('lg') ? (
          <div className='p-2.5 mt-2.5 space-y-8 overflow-y-scroll lg:overflow-y-visible max-h-[60vh] lg:max-h-none border-gray-200 bg-white rounded'>
            <FormFiltersRender
              {...props}
              renderFilterParams={renderFilterParams}
              classes={{ selectedFilter: 'text-gray-900 font-semibold' }} // classes al ultimo para sobreescribir props.classes
            />
          </div>
        ) : null}

        <Drawer
          open={isMinBreakpointActive('lg') ? false : isOpen}
          onClose={toggleDrawer}
          direction='right'
          className="p-2 py-24 overflow-y-scroll overflow-x-hidden space-y-8"
          size={
            isMinBreakpointActive('md') ? "40vw" :
            isMinBreakpointActive('sm') ? "60vw" : "80vw"
          }
        >
          <FormFiltersRender
            {...props}
            renderFilterParams={renderFilterParams}
            classes={{ selectedFilter: 'text-gray-900 font-semibold' }} // classes al ultimo para sobreescribir props.classes
          />
        </Drawer>
      </form>
    </div>
  );
}


export default MenuStyleCollapsiblePanel;