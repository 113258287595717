import _ from 'lodash';
import { priceFormat } from "../../libs/utils";
import BtnLoading from '../../components/ui/BtnLoading';


export const QtyButtons = ({ itemInBag, bagId, itemId, setItemToBag, price, extraItemId, onChange = _.noop, Render }) => {
  
  const getQty = () => {
    return itemInBag?.qty ||  1;
  }

  const restOne = () => {
    setItemToBag(bagId, itemId, getQty() - 1, extraItemId);
    onChange();
  };

  const addOne = () => {
    setItemToBag(bagId, itemId, getQty() + 1, extraItemId);
    onChange();
  };

  const setVal = (e) => {
    let newVal = parseInt(e.target.value);
    setItemToBag(bagId, itemId, newVal, extraItemId);
    onChange();
  };

  const QtySelector = (props) => (
    <div className={props?.className}>
      <h3 className="uppercase text-xs">Cantidad</h3>
      <div className="w-24 rounded-sm cursor-point border-2 border-gray-200 flex flex-row">
        {/* remove */}
        <button type="button" className="p-0.5 bg-gray-200 text-gray-900 font-semibold text-xl w-8"
        disabled={getQty() < 1}
        onClick={restOne}>
          <div className="-mt-1">-</div>
        </button>
        <input type="number" min={1} className="py-0.5 pl-3 text-gray-900 font-semibold text-xs text-center w-full"
        value={getQty()} onChange={setVal} />
        {/* add */}
        <button type="button" className="p-0.5 bg-gray-200 text-gray-900 font-semibold text-xl w-8"
        onClick={addOne}>
          <div className="-mt-1">+</div>
        </button>
      </div>
    </div>
  );

  const Total = (props) => (
    <div className={props?.classes?.container}>
      <h3 className={props?.classes?.label || 'uppercase text-xs'}>Total</h3>
      <div className={props?.classes?.value || 'text-xs text-gray-900 font-semibold'}>{priceFormat(getQty() * price)}</div>
    </div>
  );

  const RemoveButton = (props) => (
    <div className={props?.className}>
      {itemInBag ? (
        <BtnLoading  
          label="Quitar del pedido"
          onClick={() => { setItemToBag(bagId, itemId, 0, extraItemId); onChange(); }} 
          className={`inline-block px-1 text-xs text-red-700 bg-white content-center shadow-sm mx-auto`} 
        />
      ) : null}
    </div>
  );

  const template = (
    <>
      <QtySelector />

      <Total />

      <RemoveButton className="pt-2" />
    </>
  );
  
  return (
    Render 
      ? <Render {...{ QtySelector, Total, RemoveButton }} />
      : template
  );
};