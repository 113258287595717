import { Field } from 'react-final-form';
import _ from 'lodash';
import { ShowError } from './formUtils';
import Select from 'react-select'


const FormFieldSelect = (props) => {
  let {
    name,
    title,
    options,
    placeholder,
    fieldsRequired = null,
    validate = null,
    disabled = false,
    showLabel = true,
    showClearBtn = true,
    onSelect = (() => null),
  } = props;

  return (
    <div className={`pb-3 ${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={validate}>
        {({ input, meta }) => (<>
          {showLabel && title ? (
            <label position="floating" className={props?.classes?.fieldLabel}>
              <span
                className={`font-semibold mr-3 ${
                  meta.touched && meta.error
                    ? 'text-red-600'
                    : 'text-gray-900'
                }`}
              >
                {title}
                {meta.touched && meta.error ? '*' : ''}
              </span>

              {showClearBtn && input?.value ? (
                <button
                  type="button"
                  className="px-1.5 py-0.5 font-regular text-xs bg-gray-100 text-gray-400 rounded-full hover:text-gray-800 inline-block"
                  onClick={() => input?.onChange(undefined)}
                >
                  X
                </button>
              ) : ''}
            </label>
          ) : ''}
          <div className="relative">
            <Select
              options={options}
              isMulti={false}
              placeholder={`Seleccionar`}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'lightgray',
                  primary: 'darkgray',
                },
              })}
              value={input?.value ? (options.find(opt => opt.value === input.value)) : null}
              onChange={option => { 
                input?.onChange(option.value); 
                onSelect(option.value); 
              }}
            />
          </div>
          {/* error */}
          <ShowError name={input?.name} input={input} meta={meta} fieldsRequired={fieldsRequired} />
        </>)}
      </Field>
    </div>
  );
};

export default FormFieldSelect;
