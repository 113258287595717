import _ from 'lodash';
import {useAsyncMemo} from "use-async-memo"
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { LayoutAdmin } from "../../components/LayoutAdmin";
import BtnLoading from "../../components/ui/BtnLoading";
import { UserDataCard } from '../user/UserDataCard';
import { IonAlert, IonIcon } from '@ionic/react';
import { 
  bookmarkOutline
} from 'ionicons/icons';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import EntityGetOneDocument from '../../components/EntityGetOneDocument';
import { InstitutionSelector } from './InstitutionSelector';

const GridDocs = ({ docs, isAllowed, setDocToApprove, docHightligh, setDocHightligh, setConfirmMode }) => (
  <div className={`grid gap-2 grid-cols-2 md:grid-cols-3 xl:grid-cols-4`}>
    {docs?.map((doc, index) => (
      <div className="" key={doc.id}>
        <UserDataCard 
          userData={doc.data}
          classes={{ cardContainer: docHightligh === doc ? '!border-gray-500' : '' }}
          showUserLink={isAllowed('usersProfiles', ['list'])}
          underList={(
            <div className="mt-2 border-t border-gray-200">
              {docHightligh === doc ? (
                <div className="w-auto !mt-0 px-2 pt-1.5 pb-0.5 text-md absolute top-0 right-0 rounded-bl-md text-white bg-gray-500">
                  <IonIcon icon={bookmarkOutline}></IonIcon>
                </div>
              ) : null}
              <div className={`my-1.5 mr-2 ${!doc.data.institutionApprovedDate && !doc.data.institutionBloquedDate ? 'block' : 'inline-block'}`}>
                {doc.data.institutionApprovedDate ? (
                  <span className="text-xs px-2.5 py-0.5 rounded-full text-gray-600 bg-gray-50 border border-gray-200">miembro</span>
                ) : (<>
                  <span className="text-xs px-2.5 py-0.5 rounded-full text-gray-600 bg-gray-50 border border-gray-200">pendiente</span>
                </>)}
              </div>
              {!doc.data.institutionApprovedDate ? (
                <BtnLoading
                  label="Aprobar"
                  withLoading className="mr-1.5 px-2 py-0.5 !text-blue-800 hover:scale-105 bg-blue-200 shadow-none text-xs !inline-block"
                  onClickAsync={() => {
                    setDocHightligh(doc);
                    setDocToApprove(doc);
                    setConfirmMode('approve');
                  }} 
                />
              ) : null}
              {!doc.data.institutionBloquedDate ? (
                <BtnLoading
                  label="Bloquear"
                  withLoading className="px-2 py-0.5 text-gray-800 hover:scale-105 bg-gray-200 shadow-none text-xs !inline-block"
                  onClickAsync={() => {
                    setDocHightligh(doc);
                    setDocToApprove(doc);
                    setConfirmMode('block');
                  }} 
                />
              ) : null}
              <div className="">
                {/* User */}
                {doc.data.institutionApprovedBy || doc.data.institutionBloquedBy ? (
                  <span className="text-xs text-gray-500 mr-1">
                    {doc.data.institutionApprovedBy ? (
                      "Aprobado por:"
                    ) : null}
                    {doc.data.institutionBloquedBy ? (
                      "Bloqueado por:"
                    ) : null}
                  </span>
                ) : null}
                {doc.data.institutionApprovedBy || doc.data.institutionBloquedBy ? (
                  <EntityGetOneDocument
                    entitySlug={'usersProfiles'}
                    docId={doc.data.institutionApprovedBy || doc.data.institutionBloquedBy}>
                    {({ doc, entityDoc }) => (
                      isAllowed('usersProfiles', ['list']) ? (
                        <Link to={`/admin/entity/${entityDoc?.id}/${doc?.id}`} className="text-xs text-gray-500 underline">{doc.data?.name}</Link>
                      ) : (
                        <span className="text-xs text-gray-500">{doc.data?.name}</span>
                      )
                    )}
                  </EntityGetOneDocument>
                ) : null}
              </div>
              {/* Date */}
              {doc.data.institutionApprovedDate || doc.data.institutionBloquedDate ? (
                <div className="text-xs text-gray-500">
                  Fecha: {dayjs(doc.data.institutionApprovedDate || doc.data.institutionBloquedDate).format('DD/MM/YYYY HH:mm')}
                </div>
              ) : null}
            </div>
          )}
        />
      </div>
    ))}
  </div>
);

export const RouteInstitutionMembers = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {
  const [ docToApprove, setDocToApprove ] = useState();
  const [ docHightligh, setDocHightligh ] = useState();
  const [ confirmMode, setConfirmMode ] = useState(false);
  const [ selectedInstitution, setSelectedInstitution ] = useState();
  const institutionId = selectedInstitution?.data?.id || user?.userDoc?.data?.institutionId;
  const showInstitutionSelector = isAllowed('institutions', ['list']);

  const filteredDocs = useAsyncMemo(async () => {
    const ExtendedModel = Model.extend('usersProfiles');
    let filteredDocs = await ExtendedModel.filterByAttributes({ institutionId });
    filteredDocs = filteredDocs.filter(doc => !doc.data?.deleted);
    filteredDocs = _.sortBy(filteredDocs, ['data', 'name']);
    return filteredDocs;
  }, [ institutionId ]);

  const docsPending = useMemo(() => {
    return filteredDocs?.filter(doc => !doc.data?.institutionApprovedDate && !doc.data?.institutionBloquedDate);
  }, [filteredDocs, docToApprove]);

  const docsApproved = useMemo(() => {
    return filteredDocs?.filter(doc => doc.data?.institutionApprovedDate && !doc.data?.institutionBloquedDate);
  }, [filteredDocs, docToApprove]);

  const docsBloqued = useMemo(() => {
    return filteredDocs?.filter(doc => doc.data?.institutionBloquedDate);
  }, [filteredDocs, docToApprove]);

  const confirmedApprove = async () => {
    docToApprove.data.institutionApprovedDate = new Date().toISOString();
    docToApprove.data.institutionApprovedBy = user.userDoc.id;
    docToApprove.data.institutionBloquedDate = null;
    docToApprove.data.institutionBloquedBy = null;
    docToApprove.save();
    setDocToApprove(null);
    setConfirmMode(false);
    setDocHightligh(docToApprove);
    toast.success('Usuario aprobado');
  };

  const confirmedBloqued = async () => {
    docToApprove.data.institutionApprovedDate = null;
    docToApprove.data.institutionApprovedBy = null;
    docToApprove.data.institutionBloquedDate = new Date().toISOString();
    docToApprove.data.institutionBloquedBy = user.userDoc.id;
    docToApprove.save();
    setDocToApprove(null);
    setConfirmMode(false);
    setDocHightligh(docToApprove);
    toast('Usuario bloqueado', {icon: '🚫'});
  };

  const gridProps = { isAllowed, setDocToApprove, docHightligh, setDocHightligh, setConfirmMode };

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin`}
      title="Miembros de la Institución"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        title: "Miembros de la Institución"
      }]}
    > 
      <div className="ion-padding pb-24 flex flex-col md:flex-row gap-4">
        {showInstitutionSelector ? (
          <div className="md:w-1/3">
            <InstitutionSelector title="Selecciona Institución" {...{ selectedInstitution, setSelectedInstitution, selectedId: institutionId }} />
          </div>
        ) : null}
        <div className={`space-y-8 ${showInstitutionSelector ? 'md:w-2/3' : ''}`}>
          {docsPending?.length && (
            <div>
              <h2 className="text-lg font-semibold text-brand-red">Revisión</h2>
              <h3 className="mb-2 text-sm font-light text-gray-500">Usuarios pendientes de aprobación</h3>
              <GridDocs docs={docsPending} {...gridProps} />
            </div>
          ) || null}

          {docsApproved?.length && (
            <div>
              <h2 className="text-lg font-semibold text-brand-dark">Miembros</h2>
              <h3 className="mb-2 text-sm font-light text-gray-500">Usuarios aprobados</h3>
              <GridDocs docs={docsApproved} {...gridProps} />
            </div>
          ) || null}

          {docsBloqued?.length && (
            <div>
              <h2 className="text-lg font-semibold text-brand-dark">Bloqueados</h2>
              <h3 className="mb-2 text-sm font-light text-gray-500">Usuarios sin acceso</h3>
              <GridDocs docs={docsBloqued} {...gridProps} />
            </div>
          ) || null}
        </div>
      </div>
      <IonAlert
        header={confirmMode === "approve" ? "Aprobar acceso al usuario" : "Bloquear usuario"}
        isOpen={confirmMode}
        buttons={[
          {
            text: confirmMode === "approve" ? "Aprobar acceso al usuario" : "Bloquear usuario",
            role: 'confirm',
            handler: confirmMode === "approve" ? confirmedApprove : confirmedBloqued,
          },
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
            },
          },
        ]}
        onDidDismiss={() => {
          setDocToApprove(null);
          setConfirmMode(false);
        }}
      ></IonAlert>
    </LayoutAdmin>
  );
};