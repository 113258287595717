import { Link } from "react-router-dom";
import { getImageURL, numberFormat } from "../../libs/utils";

export const UserDataCard = ({
  userData,
  underList,
  selectedInstitution,
  showUserLink,
  classes
}) => {
  return (
    <div className={`p-3 border border-gray-300 rounded-md shadow-md relative ${classes?.cardContainer}`}>
      <div className="text-left space-y-1.5 text-gray-900 text-sm">
        {/* {userData.name ? (<p><span className="text-black font-medium block">Nombre</span> {userData.name}</p>) : null} */}
        {userData.firstName ? (<p>
          <span className="text-black font-medium block">Nombre completo</span>
          {showUserLink ? (
            <Link to={`/admin/entity/usersProfiles/${userData.id}`} className="text-lg underline">{userData.firstName} {userData.lastName}</Link>
          ) : (
            <span className="text-lg">{userData.firstName} {userData.lastName}</span>
          )}
        </p>) : null}
        {userData.name ? (<p><span className="text-black font-medium block">Nickname</span>{userData.name}</p>) : null}
        {userData.ci ? (<p><span className="text-black font-medium block">Número de cédula</span> {numberFormat(userData.ci)}</p>) : null}
        {userData.phone ? (<p><span className="text-black font-medium block">Teléfono</span> {userData.phone}</p>) : null}
        {userData.email ? (<p><span className="text-black font-medium block">Email</span> {userData.email}</p>) : null}
        {/* {(userData.billName && userData.billRuc) ? (
          <div>
            <p className="font-semibold text-black">Facturación</p>
            <p><span className="pl-3 font-semibold">Razón social</span> {userData.billName}</p>
            <p><span className="pl-3 font-semibold">RUC</span> {userData.billRuc}</p>
          </div>
        ) : null} */}
        {selectedInstitution ? (
          <div>
            <div className="mb-1 font-semibold text-black">Institución</div>
            <div className={`flex flex-row items-stretch gap-1.5`}>
              {/* image xs */}
              <div className="w-[80px] h-[70px] rounded-md overflow-hidden shadow-md">
                <img src={selectedInstitution.data?.images?.length && getImageURL(selectedInstitution.data.images[0], 'xs')}
                  className="h-full w-full object-cover" 
                /> 
              </div>
              {/* data */}
              <div className="bg-white px-1.5 text-left leading-tight flex-1">
                <div className={`mb-0.5 text-sm font-semibold text-gray-900`}>{selectedInstitution.data?.name}</div>
                <div className="text-xs text-gray-600">{selectedInstitution.data?.location}</div>
                <div className="text-xs text-gray-600">{selectedInstitution.data?.city}</div>
              </div>
            </div>
          </div>
        ) : null}

        {underList ? underList : null}
      </div>
    </div>
  );
}
