import {
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList
} from "@ionic/react";

import Model, { useStateResults } from "../libs/ModelClass";
import { useModule } from "../context/ModuleContext";

export const MenuOfEntityCrud = ({ history }) => {
  const { isAllowed } = useModule();  
  const entities = useStateResults( Model.extend('entities') );

  return (<>
    <IonList>
      {/* <IonListHeader>
        <IonLabel className="">
          Listados
        </IonLabel>
      </IonListHeader> */}
  
      <IonItemGroup>
        {/* <IonItemDivider>
          <IonLabel>Listados</IonLabel>
        </IonItemDivider> */}
  
        {/* Entity Sections Links */}
        {entities?.filter((entityDoc) => (
          entityDoc.data.showInMenu
          && !entityDoc.data?.deleted
          && isAllowed(entityDoc?.data?.nameSlug, ['list'])
        ))
        .map((entityDoc) => (
          <IonItem button
            key={entityDoc.id} 
            routerLink={`/admin/entity/${entityDoc?.id}`}
          >
            {/* <IonIcon icon={folderOutline} size="small"></IonIcon> */}
            <IonLabel>
              {entityDoc.data.name}
            </IonLabel>
          </IonItem>
        ))}
      </IonItemGroup>
    </IonList>
  </>);
};
