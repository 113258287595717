import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import { BillingProductsCardWithTabs, useBillingDetails } from './BillingProductsCard';
import { useAsyncMemo } from 'use-async-memo';
import { GetOneModel } from '../../libs/ModelClass';
import dayjs from 'dayjs';
import { priceFormat } from '../../libs/utils';
import { useMemo, useState } from 'react';


const Content = ({
  institutionDoc,
  recentMaxDate 
}) => {
  const billingsDetails = useBillingDetails({ 
    institutionDoc,
    dateFrom: dayjs(recentMaxDate).add(1, 'day').startOf('day').toISOString(),
    dateTo: dayjs().endOf('day').toISOString()
  });

  return (<>
    {/* {parsedParams?.id ? (<CustomerBillingShow docId={parsedParams?.id} {...{ user, isAllowed }} />) : null} */}
    {billingsDetails?.productsOfRange ? (<>
      <div className="">
        <h2 className="mb-1 text-xs font-semibold">Total de la Institución</h2>
        <div className="text-sm text-lime-600">
          {billingsDetails?.productsOfRange?.totalTotal ? priceFormat(billingsDetails?.productsOfRange?.totalTotal) : null}
        </div>
      </div>
      <div className="">
        <h2 className="mb-1 text-xs font-semibold text-black">Periodo</h2>
        <div className="text-sm text-black">
          Desde: {dayjs(billingsDetails?.productsOfRange?.dateFrom).format('DD-MM-YYYY')}
          <br />
          Hasta: {dayjs(billingsDetails?.productsOfRange?.dateTo).format('DD-MM-YYYY')}
        </div>
      </div>
    </>) : null}
    <BillingProductsCardWithTabs {...billingsDetails} />
  </>);
};


export const RouteCustomerBillingGroupShowCurrent = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {   
  const [ institutionDoc, setInstitutionDoc ] = useState();
  const recentMaxDate = useAsyncMemo(async () => {
    const BillingsModel = Model.extend('customerBillings');
    const institutionDoc = await GetOneModel('institutions', user?.userDoc?.data?.institutionId);
    setInstitutionDoc(institutionDoc);
    let filteredDocs = await BillingsModel.filterByAttributes(
      { institutionId: user?.userDoc?.data?.institutionId },
      // { limit: 10, orderBy: { field: 'createdAt', direction: 'desc' }} // TODO fix
    );
    filteredDocs = filteredDocs.filter(doc => !doc.data?.deleted);
    if (filteredDocs.length) {
      // recent timeline
      filteredDocs.sort((a, b) => new Date(b.data.dateTo) - new Date(a.data.dateTo));
      return filteredDocs[0].data.dateTo;
    }
    return institutionDoc?.data?.createdAt;
  }, []);  

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin/customerBilling/groupList`}
      title="Consumición actual"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        url: '/admin/customerBilling/groupList',
        title: 'Pagos'
      }, {
        title: 'Consumición actual'
      }]}
    >
      <div className="ion-padding space-y-2">
        {institutionDoc && recentMaxDate ? (<Content {...{ institutionDoc, recentMaxDate }} />) : null}
      </div>
    </LayoutAdmin>
  );
};