import { Field } from 'react-final-form';
import _ from 'lodash';

import { ShowError } from './formUtils';

const FormField = (props) => {
  let {
    name,
    title,
    placeholder,
    fieldsRequired = null,
    type,
    showLabel = true,
    showClearBtn = true,
    validate = null,
    disabled = false,
    formatter = (value) => value,
    minValue = null,
    maxValue = null,
    minLength = null,
    maxLength = null,
    UnderInput = null,
    onChange = null,
    onBlur = null,
  } = props;

  const validateField = (value) => {
    let error;
  
    if (value !== undefined) {
      if (type === 'number') {
        const numericValue = parseFloat(value);
  
        if (minValue && numericValue < parseFloat(minValue)) {
          error = `El valor debe ser mayor o igual a ${minValue}`;
        } else if (maxValue && numericValue > parseFloat(maxValue)) {
          error = `El valor debe ser menor o igual a ${maxValue}`;
        }
      } else if (type === 'text') {
        if (minLength && value.length < parseInt(minLength)) {
          error = `El valor debe tener al menos ${minLength} caracteres`;
        } else if (maxLength && value.length > parseInt(maxLength)) {
          error = `El valor debe tener como máximo ${maxLength} caracteres`;
        }
      }
    }
  
    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }
  
    return error;
  };

  return (
    <div className={`pb-3 ${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validateField(args)}>
        {({ input, meta }) => (
          <>
            {showLabel ? (
              <label position="floating" className={props?.classes?.fieldLabel}>
                <span
                  className={`font-semibold mr-3 ${
                    meta.touched && meta.error ? 'text-red-600' : 'text-gray-900'
                  }`}
                >
                  {title}
                  {(meta.touched && meta.error) || (_.includes(fieldsRequired, name) && !input.value)
                    ? '*'
                    : ''}
                </span>

                {showClearBtn && input?.value ? (
                  <button
                    type="button"
                    className="px-1.5 py-0.5 font-regular text-xs bg-gray-100 text-gray-400 rounded-full hover:text-gray-800 inline-block"
                    onClick={() => input?.onChange(undefined)}
                  >
                    X
                  </button>
                ) : ''}
              </label>
            ) : ''}
            <input
              className={`border-gray-200 border h-[40px] px-2 py-1.5 rounded-md ${props?.classes?.fieldInput || ''}`}
              type={type}
              placeholder={placeholder || title}
              value={input.value}
              onChange={(e) => {
                const formatedValue = formatter(e.target.value);
                input.onChange(formatedValue); 
                onChange && onChange(formatedValue);
              }}
              onBlur={(e) => {input.onBlur(e); onBlur && onBlur(e)}}
              disabled={disabled}
              min={type === 'number' && minValue ? parseFloat(minValue) : undefined}
              max={type === 'number' && maxValue ? parseFloat(maxValue) : undefined}
              minLength={type === 'text' && minLength ? parseInt(minLength) : undefined}
              maxLength={type === 'text' && maxLength ? parseInt(maxLength) : undefined}
            />
            {UnderInput && (<UnderInput input={input} />)}
            {/* error */}
            <ShowError name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} />
          </>
        )}
      </Field>
    </div>
  );
};

export default FormField;