import { LayoutAdmin } from "../components/LayoutAdmin";
import { useModule } from "../context/ModuleContext";
import PartOfModule from "../components/Module/PartOfModule";
import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { 
  cog
} from 'ionicons/icons';

export function AdminTabConfig(props) {
  const { isAllowed } = useModule();  

  return (
    <LayoutAdmin 
      history={props.history}
      title={"Configuraciones"}
    >
      <div className="ion-padding">
        <PartOfModule
          type="main"
          action="AdminConfigBeforeLinksList"
          param={{ isAllowed }}
        />

        {isAllowed('entities', ['list']) ? (
          <IonItem routerLink="/admin/config/entity-creator-list" routerDirection="forward">
            <IonIcon icon={cog}></IonIcon>
            <IonLabel className="ml-2">Entidades</IonLabel>
          </IonItem>
        ) : null}

        <PartOfModule
          type="main"
          action="AdminConfigAfterLinksList"
          param={{ isAllowed }}
        />
      </div>
    </LayoutAdmin>
  );
}