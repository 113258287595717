import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import { CardItemContent, CardItemHeader } from "./CardItem";
import { useCart } from './CartContext';
import CurrentCartDetails from "./CurrentCartDetails";
import { HorizontalCardPicker } from "../../components/ui/HorizontalCardPicker";
import { IonContent, useIonViewWillEnter } from "@ionic/react";
import ModalAlert from "../../components/ui/ModalAlert";
import { useAuth } from "../user/AuthContext";
import Model from "../../libs/ModelClass";
import toast from 'react-hot-toast';
import BadgeLoading from "../../components/ui/BadgeLoading";


const InstitutionModel = Model.extend('institutions');

const CurrentCartDetailsMemoized = memo(CurrentCartDetails, (prevProps, newProps) => {
  const res = prevProps?.totalPriceItems === newProps?.totalPriceItems;
  return res;
});

export const RouteAddCart = ({ parsedParams, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history, match }) => {
  const { cartData } = useCart();
  let { user } = useAuth();
  const { 
    CartModel, ItemModel, CategoryModel, TypeModel, 
    items, itemsTypes, itemsCategories,
    planning, processPlanningData, fetchPlanningItemsOptionsList, fetchOldPlanningDocs, PlanningItemsOptionsModel, 
    selectedInstitution, setSelectedInstitution,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    totalPriceItems, getPrice,
    loading
  } = cartData;  
  const bagsReversed = config?.modules?.cart?.bagsReversed;
  const [openIndex, setOpenIndex] = useState(-1);
  const [firstOpen, setFirstOpen] = useState(false);
  const [ showOnFirstModal, setOnFirstModal ] = useState();
  const cartItemRef = useRef();
  const cardsQty = bags?.length;
  // TODO check from data
  // const previewCartsQty = useAsyncMemo(async () => {
  //   const res = await CartModel.countWhere();
  //   console.log('res', res);
  //   return res;
  // }, [])

  useEffect(() => {
    if (localStorage.getItem('firstOrder') === 'true') {
      setOnFirstModal(true);
    }
  }, []);
  
  useEffect(() => {
    cartItemRef?.current?.scrollToTop(100);
  }, [openIndex, cartItemRef?.current]);

  useIonViewWillEnter(() => {
    determineSpecialMenuByInstitution();
  }, []);

  useEffect(() => {
    // open at init
    if (!firstOpen && cardsQty && openIndex === -1) {
      setTimeout(() => {
        setOpenIndex(bagsReversed ? cardsQty-1 : 0);
      }, 200);
      setFirstOpen(true);
    }
  }, [firstOpen, bags?.length, user.userDoc.id]);

  const determineSpecialMenuByInstitution = async () => {
    if (!user.userDoc.data.institutionId) {
      toast.error('Sin institución, elija una desde editar perfil');
      throw new Error('Invalid Institution');
    }
    let institutionDoc = await InstitutionModel.findById(user.userDoc.data.institutionId);
    if (institutionDoc?.data.hasSpecialMenu) {
      setSelectedInstitution(institutionDoc);
      await processPlanningData(true, institutionDoc.id, 'cart');
    }
    else if (institutionDoc?.data.maxItemsPerBag) {
      setSelectedInstitution(institutionDoc);
      await processPlanningData(true, null, 'cart');
    }
    else {
      setSelectedInstitution(false);
      await processPlanningData(true, null, 'cart');
    }
  };
  
  let prevBag = useCallback(() => {
    if (openIndex > 0) {
      setOpenIndex(openIndex - 1);
    }
  });
  
  let nextBag = useCallback(() => {
    if (openIndex < cardsQty - 1) {
      setOpenIndex(openIndex + 1);
    }
  });

  if (bagsReversed) {
    let swap = prevBag;
    prevBag = nextBag;
    nextBag = swap;
  }

  const goToBag = (bagId, params) => {
    setOpenIndex(bagId);
  };

  const BagCard = (item, index) => (
    <div className="p-2 !w-[110px]" key={index}>
      <div 
      onClick={() => setOpenIndex(index)}
      className={`p-2.5 border border-gray-200 rounded-md cursor-pointer
        ${index === openIndex ? 'bg-brand-red text-white' : 'bg-white text-brand-dark'} transition-colors duration-700
      `}>
        <CardItemHeader {...{...cartData, index, openIndex}} />
      </div>
    </div>
  );

  const closeOnFirstModal = () => {
    localStorage.removeItem('firstOrder');
    setOnFirstModal(false);
  };

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin`}
      // breadcrumbs={[{
      //   url: '/admin',
      //   title: 'Panel'
      // }, {
      //   title: 'Armar menú'
      // }]}
      showRefresher={false}
      contentScrollY={false}
    >
      {(cartData.loading) ? (
        <div className="pt-10 flex place-content-center content-center items-center">
          <BadgeLoading size="md" />
        </div>
      ) : (<>
        {/* Selector de días */}
        <HorizontalCardPicker
          items={bags?.map(BagCard)}
          itemWidth={110}
          itemHeight={88}
          openIndex={openIndex}
          prevBag={prevBag}
          nextBag={nextBag}
          className="border-b border-gray-400"
        />

        <IonContent ref={cartItemRef}>
          <CardItemContent {...{...cartData, index: openIndex}} />
          {/* <Route path={'/admin/cart/add'} render={(props) => {
            return (
              <IonPage {...props}>
                <IonContent ref={cartItemRef}>
                </IonContent>
              </IonPage>
            )
          }} /> */}
        </IonContent>
      </>)}
      
      {/* Grilla de platos agrupado por tipo de item  */}
      <CurrentCartDetailsMemoized goToBag={goToBag} history={history} totalPriceItems={totalPriceItems} />

      {/* Congrats modal */}
      {showOnFirstModal ? (
        <ModalAlert
          onConfirm={closeOnFirstModal}
          title="Tu primer menú!"
          subtitle="Elegí tus platos para los siguientes días y luego confirmá tu pedido."
          confirmLabel="Listo"
        />
      ) : null}
    </LayoutAdmin>
  );
};