import _ from 'lodash';
import { typeConfigs } from "../DataTypes";
import dayjs from 'dayjs';

const valueFormatter = (value, taxonomyType) => {
  const typeConfig = typeConfigs[taxonomyType?.type];
  
  if (!typeConfig || !typeConfig.valueFormatter) {
    return null;
  }

  return typeConfig.valueFormatter(value);
};

const dataToQueryFormatter = (filterData, filterMenuTaxonomyTypes) => {
  const filterQuery = {};
  filterMenuTaxonomyTypes?.forEach(filterPopulated => {
    let taxonomyType = filterPopulated?.taxonomyType;
    let filter = _.assign({}, taxonomyType?.filter, filterPopulated?.filter);
    let isSelected = filterPopulated?.id && filterData.hasOwnProperty(taxonomyType?.nameSlug);
    let filterValue = isSelected ? filterData[taxonomyType?.nameSlug] : null;
    
    let newValue = {};
    if (isSelected) {
      if (filter?.mode === "simpleInput" || !filter?.mode) {
        if (taxonomyType?.type === "date") {
          newValue = {
            gte: dayjs(filterValue).utc().startOf('day').toISOString(),
            lte: dayjs(filterValue).utc().endOf('day').toISOString(),
          };
        } else {
          newValue = filterValue;
        }
      } 
      
      else if (filter?.mode === "singleOperator") {
        newValue[filterValue.operator] = filterValue.value;
      } 
      
      else if (filter?.mode === "singleRange") {
        if (filterValue.startValue) { newValue.gte = valueFormatter(filterValue.startValue, taxonomyType); }
        if (filterValue.endValue) { newValue.lte = valueFormatter(filterValue.endValue, taxonomyType); }
      } 
      
      else if (filter?.mode === "rangeSelect") {
        let range = filter.ranges?.find((range, index) => index === parseInt(filterValue.rangeIndex));
        if (range?.startValue && range?.startOperator) { newValue[range?.startOperator] = valueFormatter(range?.startValue, taxonomyType); }
        if (range?.endValue && range?.endOperator) { newValue[range?.endOperator] = valueFormatter(range?.endValue, taxonomyType); }
      }
      
      filterQuery[taxonomyType?.nameSlug] = _.size(newValue) ? newValue : filterValue;
    }
  });
  return filterQuery;
}

export default dataToQueryFormatter;