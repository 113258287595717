import _ from 'lodash';
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { LayoutAdmin } from "../../components/LayoutAdmin";
import { useAuth } from '../../modules/user/AuthContext';
import { FaCheck, FaPhoneAlt, FaRegBuilding, FaUser } from "react-icons/fa";
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { InstitutionSelector } from '../institution/InstitutionSelector';
// import { useHistory } from 'react-router-dom';
import { UserDataCard } from './UserDataCard';
import Model from '../../libs/ModelClass';
// import { SetAndVerifyPhoneNumber } from './LoginWithPhone';
import config from '../../config';
import PhoneInput from 'react-phone-input-2';
// import { useLocation } from 'react-router-dom';
import ContactBTN from '../../components/ContactBTN';
import { IonButton } from '@ionic/react';


export function SetCustomerData({ 
  mode = 'update', // 'update'
  onFinish = () => {},
  history
}) {
  const { user } = useAuth();
  const [ userData, setUserData ] = useState(user.userDoc.data);
  const [ selectedInstitution, setSelectedInstitution ] = useState();
  // const [ phone, setPhone ] = useState(userData.phone);
  // const location = useLocation();
  
  // useEffect(() => {
  // // TODO go to step 1
  //   setUserData(user.userDoc.data);
  // }, [location]);

  useEffect(() => {
    fetchSelectedInstitution();
  }, [user]);
  
  const fetchSelectedInstitution = async () => {
    if (selectedInstitution) { return; }
    if (user?.userDoc?.data?.institutionId) {
      const InstitutionModel = Model.extend('institutions');
      const doc = await InstitutionModel.findById(user?.userDoc?.data?.institutionId);
      if (doc.data.deleted) {
        throw new Error('Institución eliminada');
      }
      setSelectedInstitution(doc);
    }
  };

  const handleComplete = async () => {
    // save data
    // redirect to "armar menú"
    user.userDoc.data.name = userData.name;
    user.userDoc.data.firstName = userData.firstName;
    user.userDoc.data.lastName = userData.lastName;
    user.userDoc.data.phone = userData.phone;
    user.userDoc.data.ci = userData.ci;
    // if (userData.billName && userData.billRuc) {
    //   user.userDoc.data.billName = userData.billName;
    //   user.userDoc.data.billRuc = userData.billRuc;
    // }

    if (user.userDoc.data.institutionId !== selectedInstitution.id) {
      user.userDoc.data.institutionApprovedDate = null;
      user.userDoc.data.institutionApprovedBy = null;
      user.userDoc.data.institutionBloquedDate = null;
      user.userDoc.data.institutionBloquedBy = null;
      user.userDoc.data.institutionId = selectedInstitution.id;
    }

    await user.userDoc.save();
    if (mode === 'register') {
      localStorage.setItem('firstOrder', true);
      history.push('/admin');
    }
    else if (mode === 'update') {
      toast.success('Datos actualizados');
      history.go(-1);
    }
    onFinish();
  };

  const tabChanged = ({ prevIndex, nextIndex }) => {};

  // const onVerifyNumberInUse = async (phone) => {
  //   const UsersProfilesModel = Model.extend(config.modules.user.usersEntitySlug);
  //   const doc = await UsersProfilesModel.filterByAttributes({ phone });
  //   if (doc.id) {
  //     return false;
  //   }
  //   return true;
  // };

  // const onFinishPhoneVerification = async (phone) => {
  //   setUserData({ ...userData, phone });
  //   setPhone(phone);
  // };
  
  // const checkValidateNumber = () => {
  //   if (phone === userData?.phone) {
  //     return true;
  //   }
  //   return false;
  // };
  // const errorNumber = () => {
  //   toast.error('Debe verificar su número');
  // };

  const checkValidateUserData = () => {
    if (
      userData?.name?.length > 2
      && userData?.firstName?.length > 2
      && userData?.lastName?.length > 2
      && userData?.ci > 90000
      && userData?.phone?.length >= 12
    ) {
      return true;
    }
    return false;
  };
  const errorUserData = () => {
    toast.error('Debe completar los datos');
  };

  const checkValidateInstitution = () => {
    if (selectedInstitution?.id) {
      return true;
    }
    return false;
  };
  const errorInstitution = () => {
    toast.error('Debe seleccionar su institución');
  };

  return (
    <div className="">
      <FormWizard
        stepSize="xs"
        shape="circle"
        color="#E6292F"
        title={(
          <h1 className='font-semibold'>Datos del usuario</h1>
        )}
        onComplete={handleComplete}
        onTabChange={tabChanged}
        backButtonTemplate={(handleNext) => (
          <IonButton color="secondary" size="small" onClick={handleNext}>
            Anterior
          </IonButton>
        )}
        nextButtonTemplate={(handleNext) => (
          <IonButton color="secondary" size="small" onClick={handleNext} className="float-right">
            Siguiente
          </IonButton>
        )}
        finishButtonTemplate={(handleComplete) => (
          <IonButton color="primary" size="small" onClick={handleComplete} className="float-right">
            {mode === 'register' ? 'Solicitar Ingreso' : 'Actualizar'}
          </IonButton>
        )}
      >
        {/* {mode === 'update' ? (
          <FormWizard.TabContent title="Teléfono" icon={(<FaPhoneAlt />)}>
            <div className="flex flex-col items-center  mb-20">
              <SetAndVerifyPhoneNumber
                phoneNumber={userData.phone}
                onFinish={onFinishPhoneVerification}
                onVerifyNumberInUse={onVerifyNumberInUse}
                classes={{ inputContainer: 'p-5 w-[300px] text-center' }}
              />
            </div>
          </FormWizard.TabContent>
        ) : null} */}
        

        <FormWizard.TabContent
          title="Usuario"
          icon={(<FaUser />)}
          // isValid={checkValidateNumber()}
          // validationError={mode === 'update' && errorNumber}
        >
          <div className="mb-10 mx-auto max-w-xs">
            <div>
              <label className='font-semibold text-xs'>
                Nombre corto {userData.name?.length < 3 ? (<span className="text-brand-red">*</span>) : null}
              </label>
              <input
                type="text"
                value={userData.name}
                minLength={3}
                onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                className="shadow appearance-none text-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
              />
              {userData.name?.length < 3 ? (<div className="h-4 text-red-600 text-xs">Mínimo 3 letras</div>) : <div className="h-4"></div>}
            </div>

            <div className="mt-4">
              <label className='font-semibold text-xs'>
                Nombres {userData.firstName?.length < 3 ? (<span className="text-brand-red">*</span>) : null}
              </label>
              <input
                type="text"
                value={userData.firstName}
                minLength={3}
                onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                className="shadow appearance-none text-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
              />
              {userData.firstName?.length < 3 ? (<div className="h-4 text-red-600 text-xs">Mínimo 3 letras</div>) : <div className="h-4"></div>}
            </div>

            <div className="mt-4">
              <label className='font-semibold text-xs'>
                Apellidos {userData.lastName?.length < 3 ? (<span className="text-brand-red">*</span>) : null}
              </label>
              <input
                type="text"
                value={userData.lastName}
                minLength={3}
                onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                className="shadow appearance-none text-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
              />
              {userData.lastName?.length < 3 ? (<div className="h-4 text-red-600 text-xs">Mínimo 3 letras</div>) : <div className="h-4"></div>}
            </div>

            <div className="mt-4">
              <label className='font-semibold text-xs'>
                Cédula de identidad {userData.ci < 90000 ? (<span className="text-brand-red">*</span>) : null}
              </label>
              <input
                type="number"
                value={userData.ci}
                onChange={(e) => setUserData({ ...userData, ci: e.target.value })}
                minLength={90000} // 90.000
                className="shadow appearance-none text-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
              />
              {userData.ci < 90000 ? (<div className="h-4 text-red-600 text-xs">Mínimo 9 dígitos</div>) : <div className="h-4"></div>}
            </div>

            <div className="mt-4">
              <label className='font-semibold text-xs'>
                Nro de celular {userData.phone?.toString().length < 12 ? (<span className="text-brand-red">*</span>) : null}
              </label>
              <PhoneInput
                country={config.phoneCountry}
                value={userData.phone}
                onChange={(phone) => setUserData({ ...userData, phone })}
                className="w-full"
              />
              {userData.phone?.toString().length < 12 ? (<div className="-mb-2 text-red-600 text-xs">Mínimo 12 números</div>) : null}
            </div>
            {/* <div className='mt-10 pt-4 border-t border-gray-200'>
              <h3 className='mb-4 font-semibold'>
                Datos de facturación
              </h3>
              <label className=''>Razón social</label>
              <input
                type="text"
                value={userData.billName}
                onChange={(e) => setUserData({ ...userData, billName: e.target.value })}
                className="shadow appearance-none text-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
              />
              <label className='block mt-4'>RUC</label>
              <input
                type="text"
                value={userData.billRuc}
                onChange={(e) => setUserData({ ...userData, billRuc: e.target.value })}
                className="shadow appearance-none text-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
              />
            </div>
            <div className="mt-10 pt-4 border-t border-gray-200 text-gray-500 text-sm">
              Los datos pueden ser cambiados en otro momento
            </div> */}
            <div className="mt-6">
              <ContactBTN label="Escribínos" message="Hola WorkFood!" />
            </div>
          </div>
        </FormWizard.TabContent>
        
        
        <FormWizard.TabContent 
          title="Institución" 
          icon={(<FaRegBuilding />)} 
          isValid={checkValidateUserData()} 
          validationError={errorUserData}
        >
          <div className="mb-4">
            <InstitutionSelector {...{ selectedInstitution, setSelectedInstitution }} />
            <div className="mt-4">
              <h4 className="mb-2 text-md font-semibold text-black">Falta tu Institución?</h4>
              <ContactBTN label="Solicitá tu Institución" message="Hola WorkFood! deseo solicitar el servicio para mi Institución" />
            </div>
          </div>
        </FormWizard.TabContent>
        

        <FormWizard.TabContent title="Listo" icon={(<FaCheck />)} isValid={checkValidateInstitution()} validationError={errorInstitution}>
          <h3 className="font-semibold mb-4 text-sm">Comprueba tus datos</h3>
          <UserDataCard {...{userData, selectedInstitution}} />
          {mode === 'register' ? (
            <div className="my-8 px-4">
              <h4 className="text-lg font-semibold text-brand-red">Todo en orden?</h4>
              <p className="text-sm">
                Solicitá el ingreso a tu Institución 
                <br />una vez aprobado 
                <br />podrás armar tu primer ménu.
              </p>
            </div>
          ) : (
            <div className="mb-12"></div>
          )}
        </FormWizard.TabContent>
      </FormWizard>
    </div>
  );
}


export function AdminUserSetCustomerData(props) {
  const { user } = useAuth();

  return (
    <LayoutAdmin
      history={props.history}
      defaultHref={`/admin/user/data`}
      title="Registrar datos personales"
      {...props}
    >
      <div className="ion-padding">
        {user?.userDoc?.id ? <SetCustomerData mode={props.mode} history={props.history} /> : null}
      </div>
    </LayoutAdmin>
  );
}
