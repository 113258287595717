import { useEffect,useState } from "react";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import config from "../../config";

import dayjs from 'dayjs';
import Model from "../../libs/ModelClass";
import { useEntityFullBySlug } from "../entity/Entity";
import EntityFilterMenuDisplay from "../../components/EntityFilterMenuDisplay";
import dataToQueryFormatter from "../../components/Filters/dataToQueryFormatter";
import BtnLoading from "../../components/ui/BtnLoading";
import EntityGetOneDocument from "../../components/EntityGetOneDocument";
import BadgeLoading from "../../components/ui/BadgeLoading";

const filterMenuSlug = "crud";

const DeliveryPacksModel = Model.extend('deliveryPacks');


const ListItemsFiltered = ({ filtersSelected, user, isAllowed, onShow }) => {
  const { filterMenuTaxonomyTypes } = useEntityFullBySlug({ entitySlug: config?.modules?.cart?.cartsEntitySlug, filterMenuSlug });
  const [deliveryPacksDocs, setDeliveryPacks] = useState({});
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    filterMenuTaxonomyTypes && fetchFilteredDocs();
  }, [filterMenuTaxonomyTypes, filtersSelected]);

  const fetchFilteredDocs = async () => {
    setLoading(true);
    const filterQuery = dataToQueryFormatter(filtersSelected, filterMenuTaxonomyTypes);
    let filteredDocs = await DeliveryPacksModel.filterByAttributes(filterQuery);
    filteredDocs = filteredDocs.filter(doc => !doc.data?.deleted);
    filteredDocs = filteredDocs.sort((a, b) => new Date(b.data.createdAt) - new Date(a.data.createdAt));

    setDeliveryPacks({
      pending: filteredDocs.filter(doc => !doc.data.delivered),
      delivered: filteredDocs.filter(doc => doc.data.delivered),
    });
    setLoading(false);
  };

  const ListItems = ({ items }) => {
    return (
      <div className="px-2 border border-gray-200 rounded-md">
        {items?.map((item, index) => (
          <div className={`
            flex flex-row place-content-between
            py-2 border-b border-gray-200 last:border-none 
          `} key={item.id}>
            <div className="">
              {/* <span className="text-xl font-semibold tracking-tighter">{item?.data?.delivered ? 'entregado' : 'pendiente'}</span> */}
              <span className="text-xs font-semibold text-gray-900">{dayjs(item.data?.createdAt).utc().format('LL')}</span>
              <span className="ml-2 text-xs font-mono text-gray-500">{item.id}</span>
              {/* User */}
              <EntityGetOneDocument
                entitySlug={'usersProfiles'}
                docId={item.data?.userId}>
                {({ doc, entityDoc }) => (
                  isAllowed('usersProfiles', ['list']) ? (
                    <Link to={`/admin/entity/${entityDoc?.id}/${doc?.id}`} className="block text-sm text-gray-900 underline">{doc.data?.name}</Link>
                  ) : (
                    <div className="text-sm text-gray-900">{doc.data?.name}</div>
                  )
                )}
              </EntityGetOneDocument>
            </div>
            <div>
              <BtnLoading label="Ver" onClick={() => onShow(item)} withLoading className="px-2 py-0.5 text-gray-800 hover:scale-105 bg-gray-200 shadow-none text-xs" />
            </div>
          </div>
        ))}
      </div>
    )
  };

  return (<>
    {(loading || !_.size(deliveryPacksDocs)) ? (
      <div className="pt-10 flex place-content-center content-center items-center">
        <BadgeLoading size="md" />
      </div>
    ) : (<>
      {(deliveryPacksDocs.hasOwnProperty('pending') && deliveryPacksDocs.hasOwnProperty('delivered')) ? (<>
        {deliveryPacksDocs?.pending?.length && (<>
          <h2 className="mb-2 mt-4 text-lg font-semibold text-brand-red">Pendientes</h2>
          <ListItems items={deliveryPacksDocs?.pending} />
        </>) || null}
        {deliveryPacksDocs?.delivered?.length && (<>
          <h2 className="mb-2 mt-4 text-lg font-semibold text-gray-900">Entregados</h2>
          <ListItems items={deliveryPacksDocs?.delivered} />
        </>) || null}
      </>) : (
        <div className="text-center">
          No hay pedidos para el día
        </div>
      )}
    </>)}
  </>);
};


export const RouteDeliveryHistory = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {  
  const [filtersSelected, setFiltersSelected] = useState({});
  
  const handleAdd = () => {
    history.push('/admin/delivery/planning');
  };

  const onShow = (doc) => {
    history.push('/admin/delivery/showOrder/id/' + doc?.id);
  };

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin`}
      title="Itinerarios diarios"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        title: 'Planificación de Envíos'
      }]}
    >
      <div className="ion-padding">
        <BtnLoading label="+ Agregar" onClick={handleAdd} className="px-2 py-1 !text-black hover:scale-105 bg-brand-yellow/80" />

        <div className="mt-4 flex flex-col lg:flex-row">
          <div className="lg:w-2/5">
            <EntityFilterMenuDisplay
              entitySlug={config.modules.cart.cartsEntitySlug}
              filterMenuSlug={filterMenuSlug}
              filterData={filtersSelected}
              onFormChange={(formValues) => {
                setFiltersSelected(formValues);
              }}
              onFormClear={() => {
                setFiltersSelected(null);
              }}
              style={'search-collapsible-panel'}
              paramsByFieldSlug={{
                'createdAt': {
                  title: 'Fecha de entrega'
                },
                'userId': {
                  optionsMapper: (docs) => docs.map((option) => {
                    let label = option?.data?.name;
                    if (option?.data?.ci) {
                      label = label + `, CI: ${option?.data?.ci}`;
                    }
                    if (option?.data?.phone) {
                      label = label + `, ${option?.data?.phone}`;
                    }
                    if (option?.data?.email) {
                      label = label + `, ${option?.data?.email}`;
                    }
                    return {
                      value: option?.id,
                      label
                    };
                  })
                },
                'institutionId': {
                  optionsMapper: (docs) => docs.map((option) => ({
                    value: option?.id,
                    label: `${option?.data?.name} | ${option?.data?.city || ''}, ${option?.data?.location || ''}`
                  }))
                }
              }}
            />
          </div>

          <div className="mt-4 lg:mt-0 lg:ml-4 lg:w-3/5">
            <ListItemsFiltered {...{ filtersSelected, user, isAllowed, onShow }} />
          </div>  
        </div>
      </div>
    </LayoutAdmin>
  );
};