import _ from 'lodash';
import Model from '../../libs/ModelClass';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const SelectedValuesList = (props) => {
  let {
    form,
    values,
    filterMenuTaxonomyTypes,
    className
  } = props;

  const [ valuesToShow, setValToShow ] = useState([]);

  useEffect(() => {
    calcValues();
  }, [values]);

  const calcValues = async () => {
    const valuesToAdd = [];
    for (const filterPopulated of filterMenuTaxonomyTypes) {
      let taxonomyType = filterPopulated?.taxonomyType;
      let filter = _.assign({}, taxonomyType?.filter, filterPopulated?.filter);
      let isSelected = filterPopulated?.id && values.hasOwnProperty(taxonomyType?.nameSlug);
      let filterValue = isSelected ? values[taxonomyType?.nameSlug] : null;
  
      let shouldRenderValue = isSelected && filterValue !== null && filterValue !== undefined && (
        Array.isArray(filterValue) ? filterValue.length > 0 : true
      );
  
      const getValue = async () => {
        if (taxonomyType?.type === 'gps') {
          return filterValue?.formatted_address + ' - ' + filterValue?.distance + ' km';
        }
    
        if (taxonomyType?.type === 'select') {
          if (taxonomyType?.param?.options) {
            const option = taxonomyType?.param?.options?.find((opt) => opt.value === filterValue);
            if (option) {
              return option.label;
            }
          }
        }

        if (taxonomyType?.type === 'multiselect') {
          // TODO show all values
          if (taxonomyType?.param?.options) {
            const option = taxonomyType?.param?.options?.find((opt) => opt.value === filterValue);
            if (option) {
              return option.label;
            }
          }
        }
    
        if (taxonomyType?.type === 'selectOneEntityDocument' && filterValue) {
          if (taxonomyType?.param?.entityNameSlug) {
            const ExtendedModel = Model.extend(taxonomyType?.param?.entityNameSlug);
            const doc = await ExtendedModel.findById(filterValue);
            return doc?.data?.name;
          }
        }
        
        if (Array.isArray(filterValue)) {
          let optionLabels = [];
          filterValue.forEach((value) => {
            if (taxonomyType?.param?.options) {
              const option = taxonomyType?.param?.options?.find((opt) => opt.value === value);
              if (option) {
                optionLabels.push(option.label);
              }
            }
          });
          return optionLabels.join(', ');
        }

        if (filter?.mode === 'simpleInput' && filterValue !== null) {
          let res;
          if (taxonomyType?.type === 'date') {
            res = dayjs(filterValue).utc().format('ll');
          }
          if (taxonomyType?.type === 'number') {
            res = Number(filterValue);
          }
          return res;
        }
    
        if (filter?.mode === 'singleRange' && (filterValue?.startValue || filterValue?.endValue)) {
          let res;
          if (taxonomyType?.type === 'date') {
            res = filterValue?.startValue ? 'Desde: ' + dayjs(filterValue?.startValue).utc().format('ll') : '';
            if (filterValue?.endValue) {
              res += filterValue?.startValue ? ', ' : ' ';
              res += ' Hasta: ' + dayjs(filterValue?.endValue).utc().format('ll');
            }
          }
          if (taxonomyType?.type === 'number') {
            res = filterValue?.startValue ? 'Desde: ' + Number(filterValue?.startValue) : '';
            if (filterValue?.endValue) {
              res += filterValue?.startValue ? ', ' : ' ';
              res += ' Hasta: ' + Number(filterValue?.endValue);
            }
          }
          return res;
        } 
        
        if (filter?.mode === 'rangeSelect' && filterValue?.rangeIndex >= 0) {
          const option = filter?.ranges[ parseInt(filterValue?.rangeIndex) ];
          return option?.rangeName;
        }
        return filterValue;
      };
  
      const value = await getValue();
      if (shouldRenderValue && value) {
        valuesToAdd.push({
          taxonomyType,
          value
        });
      }
    }
    setValToShow(valuesToAdd);
  };


  return ( valuesToShow?.map(({ taxonomyType, value }) => (
    <div key={taxonomyType.id} className={`block px-2.5 py-0.5 text-sm rounded-full bg-gray-200 ${className}`}>
      <span className="">{taxonomyType.name}: </span>
      <span className="pl-1 font-semibold">{value}</span>
      {/* Agregar botón 'x' que elimine el filtro seleccionado utilizando alguna función de form 'react-final-form' */}
      <button
        type="button"
        className="ml-2 text-gray-500 hover:text-red-500"
        onClick={() => form.change(taxonomyType.nameSlug, undefined)}
      >
        X
      </button>
    </div>
  )) || null);
}

export default SelectedValuesList;