import { useEffect, useMemo, useState } from "react";
import _ from 'lodash';
import toast from "react-hot-toast";
import Model from "../../libs/ModelClass";
import BadgeLoading from "../../components/ui/BadgeLoading";
import MapRoute from "../../components/ui/MapRoute";
import { InfoWindowRenderForDriving, PacksPlacesListForDriving } from "./PacksPlaces";
import Modal from "../../components/ui/Modal";
import ModalAlert from "../../components/ui/ModalAlert";
import BtnLoading from "../../components/ui/BtnLoading";
import dayjs from "dayjs";

const InstitutionsModel = Model.extend('institutions');
const DeliveryPacksModel = Model.extend('deliveryPacks');

export const calcTotalDistanceAndDuration = (packs) => {
  if (!Array.isArray(packs) || packs.length === 0) {
    return null; // Devuelve null si no se proporciona un array válido
  }
  // Inicializa variables para acumular la distancia y la duración
  let totalDistanceValue = 0;
  let totalDurationValue = 0;
  // Recorre los "packs" y acumula la distancia y la duración
  packs.forEach((pack) => {
    if (pack.distance && pack.distance.value) {
      totalDistanceValue += pack.distance.value;
    }
    if (pack.duration && pack.duration.value) {
      totalDurationValue += pack.duration.value;
    }
  });
  // Convierte la distancia de metros a kilómetros
  const totalDistanceKilometers = totalDistanceValue / 1000;  
  // Convierte la duración de segundos a minutos
  const totalDurationMinutes = totalDurationValue / 60;
  // Crea objetos "distance" y "duration" con los valores acumulados en km y min
  const totalDistance = {
    text: `${totalDistanceKilometers.toFixed(2)} km`,
    value: totalDistanceKilometers,
  };
  const totalDuration = {
    text: `${totalDurationMinutes.toFixed(2)} min`,
    value: totalDurationMinutes,
  };
  return { distance: totalDistance, duration: totalDuration };
};

const MapRouteWithPlaces = ({ deliveryPackDoc }) => {
  const [packsPending, setPacksPending] = useState([]);
  const [packsDelivered, setPacksDelivered] = useState([]);
  const [institutionsDocs, setInstitutionsDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalObservationIndex, setOpenModalObservationIndex] = useState();
  const [openModalMarkAsDelivered, setOpenModalMarkAsDelivered] = useState(false);
  const [observations, setObservations] = useState();
  const totalDistanceAndDuration = useMemo(() => {
    return calcTotalDistanceAndDuration(packsPending);
  }, [packsPending]);

  useEffect(() => {
    fetchAndSpreadPacks();
  }, [deliveryPackDoc]);

  const fetchAndSpreadPacks = async () => {
    setLoading(true);
    // fetch related documents
    const institutions = await InstitutionsModel.filterByAttributes({ id: { in: deliveryPackDoc.data.packs.map(pack => pack.institutionId)} });
    // populate with Insitution docs
    const packsPending = deliveryPackDoc.data.packs.map(pack => {
      return { 
        ...pack,
        institutionDoc: institutions.find(institution => institution.id === pack.institutionId)
      };
    });
    const packsDelivered = packsPending.filter(pack => pack.delivered === true);
    setPacksPending(packsPending);
    setPacksDelivered(packsDelivered);
    setInstitutionsDocs(institutions);
    setLoading(false);
  };

  const getPointPosition = (place) => {
    console.log('getPointPosition', place)
    return place?.institutionDoc?.data?.gps;
  };

  const controls = {
    openMaps: (packIndex = 0) => {
      console.log('deliveryPackDoc?.data', deliveryPackDoc?.data?.packs[packIndex])
      const institutionDoc = institutionsDocs.find(institution => institution.id ===  deliveryPackDoc?.data?.packs[packIndex].institutionId);
      const { lat, lng } = getPointPosition({ institutionDoc });
      const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
      window.open(url, '_blank');
    },
    setObservations: (packIndex) => {
      setObservations(deliveryPackDoc.data.packs[packIndex].observations || '');
      setOpenModalObservationIndex({ packIndex });
    },
    setAsDelivered: (packIndex) => {
      setOpenModalMarkAsDelivered({ packIndex });
    }
  };

  const saveObservation = async () => {
    try {
      const packIndex = openModalObservationIndex?.packIndex;
      deliveryPackDoc.data.packs[packIndex].observations = observations;
      await deliveryPackDoc.save();
      setOpenModalObservationIndex(false);
      toast.success('Observación guardada');
    } catch (error) {
      console.error(error);
    }
  };

  const saveMarkAsDelivered = async () => {
    try {
      const packIndex = openModalMarkAsDelivered?.packIndex;
      deliveryPackDoc.data.packs[packIndex].delivered = true;
      deliveryPackDoc.data.packs[packIndex].deliveredDate = dayjs().utc().toISOString();
      await deliveryPackDoc.save();
      setOpenModalMarkAsDelivered(false);
      toast.success('Entrega confirmada');
      // move to delivered list
      const packToUpdate = packsPending[packIndex];
      packToUpdate.delivered = true;
      packToUpdate.deliveredDate = deliveryPackDoc.data.packs[packIndex].deliveredDate;
      packsDelivered.unshift(packToUpdate);
      setPacksPending([ ...packsPending ]);
      setPacksDelivered([ ...packsDelivered ]);
      // si está todo confirmado entonces marcar la órden de entrega completa como confirmada
    } catch (error) {
      console.error(error);
    }
  };

  const isShowInList = (packIndex) => {
    return !deliveryPackDoc.data.packs[packIndex].delivered;
  };

  return (<>
      <div className="mt-4">
        <MapRoute
          placesSelected={packsPending}
          classes={{
            mapRender: '',
            mapHeight: 'h-96'
          }}
          InfoWindowRender={InfoWindowRenderForDriving()}
          getPointPosition={getPointPosition}
        />
      </div>
      {loading ? (
        <div className="mt-4 mx-auto w-24">
          <BadgeLoading size="md" />
        </div>
      ) : (<>
        {packsPending?.length && (packsPending.length - packsDelivered.length) && (<>
          <div className="flex flex-col place-content-start xs:flex-row xs:place-content-between items-center">
            <h2 className="mt-4 mb-0 xs:mb-2 text-lg font-semibold text-brand-red text-left w-full xs:w-auto">Pendientes</h2>
            <div className="flex flex-row gap-4 items-center place-content-between w-full xs:w-auto mb-2 xs:mb-0">
              {totalDistanceAndDuration?.distance ? (
                <div className="pt-2 text-xs font-semibold font-mono text-gray-500">
                  {totalDistanceAndDuration.distance?.text} / {totalDistanceAndDuration.duration?.text}
                </div>
              ) : null}
            </div>
          </div>
          <PacksPlacesListForDriving items={packsPending} isShow={isShowInList} controls={controls} />
        </>) || null}
        {packsDelivered?.length && (<>
          <h2 className="mt-4 mb-2 text-lg font-semibold text-black">Entregados</h2>
          <PacksPlacesListForDriving items={packsDelivered} />
        </>) || null}
      </>)}

      {openModalObservationIndex ? (
        <Modal
          open={openModalObservationIndex}
          setOpen={setOpenModalObservationIndex}
          classes={{ cardContainer: 'w-[80vw] max-w-md' }}
          title="Agregar observación"
        >
          <textarea value={observations} onChange={(e) => setObservations(e.target.value)} name="observation" cols="30" rows="10" autoFocus={true} className="p-2 w-full"></textarea>
          <BtnLoading label="Guardar" onClickAsync={saveObservation} className="mt-2 mx-auto px-3 p-1 bg-amber-600 text-white text-sm hover:scale-105 shadow-lg" />
        </Modal>
      ) : null}
      {openModalMarkAsDelivered && (
        <ModalAlert
          text="Confirma la entrega y se enviarán las notificaciones"
          onConfirm={saveMarkAsDelivered}
          onCancel={() => setOpenModalMarkAsDelivered(false)}
        />
      )}
  </>);
};

export default MapRouteWithPlaces;