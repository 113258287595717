const isPromoEnabled = false;

export default function CtaBenefitsBlock({ data }) {
    return (
      <div id="beneficios" className="text-gray-900 text-left">

        {/* Beneficio 3 / Almuerzo en equipo */}
        <div className="md:grid md:grid-cols-2 bg-brand-gray text-white py-16">
          {/* lg:left  / sm:Top */}
          <div className="h-[35vh] sm:h-[55vh] md:h-[80vh] lg:h-[90vh] xl:h-[91vh] mx-7 md:ml-12 md:mr-4 xl:ml-20 rounded-xl bg-cover bg-bottom" 
            style={{ backgroundImage: `url(${data.fourthBenefit.backgroundImageUrl})` }}>
          </div>

          {/* lg:Right / sm:Bottom*/}
          <div className="text-start content-center pl-6 pr-5 xs:px-7 pt-4 pb-4 sm:py-12 sm:px-12 md:py-6 xl:pt-20 xl:pr-6 xl:pl-12 2xl:pt-32">
            <div className="hidden lg:flex justify-end pr-24">
              <img src="/logo-icono-amarillo.svg" alt="work-food" className="h-16 xl:h-20"/>
            </div>
            <div className="pt-4">
              <div className="text-left font-brand-montserrat lg:text-base text-sm lg:text-start">
                <h3 className="pb-4 text-4xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] leading-tight font-bold lg:pr-24">{data.fourthBenefit.title}</h3>
                <p className="pb-6 text-xl md:text-[22px] 2xl:text-2xl leading-normal md:leading-relaxed">{data.fourthBenefit.content}</p>
                <a href={data.fourthBenefit.ctaUrl} target="_blank" className="text-xl px-5 py-2.5 bg-brand-red" rel="noreferrer">
                  {data.fourthBenefit.ctaTitle}
                </a>
              </div>
            </div>
          </div>
        </div>

        {isPromoEnabled ? (<>
          {/* Benefit 2 / Quinto plato gratis */}
          <div className="md:grid md:grid-cols-2 md:pt-12 md:pb-24">
            {/* lg:hidden  / sm:Top */}
            <div className="h-[35vh] sm:h-[50vh] md:h-[65vh] lg:h-[75vh] xl:h-[90vh] mx-7 rounded-xl bg-cover bg-bottom md:hidden" 
              style={{ backgroundImage: `url(${data.secondBenefit.backgroundImageUrl})` }}>
            </div>

            {/* lg:Right / sm:Bottom*/}
            <div className="text-start content-center px-10 py-8 sm:py-12 sm:px-12 md:py-16 lg:pt-24 xl:pt-48 xl:pr-4 xl:pl-20">
              <div className="">
                <div className="text-left font-brand-montserrat lg:text-base text-sm lg:text-start sm:px-2 md:px-0">
                  <h3 className="pb-4 text-4xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] md:leading-tight font-bold">{data.secondBenefit.title}</h3>
                  <p className="pb-4 text-xl md:text-[22px] 2xl:text-2xl leading-normal md:leading-relaxed">{data.secondBenefit.content}</p>
                </div>
              </div>
            </div>

            {/* lg:Left / sm:hidden */}
            <div className="h-[35vh] sm:h-[55vh] md:h-[65vh] lg:h-[70vh] mx-6 lg:mx-12 xl:mr-20 rounded-xl bg-cover bg-bottom hidden md:block" 
              style={{ backgroundImage: `url(${data.secondBenefit.backgroundImageUrl})` }}>
            </div>
          </div>
        </>) : null}

        

        {/* Beneficio 4 / Delivery gratis */}
        <div className="md:grid md:grid-cols-2 py-16">
          {/* lg:hidden  / sm:Top */}
          <div className="h-[35vh] sm:h-[50vh] md:h-[65vh] lg:h-[75vh] xl:h-[90vh] mx-7 rounded-xl bg-cover bg-bottom md:hidden" 
            style={{ backgroundImage: `url(${data.thirdBenefit.backgroundImageUrl})` }}>
          </div>

          {/* lg:Right  / sm:Bottom */}
          <div className="text-start content-center px-10 pt-8 sm:py-12 sm:px-12 md:py-16 lg:pt-24 xl:pt-32 xl:pr-4 xl:pl-20 2xl:pt-40">
            <div className="">
              <div className="text-left font-brand-montserrat lg:text-base text-sm lg:text-start">
                <h3 className="pb-4 text-4xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] font-bold">{data.thirdBenefit.title}</h3>
                <p className="pb-4 text-xl md:text-[22px] 2xl:text-2xl leading-normal md:leading-relaxed">{data.thirdBenefit.content}</p>
              </div>
            </div>
          </div>

          {/* lg:Left  / sm:hidden */}
          <div className="h-[35vh] sm:h-[55vh] md:h-[65vh] lg:h-[70vh] mx-6 lg:mx-12 xl:mr-20 rounded-xl bg-cover bg-bottom hidden md:block" 
            style={{ backgroundImage: `url(${data.thirdBenefit.backgroundImageUrl})` }}>
          </div>
        </div>
        
        {/* Benefit 1 / Almuerzos planificados a tu gusto */}
        <div className="md:grid md:grid-cols-2 md:py-12">
          {/* lg:Left  / sm:Top */}
          <div className="h-[35vh] sm:h-[55vh] md:h-[65vh] lg:h-[75vh] xl:h-[90vh] mx-7 md:mr-2 md:ml-10 lg:ml-12 xl:ml-20 rounded-xl bg-cover bg-bottom" 
            style={{ backgroundImage: `url(${data.firstBenefit.backgroundImageUrl})` }}>
          </div>

          {/* lg:Right  / sm:Bottom */}
          <div className="text-start content-center px-10 py-8 sm:py-12 sm:px-12 md:py-16 lg:pt-24 xl:pt-48">
            <div className="">
              <div className="text-left font-brand-montserrat">
                <h3 className="pb-4 text-4xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] md:leading-tight font-bold">{data.firstBenefit.title}</h3>
                <p className="pb-4 text-xl md:text-[22px] 2xl:text-2xl leading-normal md:leading-relaxed">{data.firstBenefit.content}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
  