import { useEffect, useRef, useState } from "react";
import _ from 'lodash';
import { FaPlus } from "react-icons/fa";
import dayjs from 'dayjs';
import { priceFormat, getImageURL, scrollParentToChild } from "../../libs/utils";
import config from "../../config";
import { QtyButtons } from "./QtyButtons";
import { IonRefresher, IonRefresherContent } from "@ionic/react";


const bagsReversed = config?.modules?.cart?.bagsReversed;

const ExtraSelect = ({ bagId, item, itemInBag, setItemToBag, getDocsByType }) => {
  const [ openExtraList, setOpenExtraList ] = useState(false);
  
  const extraList = getDocsByType({ id: config.modules.cart.withExtraItemTypeId });

  const addExtra = (extraItemDoc) => {
    setItemToBag(bagId, item.id, 1, extraItemDoc.id);
    setOpenExtraList(false);
  };

  return (
    openExtraList ? (
      <div className={`
      absolute top-4 lg:top-3 right-3
      `}>
        <button onClick={() => setOpenExtraList(false)} className={`
        bg-brand-red text-white content-center rounded-full h-8 w-8
        `}>
          <FaPlus className="ml-2"/>
        </button>
        {extraList?.length ? (
          <div className="w-[200px] p-1.5 absolute top-10 right-0 z-10 bg-brand-red text-white text-center rounded-md shadow-xl shadow-black/30">
            <div className="mb-1 py-1 text-sm font-semibold tracking-wide border-b border-red-300">
              Elegí la guarnición
            </div>
            {extraList.map((extraItemDoc) => (
              <button key={extraItemDoc.id} className="py-2 w-full hover:font-semibold" onClick={() => addExtra(extraItemDoc)} type="button">
                {extraItemDoc.data.name}
              </button>
            ))}
          </div>
        ) : null}
      </div>
    ) : (
      <button onClick={() => setOpenExtraList(true)} className={`
      absolute top-4 lg:top-3 right-3 text-white content-center rounded-full h-8 w-8
        bg-brand-yellow
      `}>
        <FaPlus className="ml-2"/>
      </button>
    )
  );
};

export const CardItemHeader = ({ openIndex, index, bags, items, itemsTypes, getBagTotal, getBagById, isItemInBag, setItemToBag }) => {
  const bagId = bagsReversed ? bags.length - 1 - index : index;
  const bag = getBagById(bagId);

  // const getTotalQtyItemsByBag = (bag) => {
  //   return bag.itemsInBag.reduce((acc, itemsInBag) => {
  //     return acc + itemsInBag.qty;
  //   }, 0);
  // }

  // const totalItemsQty = getTotalQtyItemsByBag(bag);

  return (
    <div className="">
      {/* {totalItemsQty ? (
        <div className="ml-2 p-1.5 w-10 h-10 text-xl font-bold bg-gray-100 text-brand-dark rounded-full border border-gray-400">
          {totalItemsQty}
        </div>
      ) : null} */}
      <h2 className={`text-lg font-regular uppercase leading-none tracking-wide ${index === openIndex ? 'text-white' : 'text-black'} transition-colors duration-700`}>
        {bag && dayjs(bag.date).format('ddd D')}
      </h2>
      <h2 className={`text-xs font-regular capitalize leading-none tracking-wide ${index === openIndex ? 'text-red-300' : 'text-gray-600'} transition-colors duration-700`}>
        {bag && dayjs(bag.date).format('MMM, YYYY')}
      </h2>
      <h3 className={`mt-1 text-xs font-normal leading-none tracking-wide ${index === openIndex ? 'text-white' : 'text-red-600'} transition-colors duration-700`}>
        {priceFormat(getBagTotal(bagId) || 0)}
      </h3>
    </div>
  );
};

export const CardItemContent = ({ index, bags, items, itemsTypes, getBagTotal, getBagById, isItemInBag, setItemToBag, getPrice }) => {
  const bagId = bagsReversed ? bags.length - 1 - index : index;
  const bag = getBagById(bagId);
  const [activeIndex, setActiveIndex] = useState(null);
  const containerRef = useRef();

  useEffect(() => {
    setActiveIndex(null);
  }, [index]);

  const getDocsByType = (itemType) => {
    const itemsList = [];
    bag?.itemsAvailable?.forEach(({ id }) => {
      const itemDoc = items?.find((item) => {
        return item.id === id;
      });
      if (
        itemDoc?.data?.itemType === itemType.id
        && itemDoc.data.available
      ) {
        itemsList.push(itemDoc);
      }
    });
    return itemsList;
  };
  
  const handleRefresh = () => {
    setTimeout(() => {
      window.location.href = '/admin';
    }, 600);
  };

  const handleAccordionClick = (index, e) => {
    setActiveIndex(index === activeIndex ? null : index);
    setTimeout(() => {
      scrollParentToChild(containerRef.current, e.target);
    }, 600);
  };

  return (
    <div className="pb-48 overflow-y-scroll" ref={containerRef}>
      <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={150} onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <div className="mt-10">
        {itemsTypes?.map((itemType, index) => {
          const itemsDocs = getDocsByType(itemType);
          return (
            itemsDocs.length ? (
              <div key={itemType.id} className="mt-4">
                <button 
                  onClick={(e) => handleAccordionClick(index, e)}
                  className={`w-full text-center text-xl font-semibold py-2 ${ index === activeIndex ? 'text-brand-red' : 'text-gray-500' }`}
                >
                  {itemType.data.name}
                </button>

                {index === activeIndex ? (
                  <div className="flex flex-row flex-wrap mx-1">
                    {itemsDocs.length && itemsDocs.map((item, index) => {
                      const itemInBag = isItemInBag(bagId, item.id);
                      const extraList = getDocsByType({ id: config.modules.cart.withExtraItemTypeId });
                      const extraListInBag = [];

                      extraList.map((extraItem) => {
                        const itemInBagWithExtra = isItemInBag(bagId, item.id, extraItem?.id);
                        if (itemInBagWithExtra) {
                          itemInBagWithExtra.extraDoc = items.find((item) => item.id === extraItem?.id);
                          extraListInBag.push(itemInBagWithExtra);
                        }
                      });

                      const hasBagItems = extraListInBag.length || !!itemInBag; 

                      return (
                        <div className="w-1/2 md:w-1/3 xl:w-1/4 p-1" key={index}>
                          <div className={`
                            rounded-xl shadow hover:shadow-md h-full
                            ${hasBagItems ? 'border border-brand-red shadow-brand-red' : 'border border-gray-300'}
                          `}>
                            {/* image xs */}
                            <img src={item?.data?.images?.length && getImageURL(item.data.images[0], 'xs')} 
                              className="h-[170px] min-h-[170px] md:h-[190px] md:min-h-[190px] lg:h-[200px] lg:min-h-[200px] xl:h-[240px] xl:min-h-[240px] rounded-t-xl w-full object-cover" 
                            /> 

                            <div className="relative">
                              {/* data */}
                              <div className="py-2 px-2.5 md:px-3 lg:px-4 text-start">
                                <div className="text-xs uppercase text-gray-600">
                                  {item?.data?.itemType ? itemsTypes.find(itemType => itemType.id === item.data.itemType)?.data?.name : null}
                                </div>
                                <div className="font-semibold leading-tight text-base pr-9">{item.data.name}</div>
                                {item.data.withExtra ? (
                                  <div className="font-semibold leading-tight text-xs">
                                    con guarnición
                                  </div>
                                ) : null}
                                <div className="text-xs text-gray-600 lg:my-0">{priceFormat(getPrice(bag, item))}</div>
                              </div>
                              {item.data.withExtra ? (<>
                                {/* CON Guarnición */}
                                {/* add & select extra */}
                                <ExtraSelect {...{bagId, item, itemInBag, setItemToBag, getDocsByType}} />
                              </>) : (
                                // SIN Guarnición
                                itemInBag ? (
                                  <div className="px-2.5 space-y-1 mb-2">
                                    <QtyButtons {...{
                                      itemInBag, bagId, setItemToBag,
                                      itemId: item.id, 
                                      price: getPrice(bag, item)
                                    }} />
                                  </div>
                                ) : (
                                  <button onClick={() => setItemToBag(bagId, item.id, 1)} className={`
                                  absolute top-4 lg:top-3 right-3 text-white content-center rounded-full h-8 w-8
                                    bg-brand-yellow
                                  `}>
                                    <FaPlus className="ml-2"/>
                                  </button>
                                )
                              )}
                            </div>
                            {item.data.withExtra ? (
                              <div className="rounded-b-xl overflow-hidden">
                                {/* guarnición list */}
                                {extraListInBag?.length ? (
                                  <div className="pl-2.5 pr-2 py-1">
                                    {extraListInBag.map((extraItemInBag, index) => (
                                      <div key={index} className="relative py-2 border-t border-t-brand-red space-y-1 mb-1">
                                        <div className="font-semibold leading-tight">
                                          <div className="text-xs -mb-1">
                                            {item.data.name} con
                                          </div>
                                          <div className="text-base">
                                            {extraItemInBag?.extraDoc?.data?.name}
                                          </div>
                                        </div>
                                        <QtyButtons {...{
                                          bagId, setItemToBag,
                                          itemInBag: extraItemInBag,
                                          itemId: item.id, 
                                          extraItemId: extraItemInBag.extraDoc.id,
                                          price: item.data.price
                                        }} />
                                      </div>
                                    ))}
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </div>
            ) : null
          );
        })}
      </div>
    </div>
  );
};

export const CardItem = ({ index, openIndex, paneInstance, cardItemData }) => {
  const { bags, items, itemsTypes, getBagTotal, getBagById, isItemInBag, setItemToBag } = cardItemData?.current;
  const bagId = bagsReversed ? bags.length - 1 - index : index;
  const bag = getBagById(bagId);

  const getDocsByType = (itemType) => {
    const itemsList = [];
    bag?.itemsAvailable?.forEach(({ id }) => {
      const itemDoc = items?.find((item) => {
        return item.id === id;
      });
      if (
        itemDoc?.data?.itemType === itemType?.id
        && itemDoc.data.available
      ) {
        itemsList.push(itemDoc);
      }
    });
    return itemsList;
  };

  // render lock
  const renderFull = (
    openIndex === index
    || openIndex + 1 === index
    || openIndex - 1 === index
  );

  return (
    <div className="flex flex-col max-h-full relative">
      <div className="px-3 xs:px-4 py-2 text-center bg-white/80 shadow-md backdrop-blur-md">
        <h2 className="text-black text-lg font-regular uppercase leading-none tracking-wide">
          {bag && dayjs(bag.date).format('dddd D')}
        </h2>
        <h2 className="text-gray-600 text-xs font-regular capitalize leading-none tracking-wide">
          {bag && dayjs(bag.date).format('MMMM, YYYY')}
        </h2>
        <h3 className="text-red-600 mt-1 text-md font-normal leading-none tracking-wide">
          {priceFormat(getBagTotal(bagId) || 0)}
        </h3>
      </div>
      <div className="pt-16 pb-48 overflow-y-scroll">
        {renderFull && itemsTypes?.map((itemType) => {
          const itemsDocs = getDocsByType(itemType);
          return (
            itemsDocs.length ? (
              <div key={itemType.id} className="mt-4">
                <h3 className="mb-1 text-center text-sm uppercase text-gray-500 font-semibold">{itemType.data.name}</h3>

                <div className="flex flex-row flex-wrap mx-1">
                  {itemsDocs.length && itemsDocs.map((item) => {
                    const itemInBag = isItemInBag(bagId, item.id);
                    const extraList = getDocsByType({ id: config.modules.cart.withExtraItemTypeId });
                    const extraListInBag = [];

                    extraList.map((extraItem) => {
                      const itemInBagWithExtra = isItemInBag(bagId, item.id, extraItem?.id);
                      if (itemInBagWithExtra) {
                        itemInBagWithExtra.extraDoc = items.find((item) => item.id === extraItem?.id);
                        extraListInBag.push(itemInBagWithExtra);
                      }
                    });

                    const hasBagItems = extraListInBag.length || !!itemInBag; 

                    return (
                      <div className="w-1/2 p-1">
                        <div className={`
                          rounded-xl shadow hover:shadow-md
                          ${hasBagItems ? 'border border-brand-red shadow-brand-red' : 'border border-gray-300'}
                        `}>
                          <div className="relative">
                            {/* image xs */}
                            <img src={item?.data?.images?.length && getImageURL(item.data.images[0], 'xs')} 
                              className="h-[160px] rounded-t-xl w-full object-cover" 
                            /> 
                            {/* data */}
                            <div className="py-2 px-2.5 md:px-3 lg:px-4 text-start">
                              <div className="text-xs uppercase text-gray-600">
                                {item?.data?.itemType ? itemsTypes.find(itemType => itemType.id === item.data.itemType)?.data?.name : null}
                              </div>
                              <div className="font-semibold leading-tight text-base">{item.data.name}</div>
                              {item.data.withExtra ? (
                                <div className="font-semibold leading-tight text-xs">
                                  con guarnición
                                </div>
                              ) : null}
                              <div className="text-xs text-gray-600 lg:my-0">{item.data.price}</div>
                            </div>
                            {item.data.withExtra ? (<>
                              {/* CON Guarnición */}
                              {/* add & select extra */}
                              <ExtraSelect {...{bagId, item, itemInBag, setItemToBag, getDocsByType}} />
                            </>) : (
                              // SIN Guarnición
                              itemInBag ? (
                                <div className="px-2.5 space-y-1 mb-2">
                                  <QtyButtons {...{
                                    itemInBag, bagId, setItemToBag,
                                    itemId: item.id, 
                                    price: item.data.price
                                  }} />
                                </div>
                              ) : (
                                <button onClick={() => setItemToBag(bagId, item.id, 1)} className={`
                                absolute bottom-4 lg:bottom-3 right-3 text-white content-center rounded-full h-8 w-8
                                  bg-brand-yellow
                                `}>
                                  <FaPlus className="ml-2"/>
                                </button>
                              )
                            )}
                          </div>
                          {item.data.withExtra ? (
                            <div className="rounded-b-xl overflow-hidden">
                              {/* guarnición list */}
                              {extraListInBag?.length ? (
                                <div className="pl-2.5 pr-2 py-1">
                                  {extraListInBag.map((extraItemInBag, index) => (
                                    <div key={index} className="relative py-2 border-t border-t-brand-red space-y-1 mb-1">
                                      <div className="font-semibold leading-tight">
                                        <div className="text-xs -mb-1">
                                          {item.data.name} con
                                        </div>
                                        <div className="text-base">
                                          {extraItemInBag?.extraDoc?.data?.name}
                                        </div>
                                      </div>
                                      <QtyButtons {...{
                                        bagId, setItemToBag,
                                        itemInBag: extraItemInBag,
                                        itemId: item.id, 
                                        extraItemId: extraItemInBag.extraDoc.id,
                                        price: item.data.price
                                      }} />
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )
                  })}
                </div>

              </div>
            ) : null
          );
        })}
      </div>
    </div>
  );
};

