import { useEffect,useState } from "react";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import { priceFormat, getImageURL } from "../../libs/utils";
import { EntityDocListView } from '../../components/EntityDocView';
import { usePanelSheet } from "../../components/ui/PanelSheet";
import config from "../../config";
import BtnLoading from "../../components/ui/BtnLoading";

import dayjs from 'dayjs';
import EntityGetOneDocument from "../../components/EntityGetOneDocument";
import Model from "../../libs/ModelClass";
import { useEntityFullBySlug } from "../entity/Entity";
import EntityFilterMenuDisplay from "../../components/EntityFilterMenuDisplay";
import dataToQueryFormatter from "../../components/Filters/dataToQueryFormatter";
import toast from "react-hot-toast";
import ModalAlert from "../../components/ui/ModalAlert";
import { getPrice } from "./useCartData";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";

const filterMenuSlug = "crud";
const maxResultsForCustomer = 4;

const getMap = (user) => {
  if (user?.rolesDoc?.data?.nameSlug === 'superAdmin') {
    return {
      listShowUser: true,
      filterUserLimit: false,
      listShowInstitution: true,
      filtersPatch: (filters) => (filters)
    };
  }
  else if (user?.rolesDoc?.data?.nameSlug === 'mainCustomer') {
    return {
      listShowUser: true,
      filterUserLimit: true,
      listShowInstitution: false,
      filtersPatch: (filters) => ({ 
        ...filters,
        institutionId: user?.userDoc?.data?.institutionId
      })
    };
  }
  else if (user?.rolesDoc?.data?.nameSlug === 'customer') {
    return {
      listShowUser: false,
      filterUserLimit: true,
      listShowInstitution: false,
      limitResults: maxResultsForCustomer,
      filtersPatch: (filters) => ({ 
        ...filters,
        userId: user?.userDoc?.id,
        institutionId: user?.userDoc?.data?.institutionId
      })
    };
  } 
  return {
    listShowUser: false,
    filterUserLimit: true,
    listShowInstitution: false,
    limitResults: false,
    filtersPatch: (filters) => (filters)
  };
};

export const usePermissionsMap = (user) => {
  return getMap(user);
};

const CartCardItem = ({ 
  item, onClose, isAllowed
}) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [singleProductIndexInBagToCancel, setSingleProductIndexInBagToCancel] = useState();
  const cartDoc = item;
  const [ bagsPopulated, setBagsPopulated ] = useState([]);

  // Item taxonomyTypesDocList
  const { taxonomyTypesDocList, mainAttr, mainImgAttr } = useEntityFullBySlug({ entitySlug: config?.modules?.cart?.cartItemEntitySlug });

  useEffect(() => {
    fetchCartDetailsData();
  }, []);

  const fetchCartDetailsData = async () => {
    const ItemModel = Model.extend(config?.modules?.cart?.cartItemEntitySlug);
    const populatedBags = [];
    for (const bag of cartDoc?.data?.itemsInBags) {
      const populatedItemsInBag = [];
      for (const bagItems of bag.bagItems) {
        const itemDoc = await ItemModel.findById(bagItems.id);
        let itemExtraDoc;
        if (bagItems.withExtra) {
          itemExtraDoc = await ItemModel.findById(bagItems.withExtra);
        }
        populatedItemsInBag.push({
          ...bagItems,
          itemDoc,
          itemExtraDoc
        });
      }
      populatedBags.push({
        ...bag,
        populatedItemsInBag
      });
    }
    setBagsPopulated(populatedBags);
  };

  const confirmedDelete = async () => {
    await cartDoc.delete();
    setShowDeleteAlert(false);
    toast.error('Se ha eliminado el documento');
  };

  const cancelSingleProduct = async () => {
    const { bagId, index } = singleProductIndexInBagToCancel;
    const itemInBag = cartDoc.data.itemsInBags[bagId].bagItems[index];
    const priceToDiscount = itemInBag.price * itemInBag.qty;
    // mark as cancelled
    itemInBag.deleted = true;
    itemInBag.deletedAt = new Date().toISOString();
    // discount price from total by bag
    cartDoc.data.itemsInBags[bagId].total -= priceToDiscount;
    // discount price from total of cart
    cartDoc.data.total -= priceToDiscount;
    await cartDoc.save();
    await fetchCartDetailsData();
    setSingleProductIndexInBagToCancel(null);
    toast.error('Se ha anulado el producto ' +  bagsPopulated[bagId].populatedItemsInBag[index].itemDoc.data.name + ' del día ' +  dayjs(bagsPopulated[bagId].date).format('LL'));
  };

  const canDeleteItemInBag = (bag) => {
    if (isAllowed('carts', 'cancelOldItem')) {
      return true;
    }
    const bagDate = dayjs(new Date(bag.date)).utc();
    const maxDate = bagDate.startOf('day').subtract(1, 'day').hour(21);
    const today = dayjs().utc();
    const canCancel = dayjs(today).isBefore(maxDate);
    return canCancel;
  };

  const close = async () => {
    onClose && await onClose(item);
  };

  const getTotalQtyItemsByBag = (bag) => {
    return bag.bagItems.reduce((acc, bagItems) => {
      return acc + bagItems.qty;
    }, 0);
  };

  return (
    <div className="flex flex-col max-h-full">

      {/* header */}
      <div className="px-4 py-3 lg:py-4 bg-white shadow flex flex-row place-content-between pr-4 gap-2">
        <div className="flex flex-row gap-4 items-center leading-3">
          <div>
            {item.data.deleted ? (
              <div className="mb-2">
                <span className="px-2 py-0.5 rounded-full text-sm text-white bg-red-600">Documento eliminado</span>
              </div>
            ) : null}

            {/* <span className="text-lg font-semibold tracking-tighter block">{item?.data?.id}</span> */}

            <span className="text-3xl font-semibold tracking-tighter">{priceFormat(item?.data?.total)}</span>
            {/* User */}
            <EntityGetOneDocument
              entitySlug={'usersProfiles'}
              docId={item.data?.userId}>
              {({ doc, entityDoc }) => (
                isAllowed('usersProfiles', ['list']) ? (
                  <Link to={`/admin/entity/${entityDoc?.id}/${doc?.id}`} className="block text-sm text-gray-900 underline">{doc.data?.name}</Link>
                ) : (
                  <div className="text-sm text-gray-900">{doc.data?.name}</div>
                )
              )}
            </EntityGetOneDocument>

            {/* Institución */}
            <EntityGetOneDocument
              entitySlug={'institutions'}
              docId={item.data?.institutionId}>
              {({ doc, entityDoc }) => (
                isAllowed('institutions', ['list']) ? (
                  <Link to={`/admin/entity/${entityDoc?.id}/${doc?.id}`} className="block text-sm text-gray-900 underline">{doc.data?.name}</Link>
                ) : (
                  <div className="text-sm text-gray-900">{doc.data?.name}</div>
                )
              )}
            </EntityGetOneDocument>

            <div className="text-sm text-gray-400">{dayjs(item.data?.createdAt).tz(config.timezone).format('LLL')}</div>
          </div>
        </div>
        <div>
          <div className="flex flex-row gap-2">
            {!item.data.deleted && isAllowed('carts', ['delete']) ? (
              <div className="flex flex-row gap-2">
                <BtnLoading
                  label="Eliminar"
                  onClick={() => setShowDeleteAlert(true)}
                  withLoading
                  colorClass="text-white"
                  className="inline-block mt-1.5 px-2 py-1 text-sm text-white hover:scale-105 bg-brand-red shadow-none"
                />
              </div>
            ) : null}
            <BtnLoading
              label="Cerrar"
              onClickAsync={close}
              withLoading
              disabled={false}
              className="mt-1.5 px-2 py-1 text-xs text-gray-900 hover:scale-105 bg-gray-200 shadow-none"
            />
          </div>
        </div>
      </div>

      {/* content */}
      <div className="overflow-y-scroll px-4 pt-4 pb-32">
        <h3 className="mb-4 text-md font-semibold text-center text-gray-500">
          Productos seleccionados
        </h3>
        <div className="space-y-4">
          {bagsPopulated.map((bag, bagId) => {
            const totalItemsQty = getTotalQtyItemsByBag(bag);
            return (
              <div key={bagId} className="px-2">
                <div className="px-2 flex flex-row place-content-between">
                  <div className="">
                    <h2 className="text-black text-lg font-regular uppercase leading-none tracking-wide">
                      {bag?.date && dayjs(bag.date).format('dddd D')}
                    </h2>
                    <h2 className="text-gray-600 text-xs font-regular capitalize leading-none tracking-wide">
                      {bag?.date && dayjs(bag.date).format('MMMM, YYYY')}
                    </h2>
                  </div>
                  <div className="">
                    {totalItemsQty ? (
                      <div className="mr-2 inline-block text-center p-0.5 w-6 h-6 text-sm font-semibold bg-gray-100 text-gray-700 rounded-full">
                        {totalItemsQty}
                      </div>
                    ) : null}
                    Total:
                    <h3 className="inline pl-1 text-red-600 mt-1 text-md font-normal leading-none tracking-wide">
                      {priceFormat(bag.total || 0)}
                    </h3>
                  </div>
                </div>

                <div className={`px-2 pb-8 cursor-default ${bag.populatedItemsInBag?.length > 0 ? 'mt-6 mb-6' : 'mb-2'}`}>
                  {bag?.populatedItemsInBag?.length ? bag.populatedItemsInBag.map((itemInBag, index) => {
                    // // comenatado para mostrar datos de lo cancelado
                    // if (itemInBag.deleted) {
                    //   return null;
                    // }
                    return (
                      <EntityDocListView
                        key={index}
                        doc={itemInBag.itemDoc}
                        taxonomyTypesDocList={taxonomyTypesDocList}
                        mainAttr={mainAttr}
                        mainImgAttr={mainImgAttr}
                        classes={{
                          fieldLabel: "hidden"
                        }}
                        render={({ ViewData, mainAttr, mainImgAttr }) => (
          
                          <div className="w-full flex flex-row border-b border-gray-200 pb-2 mb-2 text-center grow place-content-between items-center">
                            {/* image xs */}
                            <div className="w-2/5">
                              <img src={itemInBag.itemDoc?.data && getImageURL(itemInBag.itemDoc.data[mainImgAttr][0], 'xs')} 
                                className="h-[110px] xs:h-[140px] w-full object-cover rounded-md shadow-sm border border-gray-200"
                              /> 
                            </div>
                            {/* data */}
                            <div className="w-3/5 pb-1 px-1 space-y-1">
                              <div className="">
                                {/* <ViewData field="name" classes={{ fieldContainer: 'px-1 md:text-lg lg:text-xl font-semibold leading-tight' }} /> */}
                                <div className={itemInBag.deleted ? 'line-through' : ''}>
                                  {isAllowed(config.modules?.cart?.cartItemEntitySlug, ['list']) ? (
                                    <Link to={`/admin/entity/${config.modules?.cart?.cartItemEntityId}/${itemInBag.itemDoc?.id}`} className="px-1 md:text-lg lg:text-xl font-semibold leading-tight underline">{itemInBag.itemDoc.data?.name}</Link>
                                  ) : (
                                    <div className="px-1 md:text-lg lg:text-xl font-semibold leading-tight">{itemInBag.itemDoc.data?.name}</div>
                                  )}
                                </div>
                                {/* CON Guarnición */}
                                {itemInBag.itemDoc?.data.withExtra ? (<>
                                  <div className=''> con <u>
                                      {isAllowed(config.modules?.cart?.cartItemEntitySlug, ['list']) ? (
                                        <Link to={`/admin/entity/${config.modules?.cart?.cartItemEntityId}/${itemInBag.itemExtraDoc?.id}`} className="">{itemInBag.itemExtraDoc?.data?.name}</Link>
                                      ) : (
                                        <span className="">{itemInBag.itemExtraDoc?.data?.name}</span>
                                      )}
                                    </u>
                                  </div>
                                </>) : null }
                                <div className="text-sm text-center text-gray-600 lg:my-0">{priceFormat(getPrice(itemInBag, itemInBag.itemDoc))}</div>
                                {canDeleteItemInBag(bag) && !itemInBag.deleted ? (
                                  <BtnLoading
                                    label="Anular este producto"
                                    onClick={() => setSingleProductIndexInBagToCancel({ bagId, index })}
                                    withLoading
                                    colorClass="text-white"
                                    className="!inline mt-1.5 px-1.5 py-0.5 text-xs text-white hover:scale-105 bg-brand-red/70 shadow-none"
                                  />
                                ) : null}
                                {itemInBag.deleted ? (
                                  <div className="text-brand-red text-xs">
                                    Producto anulado
                                    <br />
                                    {dayjs(itemInBag.deletedAt).format('lll')}
                                  </div>
                                ) : null}
                              </div>
                              {!itemInBag.deleted ? (
                                <div className="pt-2 grid grid-cols-2">
                                  <div className="">
                                    <h3 className="text-xs uppercase font-regular text-gray-500">Cantidad</h3>
                                    <div className="text-sm uppercase font-semibold">{itemInBag.qty}</div>
                                  </div>
                                  <div className="">
                                    <h3 className="text-xs font-regular text-gray-500">Total</h3>
                                    <div className="text-sm uppercase font-semibold">{priceFormat(itemInBag.qty * itemInBag.price)}</div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            {singleProductIndexInBagToCancel?.bagId === bagId && singleProductIndexInBagToCancel?.index === index && (
                              <ModalAlert
                                text={`¿Estás seguro de que deseas anular ${itemInBag.itemDoc?.data?.name}?`}
                                onConfirm={cancelSingleProduct}
                                onCancel={() => setSingleProductIndexInBagToCancel(null)}
                              />
                            )}
                          </div>
                          
                        )}
                      />
                    );
                  }) : null}
                </div>
              </div>
            )
          })}
        </div>

        {showDeleteAlert && (
          <ModalAlert
            text="¿Estás seguro de que deseas eliminar este elemento?"
            onConfirm={confirmedDelete}
            onCancel={() => setShowDeleteAlert(false)}
          />
        )}
      </div>
    </div>
  );
};

const ListItemsFiltered = ({ filtersSelected, user, isAllowed, onShow }) => {
  const { filterMenuTaxonomyTypes } = useEntityFullBySlug({ entitySlug: config?.modules?.cart?.cartsEntitySlug, filterMenuSlug });
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [docsListed, setDocsListed] = useState([]);
  const docsPerPage = 30;
  const ExtendedModel = Model.extend(config?.modules?.cart?.cartsEntitySlug);
  const permissionsMap = usePermissionsMap(user);
  
  useEffect(() => {
    filterMenuTaxonomyTypes && fetchFilteredDocs();
  }, [filterMenuTaxonomyTypes, filtersSelected]);

  const fetchFilteredDocs = async () => {
    const filterQuery = dataToQueryFormatter(permissionsMap.filtersPatch(filtersSelected), filterMenuTaxonomyTypes);
    let filteredDocs = await ExtendedModel.filterByAttributes(filterQuery);
    // filteredDocs = filteredDocs.filter(doc => !doc.data?.deleted);
    filteredDocs = filteredDocs.sort((a, b) => new Date(b.data.createdAt) - new Date(a.data.createdAt));
    if (permissionsMap.limitResults) {
      filteredDocs = filteredDocs.slice(0, permissionsMap.limitResults);
    }
    setFilteredDocs(filteredDocs);
    setDocsListed(filteredDocs.slice(0, docsPerPage));  // Inicializar los primeros documentos
  };

  const nextPage = () => {
    const start = docsListed.length;
    const end = start + docsPerPage;
    setDocsListed(prevDocs => [
      ...prevDocs,
      ...filteredDocs.slice(start, end)
    ]);
  };

  return (
    <div className="px-2 border border-gray-200 rounded-md">
      {docsListed?.map((item, index) => (
        <div className={`
          flex flex-row place-content-between
          py-2 border-b border-gray-200 last:border-none 
        `} key={item.id}>
          <div className="">
            {item.data.deleted ? (
              <div className="flex align-middle gap-2">
                <span className="text-xl text-red-700 font-semibold tracking-tighter">{priceFormat(item?.data?.total)}</span>
                <div>
                  <span className="bg-red-700 rounded-full px-2 py-0.5 text-xs text-white">Eliminado</span>
                </div>
              </div>
            ) : (
              <span className="text-xl font-semibold tracking-tighter">{priceFormat(item?.data?.total)}</span>
            )}

            {/* User */}
            {permissionsMap?.listShowUser ? (
              <EntityGetOneDocument
                entitySlug={'usersProfiles'}
                docId={item.data?.userId}>
                {({ doc, entityDoc }) => (
                  isAllowed('usersProfiles', ['list']) ? (
                    <Link to={`/admin/entity/${entityDoc?.id}/${doc?.id}`} className="block text-sm text-gray-900 underline">{(doc?.data?.firstName || '') + ' ' + (doc?.data?.lastName || '')}</Link>
                  ) : (
                    <div className="text-sm text-gray-900">{(doc?.data?.firstName || '') + ' ' + (doc?.data?.lastName || '')}</div>
                  )
                )}
              </EntityGetOneDocument>
            ) : null}

            {/* Institución */}
            {permissionsMap?.listShowInstitution ? (
              <EntityGetOneDocument
                entitySlug={'institutions'}
                docId={item.data?.institutionId}>
                {({ doc, entityDoc }) => (
                  isAllowed('institutions', ['list']) ? (
                    <Link to={`/admin/entity/${entityDoc?.id}/${doc?.id}`} className="block text-sm text-gray-900 underline">{doc.data?.name}</Link>
                  ) : (
                    <div className="text-sm text-gray-900">{doc.data?.name}</div>
                  )
                )}
              </EntityGetOneDocument>
            ) : null}

            <div className="text-sm text-gray-400">{dayjs(item.data?.createdAt).tz(config.timezone).format('lll')}</div>
          </div>
          <div>
            <BtnLoading label="Ver" onClick={() => onShow(item)} withLoading className="px-2 py-0.5 text-gray-800 hover:scale-105 bg-gray-200 shadow-none text-xs" />
          </div>
        </div>
      ))}

      <IonInfiniteScroll
        onIonInfinite={(ev) => {
          nextPage();
          setTimeout(() => ev.target.complete(), 500);
        }}
      >
        <IonInfiniteScrollContent></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </div>
  );
};

const panelConfig = ({ windowHeight }) => {
  return {
    breaks: {
      top: { 
        enabled: true, 
        height: (windowHeight || 900) - 80,
        bounce: true
      },
      middle: { enabled: false },
      bottom: { enabled: false },
    },
    dragBy: ['.no-draggable'],
    preventClicks: false,
    initialBreak: 'top',
    backdrop: true,
    backdropOpacity: 0.5,
    buttonDestroy: false,
    bottomClose: true,
    preventClicks: false,
    simulateTouch: false,
    passiveListeners: false
  };
};

export const RouteCartHistory = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {
  const [selectedDoc, setSelectedDoc] = useState();
  const { panelRef, paneInstance, openPanel, closePanel } = usePanelSheet(panelConfig);
  const [filtersSelected, setFiltersSelected] = useState({});
  const permissionsMap = usePermissionsMap(user);


  const onShow = (doc) => {
    setSelectedDoc(doc);
    openPanel();
  };

  const onClose = async (doc) => {
    closePanel();
    setSelectedDoc(null);
  };

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin`}
      title="Historial de Pedidos"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        title: "Pedidos"
      }]}
    >
      <div className="ion-padding flex flex-col lg:flex-row">
        <div className="lg:w-2/5">
          <EntityFilterMenuDisplay
            entitySlug={config.modules.cart.cartsEntitySlug}
            filterMenuSlug={filterMenuSlug}
            filterData={filtersSelected}
            onFormChange={(formValues) => {
              setFiltersSelected(formValues);
            }}
            onFormClear={() => {
              setFiltersSelected(null);
            }}
            style={'search-collapsible-panel'}
            paramsByFieldSlug={{
              'userId': {
                isShow: permissionsMap?.listShowUser,
                queryGetter: permissionsMap?.filterUserLimit ? (async (ExtendedModel) => {
                  const docs = await ExtendedModel.filterByAttributes({ institutionId: user.userDoc.data.institutionId });
                  ExtendedModel.sortDocs(docs, 'name');
                  return docs;
                }) : undefined,
                optionsMapper: (docs) => docs.map((option) => {
                  let label = option?.data?.name;
                  if (option?.data?.ci) {
                    label = label + `, CI: ${option?.data?.ci}`;
                  }
                  if (option?.data?.phone) {
                    label = label + `, ${option?.data?.phone}`;
                  }
                  if (option?.data?.email) {
                    label = label + `, ${option?.data?.email}`;
                  }
                  return {
                    value: option?.id,
                    label
                  };
                })
              },
              'institutionId': {
                isShow: permissionsMap?.listShowInstitution,
                optionsMapper: (docs) => docs.map((option) => ({
                  value: option?.id,
                  label: `${option?.data?.name} | ${option?.data?.city || ''}, ${option?.data?.location || ''}`
                }))
              }
            }}
          />
        </div>

        <div className="mt-4 lg:mt-0 lg:ml-4 lg:w-3/5">
          <ListItemsFiltered {...{filtersSelected, user, isAllowed, onShow}} />
        </div>  
      </div>
      {/* Selector de items a incluirse en el range */}
      <div ref={panelRef} className="w-full h-screen">
        {selectedDoc ? (
          <CartCardItem {...{item: selectedDoc, onClose, isAllowed}} />
        ) : null}
      </div>
    </LayoutAdmin>
  );
};