import {
  FormField,
  FormFieldSelect, // options: [{value, label}]
  FormFieldTextArea,
  FormFieldCustom,
  RawInputTextareaMarkdown
} from '../Form';

// TODO richWYSIWYG

export default { 
  Render(props) {
    const {
      fieldName,
      taxonomyType,
      overrideParams = {},
      values,
      onChange,
      fieldsRequired
    } = props;
    const taxonomyTypeData = taxonomyType?.data || taxonomyType;
    const title = taxonomyTypeData?.name;
    const design = taxonomyTypeData?.style?.design;
    const _fieldName = fieldName || taxonomyTypeData?.nameSlug;
    const param = { ...taxonomyTypeData?.param, ...overrideParams };
    const style = param?.style;
    param.disabled = taxonomyTypeData?.readOnly;

    if(design === "simple") {
      return(
        <FormFieldTextArea 
          name={_fieldName}
          title={title}
          placeholder={title}
          onSelect={onChange}
          fieldsRequired={fieldsRequired}
          {...param}
          {...style}
          {...props}
        />
      )
    }

    else if (design === "richMarkdown") {
      return (
        <FormFieldCustom 
          name={_fieldName}
          title={title}
          fieldsRequired={fieldsRequired}
          {...param}
          {...style}
          {...props}
          Render={RawInputTextareaMarkdown}
        />
      )
    }

    // else if (design === "richWYSIWYG") {
    //   return (
    //     <FormFieldCustom 
    //       name={_fieldName}
    //       title={title}
    //       fieldsRequired={fieldsRequired}
    //       {...param}
    //       {...style}
    //       {...props}
    //       Render={RawInputTextareaMarkdown}
    //     />
    //   )
    // }
    
    return null;
  },

  RenderInputParams: ({ values, param }) => (
    <>
      <FormField
        name="param.minLength"
        title="Longitud mínima"
        type="number"
        maxValue={values.param && parseInt(values.param.maxLength)}
      />
      <FormField
        name="param.maxLength"
        title="Longitud máxima"
        type="number"
        minValue={values.param && parseInt(values.param.minLength)}
      />
    </>
  ),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (
    <>
      <FormFieldSelect
        name="show.textArea.operator"
        title="Operador"
        placeholder="Operador"
        options={[
          { value: 'hasLength', label: 'Tiene contenido' },
          { value: 'isEmpty', label: 'Está vacío' },
          { value: 'eq', label: 'es igual' },
        ]}
      />
      {values.show?.text?.operator === 'eq' ? (
        <FormField
          name="show.textArea.value"
          title="Valor"
          placeholder="valor"
        />
      ) : ''}
    </>
  ),
  
  RenderStyleParams: ({ values }) => (
    <FormFieldSelect
      name="style.design"
      title="Diseño"
      placeholder="Diseño"
      options={[
        { value: 'simple', label: 'Simple' },
        { value: 'richMarkdown', label: 'Enriquecido: Markdown' },
        // { value: 'richWYSIWYG', label: 'Enriquecido: WYSIWYG' },
      ]}
    />
  ),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (null),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}