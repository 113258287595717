import {
  FormField,
  FormFieldSelect,
  FormFieldMultiText,
  FormFieldMultiSelectSwitch,
  FormFieldSelectButtons
} from '../Form';

import {
  // Select
  FilterRenderMultiSelect,
  FilterCreatorMultiSelect,
} from '../Filters';

export default {
  Render(props) {
    const {
      fieldName,
      taxonomyType,
      overrideParams = {},
      fieldsRequired
    } = props;
    const taxonomyTypeData = taxonomyType?.data || taxonomyType;
    const title = taxonomyTypeData?.name;
    const design = taxonomyTypeData?.style?.design
    const _fieldName = fieldName || taxonomyTypeData?.nameSlug;
    const param = {
      ...taxonomyTypeData?.param, // adds { }
      ...overrideParams,
      classes: taxonomyTypeData?.style?.classes,
      show: taxonomyTypeData?.show,
    };
    const style = param?.style;
    param.disabled = taxonomyTypeData?.readOnly;

    if(design === "toggle") {
      return(
        <FormFieldMultiSelectSwitch // TODO: disabled
          name={_fieldName}
          title={title}
          placeholder={title}
          fieldsRequired={fieldsRequired}
          {...param}
          {...style}
          {...props}
        />
      )
    }

    if(design === "select") {
      return(
        <FormFieldSelect 
          name={_fieldName}
          title={title}
          placeholder={title}
          fieldsRequired={fieldsRequired}
          {...param}
          {...style}
          {...props}
        />
      )
    }

    else if (design === "buttons") {
      return (
        <FormFieldSelectButtons // TODO: disabled
          name={_fieldName}
          title={title}
          fieldsRequired={fieldsRequired}
          {...param}
          {...style}
          {...props}
        />
      )
    }
    
    return null;
  },

  RenderFilter(props) {
    return <FilterRenderMultiSelect {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorMultiSelect {...props} />
  },

  RenderInputParams: ({ values, param }) => (
    <FormFieldMultiText name="param.options" title="Opciones" placeholder="Opciones" />
  ),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (<>
    <FormFieldSelect
      name="show.multiselect.operator"
      title="Operador"
      placeholder="Operador"
      options={[
        { value: 'eq', label: 'es igual' },
      ]}
    />
    <FormFieldSelect
      name="show.multiselect.values"
      title="Opciones"
      placeholder="Opciones"
      options={taxonomyToWatch?.data?.param?.options}
    />
  </>),
  
  RenderStyleParams: ({ values }) => (<>
    <FormFieldSelect
      name="style.design"
      title="Diseño"
      placeholder="Diseño"
      options={[
        { value: 'toggle', label: 'Switch' },
        { value: 'select', label: 'Select' },
        { value: 'buttons', label: 'Buttons' }
      ]}
    />
  <div className="mt-4 p-2 bg-white rounded">
    <label className="mb-2 pb-2 block font-semibold border-b border-gray-200">Estilos</label>
    <FormField
      name={`style.classes.switchListContainer`}
      showLabel={true}
      title="Contenedor de lista de Switchs"
      placeholder="Clases"
      className="inline-block"
      type="text"
    />
  </div>  
  </>),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => {
    if (values.style?.design === 'toggle') {
      return (<FormFieldMultiSelectSwitch {...formFieldProps} />);
    }
    if (values.style?.design === 'select') {
      return (<FormFieldSelect {...formFieldProps} />);
    }
    if (values.style?.design === 'buttons') {
      return (<FormFieldSelectButtons {...formFieldProps} />);
    }
    return '';
  },
 
  getDefaultTaxonomyTypeDesign: () => ('toogle')
}