import { useIonViewDidEnter } from "@ionic/react";
import { CupertinoPane } from "cupertino-pane";
import { useEffect, useRef } from "react";
import useOnResize from "../../libs/useOnResize";

const IonDrawer = ({ panelKey, refresh, configGetter, children, noWrap }) => {
  const windowHeight = useOnResize(() => window?.innerHeight);
  const drawerRef = useRef();
  const isShowing = useRef(false);

  const hidePanel = async () => {
    isShowing.current = false;
    await drawerRef.current?.destroy({ animate: true });
  };

  useEffect(() => {
    const showPanel = async () => {
      isShowing.current = true;
      await drawerRef.current?.present({ animate: true });
    };
    if (refresh) {
      showPanel();
    } else if (refresh === -1) {
      hidePanel();
    }
  }, [refresh]);

  useIonViewDidEnter(async () => {
    if (drawerRef?.current) { return; }
    let paneConfig = configGetter({ 
      windowHeight: windowHeight || window?.innerHeight,
      hidePanel
    });
    paneConfig.events = { ...paneConfig.events };
    paneConfig.onBackdropTap = () => hidePanel();
    drawerRef.current = new CupertinoPane(`.${panelKey}`, paneConfig);
    drawerRef.current.device.ionic = false;
  }, []);

  if (noWrap) {
    return children;
  }
  
  return <div className={panelKey}>{children}</div>;
};

export default IonDrawer;
