import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Select from 'react-select';
import toast from "react-hot-toast";
import dayjs from 'dayjs';
import { DayPicker } from 'react-day-picker';
import { es } from 'date-fns/locale';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import Model from "../../libs/ModelClass";
import BtnLoading from "../../components/ui/BtnLoading";
import PlanningDeliveryMap from "./PlanningDeliveryMap";

const CredentialsModel = Model.extend('credentials');
const RolesModel = Model.extend('roles');
const UsersProfilesModel = Model.extend('usersProfiles');
const DeliveryPacksModel = Model.extend('deliveryPacks');


export const RouteDeliveryPlanning = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {  
  const [dateSetted, setDate] = useState();
  const [month, setMonth] = useState(dateSetted);
  const [usersOptions, setUsersOptions] = useState([]);
  const [userSelected, setUserSelected] = useState();
  const [packsSelected, setPacksSelected] = useState([]);

  useEffect(() => {
    const today = dayjs().toDate(); // local time to use as input
    setDate(today);
    setMonth(today);
    if (!usersOptions.length) {
      fetchAllUsersDrivers();
    }
  }, []);
  
  const startDateChange = (value) => {
    if (value) {
      setDate( dayjs(value).utc().toDate() );
    }
  };

  const onSelectPack = (packs) => {
    setPacksSelected([...packs]);
  };

  const fetchAllUsersDrivers = async () => {
    let rolesDoc = await RolesModel.filterByAttributes({ nameSlug: 'deliveryDriver' });
    let credentials = await CredentialsModel.filterByAttributes({ roles: rolesDoc[0].id });
    const usersIds = credentials.map(doc => doc.data.profile);
    let users = await UsersProfilesModel.filterByAttributes({ id: { in: usersIds } });
    users = users.filter(docs => docs.data.deleted !== true);
    users = users.map(docs => ({ value: docs.id, label: docs.data.name }));
    setUsersOptions(users);
    return users;
  };

  const savePlanning = async () => {
    if (!verifyData()) { return; }
    try {
      await DeliveryPacksModel.create({
        userId: userSelected.value,
        packs: packsSelected,
        institutionsIds: packsSelected.map(pack => pack.institutionId),
        date: dayjs(dateSetted).utc().format('YYYY-MM-DD')
      });
      history.push('/admin/delivery/history');
    } catch (error) {
      toast.error('Ocurrió un error: ' + error.message);
      console.error(error);
    }
  };

  const verifyData = () => {
    if (!userSelected) {
      toast.error('Debe seleccionar un conductor');
      return false;
    }
    if (!packsSelected.length) {
      toast.error('Debe asignar paquetes para el envío');
      return false;
    }
    return true;
  };

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin/delivery/history`}
      title="Nueva hoja de ruta"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        url: '/admin/delivery/history',
        title: 'Planificación de Entregas'
      }, {
        title: 'Agregar'
      }]}
    >
      <div className="ion-padding">
        <BtnLoading
          label="Guardar"
          onClickAsync={savePlanning}
          withLoading
          disabled={!dateSetted}
          colorClass="text-white"
          className="px-3 py-1 text-sm text-white hover:scale-105 bg-brand-red shadow-none"
        />

        <div className="mt-4 border border-gray-200 rounded-md p-2">
          <DayPicker
            mode="single"
            month={month}
            onMonthChange={setMonth}
            selected={dateSetted}
            onSelect={startDateChange}
            className="justify-center"
            locale={es}
            modifiersClassNames={{
              day_selected: 'bg-brand-red'
            }}  
          />
        </div>
        <div className="mt-4 flex flex-row gap-2">
          <Select
            className="flex-1"
            isMulti={false}
            options={usersOptions}
            placeholder="Seleccionar conductor"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'lightgray',
                primary: 'darkgray',
              },
            })}
            value={userSelected}
            onChange={option => setUserSelected(option)}
          />
        </div>

        <div className="mt-4 mb-[300px] flex flex-col">
          <PlanningDeliveryMap {...{ daySelected: dateSetted, onSelectPack }} />
        </div>
      </div>
    </LayoutAdmin>
  );
};