import { LayoutAdmin } from "../components/LayoutAdmin";
import SectionCrudModel from "../components/SectionCrudModel";
import Entity, { getEntitiesBlueprint } from '../modules/entity/Entity';
import { downloadJSON } from '../libs/utils';
import { IonButton } from '@ionic/react';
import Model from '../libs/ModelClass';
import toast from 'react-hot-toast';


const exportEntities = async () => {
  try {
    let entitiesBlueprints = await getEntitiesBlueprint();
    downloadJSON(entitiesBlueprints, 'entitiesBlueprints.json');
  } catch (error) {
    console.log('Error exporting entities:', error);
  }
};

export function Content(props) {
  const exportEntityData = async (entitySlug) => {
    try {
      let entityData = await Model.extend(entitySlug).getAll();
      entityData = entityData.map(({ data }) => ({
        ...data
      }));
      downloadJSON(entityData, 'entityData.json');
      toast.success('Data has been exported successfully from ' + entitySlug);
    } catch (error) {
      console.log('Error exporting entity:', error);
    }
  };
  
  const ListItem = ({ doc }) => (<div className=''>
    <span className="">{doc?.data?.name}</span>
    <div className="">
      <span className="text-sm text-sky-600">{doc.data?.nameSlug}</span>
    </div>
  </div>);

  const ExtraActions = () => (<>
    <button onClick={exportEntities} className="text-left bg-gray-200 text-gray-900 rounded px-3 p-1 text-sm">
      Exportar JSON
    </button>
  </>);

const ItemExtraActions = ({ doc, isAllowed }) => (<>
  {(isAllowed('entities', ['export'])) ? (
      <IonButton color="medium" size="small" fill="clear" onClick={() => exportEntityData(doc)}>
        Export
      </IonButton>
    ) : null}
  </>);

  return (
    <div className="">
      <SectionCrudModel
        {...props}
        model={Entity}
        entitySlug="entities"
        editStyle="route"
        reorder={true}
        navigateTo={(doc) => (`/admin/config/entity-creator/${doc ? doc.id : 'new'}/form`)}
        // add UI
        ExtraActions={ExtraActions}
        ListItem={ListItem}
        ItemExtraActions={ItemExtraActions}
      />
    </div>
  );
}

export function AdminEntityCreator(props) {
  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={`/admin/config`}
      title="Entidades"
      breadcrumbs={[{
        url: '/admin/config',
        title: 'Config'
      }, {
        title: "Entidades"
      }]}
    >
      <div className="ion-padding">
        <Content {...props} />
      </div>
    </LayoutAdmin>
  );
}
