import { Capacitor } from '@capacitor/core';
import { IonButton, IonIcon, IonItem, IonItemGroup, IonLabel, IonList } from "@ionic/react";
import { 
restaurant,
informationCircle,
ban
} from 'ionicons/icons';
import ContactBTN from "../../components/ContactBTN";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useAsyncMemo } from "use-async-memo";
import { GetOneModel } from "../../libs/ModelClass";
import { usePanel } from "./PanelContext";
import { useMemo } from "react";
import config from "../../config";

export const AdminHomeBeforeLinksList = ({ isAllowed, user }) => {
  const { versionSpects } = usePanel();

  const institutionDoc = useAsyncMemo(async () => {
    return await GetOneModel('institutions', user?.userDoc?.data?.institutionId);
  }, []);  

  const hasUpdates = versionSpects?.availableHardVersion || versionSpects?.availableLightVersion;

  return (<>
    <div className="pb-32">
      {hasUpdates ? (
        <div className="bg-blue-600 text-white py-4 text-center">
          <div className="font-bold uppercase text-sm">Actualización disponible</div>
          <div className="text-sm text-blue-100">Debe actualizar desde {Capacitor.getPlatform() === 'ios' ? 'la AppStore' : 'Google PlayStore'}.</div>
          <a href={config.googlePlayLink} target="_blank" rel="noreferrer" className="mt-2 px-4 py-1 rounded-full bg-white text-blue-600 text-xs">Hacé click aquí</a>
        </div>
      ) : null}
      <IonList className="mx-auto max-w-xs pt-10">
        <IonItemGroup>
          {institutionDoc?.data?.available && user?.userDoc?.data?.institutionApprovedDate && isAllowed('carts', ['create']) ? (<>
            <IonButton routerLink="/admin/cart/add" routerDirection="forward" color="primary" size="large" shape="round" expand="full" disabled={hasUpdates}>
              <IonIcon icon={restaurant} className="text-lg" />
              <IonLabel className="ml-2">
                Armar menú
              </IonLabel>
            </IonButton>
            {hasUpdates ? (
              <div className="text-center">
                <span className="uppercase text-xs tracking-wider font-semibold text-brand-red">Requiere actualización</span>
              </div>
            ) : null}
          </>) : null}
          {!user?.userDoc?.data?.institutionApprovedDate && !user?.userDoc?.data?.institutionBloquedDate ? (
            <div className="flex items-center p-4 mb-4 text-sm text-amber-900 border border-yellow-500 rounded-lg bg-yellow-100" role="alert">
              <IonIcon icon={informationCircle} className="text-xl w-10 mr-3" />
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">Esperando aprobación</span> 
                <br />
                En breve podrás armar tu primer ménu.
              </div>
            </div>
          ) : null}
          {user?.userDoc?.data?.institutionBloquedDate ? (
            <div className="flex items-center p-4 mb-4 text-sm text-brand-red border border-brand-red rounded-lg bg-red-50" role="alert">
              <IonIcon icon={ban} className="text-xl w-10 mr-3" />
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">Acceso rechazado</span> 
                <br />
                Comunícate con tu Institución o solicitá acceso a otra, desde <Link to="/admin/user/data" className="underline">editar perfil</Link>.
              </div>
            </div>
          ) : null}
          {institutionDoc?.data && !institutionDoc?.data?.available ? (
            <div className="flex items-center p-4 mb-4 text-sm text-brand-red border border-brand-red rounded-lg bg-red-50" role="alert">
              <IonIcon icon={ban} className="text-xl w-10 mr-3" />
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">Institución bloqueada</span>                
              </div>
            </div>
          ) : null}
        </IonItemGroup>

        {/* <h2 className="mt-8 text-black text-xs uppercase text-center">
          Administración
        </h2> */}

        <IonItemGroup className="mt-5 space-y-5">
          {isAllowed('gastronomicPlanningItemsOptions', ['list']) ? (
            <IonButton routerLink="/admin/gastronomicDailyCart/planning" routerDirection="forward" className="!text-yellow-600" size="default" fill="clear" shape="default" expand="block">
              <IonLabel className="ml-2">
                Planificación del menú
              </IonLabel>
            </IonButton>
          ) : null}

          {isAllowed('customerBillings', ['create']) ? (
            <IonButton routerLink="/admin/customerBilling/list" routerDirection="forward" className="!text-yellow-600" size="default" fill="clear" shape="default" expand="block">
              <IonLabel className="ml-2">
                Cobros
              </IonLabel>
            </IonButton>
          ) : null}

          {isAllowed('carts', ['materialsByDay']) ? (
            <IonButton routerLink="/admin/cart/materialsByDay" routerDirection="forward" className="!text-yellow-600" size="default" fill="clear" shape="default" expand="block">
              <IonLabel className="ml-2">
                Producción
              </IonLabel>
            </IonButton>
          ) : null}

          {isAllowed('delivery', ['history']) ? (
            <IonButton routerLink="/admin/delivery/history" routerDirection="forward" className="!text-yellow-600" size="default" fill="clear" shape="default" expand="block">
              <IonLabel className="ml-2">
                Envíos
              </IonLabel>
            </IonButton>
          ) : null}

          {isAllowed('carts', ['history']) ? (
            <IonButton routerLink="/admin/cart/history" routerDirection="forward" color="medium" size="default" fill="clear" shape="default" expand="block">
              <IonLabel className="ml-2">
                Pedidos
              </IonLabel>
            </IonButton>
          ) : null}

          {/* <h2 className="mt-8 text-black text-xs uppercase text-center">
            Encargados de Institutionces
          </h2> */}

          {isAllowed('institutions', ['group:approbe']) ? (
            <IonButton routerLink="/admin/institution/groupApprobe" routerDirection="forward" color="medium" size="default" fill="clear" shape="default" expand="block">
              <IonLabel className="ml-2">
                Miembros de la Institución
              </IonLabel>
            </IonButton>
          ) : null}

          {isAllowed('customerBillings', ['group:list']) ? (
            <IonButton routerLink="/admin/customerBilling/groupList" routerDirection="forward" color="medium" size="default" fill="clear" shape="default" expand="block">
              <IonLabel className="ml-2">
                Pagos de la Institutición
              </IonLabel>
            </IonButton>
          ) : null}

          {/* <h2 className="mt-8 text-black text-xs uppercase text-center">
            Conductores
          </h2> */}

          {isAllowed('delivery', ['pendingOrders']) ? (
             <IonButton routerLink="/admin/delivery/pendingOrders" routerDirection="forward" color="medium" size="default" fill="clear" shape="default" expand="block">
              <IonLabel className="ml-2">
                Envíos asignados
              </IonLabel>
            </IonButton>
          ) : null}
        </IonItemGroup>
      </IonList>

      <div className="mt-24 w-full">
        <ContactBTN label="Escribínos" message="Hola WorkFood!" />
      </div>
    </div>
  </>
  );
};