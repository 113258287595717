import _ from 'lodash';
import { createContext, useContext, useEffect, useState } from "react";
import { useVerifyVersion } from './useVerifyVersion';
import { useNotifications } from './useNotifications';
// import { useContacts } from './useContacts';

const panelContext = createContext();

export const usePanel = () => {
  const context = useContext(panelContext);
  if (!context) throw new Error("There is no Panel provider");
  return context;
};

export function PanelProvider({ children }) {
  const [isUserMenuPopoverOpen, setIsUserMenuPopoverOpen] = useState(false);

  const versionSpects = useVerifyVersion();
  let versionCurrent = versionSpects?.versionCurrent;
  useNotifications();
  // useContacts();

  useEffect(() => {
    // do nothing only refresh
  }, [
    versionSpects?.versionList,
    versionSpects?.availableLightVersion,
    versionSpects?.availableHardVersion
  ]);

  return (
    <panelContext.Provider
      value={{
        versionCurrent, versionSpects,
        isUserMenuPopoverOpen, setIsUserMenuPopoverOpen
      }}
    >
      {children}
    </panelContext.Provider>
  );
}
