export default function ({ setPermissions }) {
  return {
    name: 'Entity',
    slug: 'entity',
    permissions: [
      setPermissions({ slug: 'panel', label: 'Panel', type: 'system', actions: ['lists', 'config'] }),
      setPermissions({ slug: 'entities', label: 'Entities', type: 'system' }),
      setPermissions({ slug: 'taxonomyTypes', label: 'TaxonomyTypes', type: 'system' }),
      setPermissions({ slug: 'filterMenu', label: 'FilterMenu', type: 'system' })
    ]
  };
};