import Model from '../../libs/ModelClass';

import text from './text';
import textArea from './textArea';
import select from './select';
import multiselect from './multiselect';
import boolean from './boolean';
import number from './number';
import numberId from './numberId';
import date from './date';
import gps from './gps';
import gallery from './gallery';
import selectOneEntityDocument from './selectOneEntityDocument';
import coded from './coded';
import { getGeofire } from '../../firebase';
import relatedListMaterials from './relatedListMaterials';

const typeOptions = [
  { key: 'text', name: 'Texto' },
  { key: 'textArea', name: 'Texto extenso' },
  { key: 'select', name: 'Selección' },
  { key: 'multiselect', name: 'Selección múltiple' },
  { key: 'boolean', name: 'Booleano' },
  { key: 'number', name: 'Número' },
  { key: 'numberId', name: 'Número ID' },
  { key: 'date', name: 'Fecha' },
  { key: 'gps', name: 'GPS' },
  { key: 'gallery', name: 'Galería de Imágenes' },
  { key: 'selectOneEntityDocument', name: 'Seleccionar documento' },
  { key: 'coded', name: 'Codificado' },
  { key: 'relatedListMaterials', name: 'Lista relacionada de Materias primas' }
];

const typeConfigs = {
  text,
  textArea,
  select,
  multiselect,
  boolean,
  number,
  numberId,
  date,
  gps,
  gallery,
  selectOneEntityDocument,
  coded,
  relatedListMaterials
};

// Listeners for all entities events
const events = {
  beforeSave,
  afterSave,
};

export {
  typeOptions,
  typeConfigs,
  events,
};

// Parts for each DataType {
//   Render: ({ fieldName, taxonomyType, overrideParams, fieldsRequired }) => (FormField),
//   RenderFilter: (props) => (null),
//   RenderFilterParam: (props) => (null),
//   valueFormatter: (value) => (formatedValue),
//   RenderInputParams: ({ values, param }) => (null),
//   RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
//   RenderStyleParams: ({ values }) => (null),
//   RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (null),
//   getDefaultTaxonomyTypeDesign: () => ('default')
// }
export const DataTypePart = (field, props) => {
  const typeConfig = typeConfigs[props?.taxonomyType?.type];
  
  if (!typeConfig || !typeConfig[field]) {
    return null;
  }

  return typeConfig[field](props);
};

async function beforeSave ({ entitySlug, taxonomyTypesDocList }, formValues) {
  // Number ID
  for (const taxonomyType of taxonomyTypesDocList) {
    if (taxonomyType.data?.type === 'numberId') {
      let nameSlug = taxonomyType.data.nameSlug;
      const ExtendedModel = Model.extend(entitySlug);
      let docCount = await ExtendedModel.count();
      if ( !formValues[nameSlug] || formValues[nameSlug] < 0 ) {
        formValues[nameSlug] = docCount + 1;
      }
    }
    // GPS coordinates
    if (taxonomyType.data?.type === 'gps') {
      const geofire = getGeofire();
      let nameSlug = taxonomyType.data.nameSlug;
      if ( formValues[nameSlug] ) {
        const { lat, lng } = formValues[nameSlug];
        formValues[nameSlug + '_hash'] = geofire.geohashForLocation([lat, lng]);
      }
    }
  }
  return formValues;
};

async function afterSave ({ entitySlug, taxonomyTypesDocList }, itemDoc) {
  // for (const taxonomyType of taxonomyTypesDocList) {
  //   if (taxonomyType.data?.type === 'relatedListMaterials') {
  //     console.log('relatedListMaterials', itemDoc)
  //     let nameSlug = taxonomyType.data.nameSlug;
  //     if ( itemDoc[nameSlug] && itemDoc.id ) {
  //       // const {  } = itemDoc[nameSlug];
  //     }
  //   }
  // }
  return itemDoc;
};