import { FaInstagram,FaWhatsapp,FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function FooterBlock({ data }) {
    return (
    <footer id="footer" className="font-brand-montserrat" >
      <div className="py-8 lg:py-10 mx-6 bg-white"> 
        <div className="text-center container-width border-b border-neutral-300 py-2">
          <p>Encontranos en:</p>
          <div className="flex justify-center space-x-4 lg:space-x-6 my-2">
            <div className="text-neutral-700 border border-neutral-600 hover:border-brand-red  p-2 lg:p-3 cursor-pointer rounded-full">
              <a target='_blank' href="https://www.facebook.com" rel="noreferrer"><FaFacebook size={21}/></a>
            </div>
            <div className="text-neutral-700 border border-neutral-600 hover:border-brand-red p-2 lg:p-3 cursor-pointer rounded-full">
              <a target='_blank' href="https://www.instagram.com" rel="noreferrer"><FaInstagram size={21}/></a>
            </div>
            <div className="text-neutral-700 border border-neutral-600 hover:border-brand-red p-2 lg:p-3 cursor-pointer rounded-full">
              <a target='_blank' href="https://api.whatsapp.com/send?phone=59590000011&text=%C2%A1Hola%20Work%20Food!." rel="noreferrer"><FaWhatsapp size={21}/></a>
            </div>
          </div>
        </div>
        <div className="text-center pt-4 pb-2 lg:pb-0">
          <Link to="/politica-de-privacidad">Política de privacidad</Link> - <Link to="/seguridad-de-datos">Seguridad de datos</Link>
          <br />
          <Link to="/login">Iniciar sesión</Link>
        </div>
        {/* cta */}
        <div className="relative z-10 container-width text-center">
          <p className="text-black">
            {data.footerTitle}
          </p>
        </div>
      </div>
    </footer>
    )
  }