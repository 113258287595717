import PartOfModule from '../Module/PartOfModule';

import {
  FormFieldCustom
} from '../Form';

export default {
  Render(props) {
    const {
      entitySlug,
      fieldName,
      taxonomyType,
      overrideParams = {},
      fieldsRequired
    } = props;
    const taxonomyTypeData = taxonomyType?.data || taxonomyType;
    const title = taxonomyTypeData.name;
    const _fieldName = fieldName || taxonomyTypeData.nameSlug;
    const param = { ...taxonomyTypeData?.param, ...overrideParams };

    return (
      <FormFieldCustom
        name={_fieldName}
        title={title}
        placeholder={title}
        fieldsRequired={fieldsRequired}
        Render={({ value, onChange }) => (
          <PartOfModule
            type="codedDataType"
            fieldName={_fieldName}
            action="RenderForm"
            entitySlug={entitySlug}
            param={{ value, onChange, ctx: { title, taxonomyTypeData, param, props } }} 
          />
        )}
        {...param}
        {...props}
      />
    );
  },

  RenderInputParams: ({ values, param }) => (null),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderStyleParams: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (null),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}