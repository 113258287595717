import { useModule } from '../context/ModuleContext';
import { getEntityFullBySlug } from '../modules/entity/Entity';
import Model from './ModelClass';


export default class ModuleModel extends Model {

  static extend ( entitySlug, moduleLibs ) {
    const ExtendedModel = super.extend( entitySlug );
    ExtendedModel.moduleLibs = moduleLibs;
    return ExtendedModel;
  }

  static async create(data) {
    const entitySlug = this.collectionName;
    const { taxonomyTypesDocList } = await getEntityFullBySlug({ entitySlug, includeFilters: false });
    const modifiedDataValues = await this.moduleLibs.fireEventReducer('beforeSave', { entitySlug, taxonomyTypesDocList }, data);
    return await super.create(modifiedDataValues);
  }

  // static async createMany(data) {}
  // static async createWithId(data, id) {}
  // static async createOrUpdate(data) {}
  // static async saveSort() {}

  // async save() {}
  // async delete() {}
  // async deleteField(fieldName) {}
}


export const useModuleModels = () => {
  const moduleLibs = useModule();

  return {
    ...moduleLibs,

    ModuleModel: (param) => {
      if (param instanceof Model) {
        param.moduleLibs = moduleLibs;
        return param;
      }
      return ModuleModel.extend( param, moduleLibs );
    }
  };
}