import { useEffect } from "react";
import { useLocation, Route, Redirect, Switch } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { IonApp, IonMenu, IonRedirect, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import "dayjs/locale/es";

import { ModuleProvider } from "./context/ModuleContext";
import { AuthProvider, useAuth } from "./modules/user/AuthContext";

import { PublicHome } from "./routes/PublicHome";

import { Install } from "./routes/Install";
import { Login } from "./modules/user/Login";
// import { Register } from "./routes/Register";

import { AdminHome } from "./routes/AdminHome";

import { AdminEntityCrud } from "./routes/AdminEntityCrud";
import { AdminEntityCrudForm } from "./routes/AdminEntityCrudForm";
import { AdminEntityCrudShow } from "./routes/AdminEntityCrudShow";
import { AdminEntityCreator } from "./routes/AdminEntityCreator";
// import { AdminEntityCreatorShow } from "./routes/AdminEntityCreatorShow";
import { AdminEntityCreatorForm } from "./routes/AdminEntityCreatorForm";
// import { AdminEntityCreatorTaxonomy } from "./routes/AdminEntityCreatorTaxonomy";
// import { AdminEntityCreatorFilterMenu } from "./routes/AdminEntityCreatorFilterMenu";

import { AdminOutstandingProducts } from "./routes/AdminOutstandingProducts";
// import { FilterUrlSection } from "./routes/FilterUrlSection";
// import { ItemShow } from "./routes/ItemShow";

import { PublicModulePage } from "./routes/PublicModulePage";
import { AdminModulePage } from "./routes/AdminModulePage";

// import { AdminProducts } from "./routes/AdminProducts";
// import { AdminCarts } from "./routes/AdminCarts";
// import { AdminBrand } from "./routes/AdminBrand";
// import { CartShow } from "./routes/CartShow";
// import { ProductsShow } from "./routes/ProductsShow";

import { addLogEvent } from "./firebase";
import { CartProvider } from "./modules/cart/CartContext";
import { AdminUserCustomerData } from "./modules/user/AdminUserCustomerData";
import { AdminUserSetCustomerData } from "./modules/user/AdminUserSetCustomerData";
import { Capacitor } from "@capacitor/core";
import { MenuMainContent } from "./components/MenuMainContent";
import { PanelProvider } from "./modules/panel/PanelContext";
import { AdminTabConfig } from "./routes/AdminTabConfig";
import { AdminTabEntity } from "./routes/AdminTabEntity";

import {
  cogOutline,
  cogSharp,
  readerOutline,
  readerSharp,
  fileTrayStackedOutline,
  fileTrayStackedSharp
} from 'ionicons/icons';
import { PoliticaPrivacidad } from "./modules/public/PoliticaPrivacidad";
import { SeguridadDeDatos } from "./modules/public/SeguridadDeDatos";

setupIonicReact();

dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(utc);
dayjs.extend(localizedFormat)
dayjs.locale('es');
dayjs.tz.setDefault();

function LogLocation ({ children }) {
  let location = useLocation();
  
  // log page
  useEffect(() => {
    setTimeout(() => {
      addLogEvent('page_view', {
        // page_location: location.href,
        page_path: location.pathname,
        page_title: document.title,
      }); // analytics
    }, 1000);
  }, [location]);

  return children;
}

const appPages = [
  {
    title: 'Panel',
    url: '/admin',
    iosIcon: readerOutline,
    mdIcon: readerSharp
  },
  // {
  //   title: 'Outbox',
  //   url: '/page/Outbox',
  //   iosIcon: heartOutline,
  //   mdIcon: heartSharp,
  //   permissions: { resource: 'panel', actions: [''] }
  // },
  {
    title: 'Listados',
    url: '/admin/entity',
    iosIcon: fileTrayStackedOutline,
    mdIcon: fileTrayStackedSharp,
    permissions: { resource: 'panel', actions: ['lists'] }
  },
  {
    title: 'Configuraciones',
    url: '/admin/config',
    iosIcon: cogOutline,
    mdIcon: cogSharp,
    permissions: { resource: 'panel', actions: ['config'] }
  },
  
];

function AdminPanel (props) {
  const { history } = props;
  const { user } = useAuth();
  
  useEffect(() => {
    if (user && !user?.userDoc?.data) {
      if (Capacitor.isNativePlatform()) {
        history.replace('/login');
      } else {
        history.replace('/');
      }
    }
  }, [user]);

  // TODO kickout based on appPage's permissions

  return (
    <PanelProvider>
      <IonSplitPane contentId="main">
        {/* Menu */}
        <IonMenu type="overlay" contentId="main" side="start" menuId="main" className={`md:z-10 md:border-r md:border-gray-200 ${user?.userDoc?.data?.institutionId ? '' : 'hidden'}`}>
          <MenuMainContent triggerId="main" history={props.history} appPages={appPages} />
        </IonMenu>

        <IonRouterOutlet id="main">
          <Switch>
            {/* Panel main */}
            <Route path="/admin" exact component={AdminHome}/>

            {/* TAB Config */}
            <Route path="/admin/config">
              <IonRouterOutlet>
                <Switch>
                  <Route path="/admin/config" exact component={AdminTabConfig}/>
                  {/* Entity Creator */}
                  <Route path="/admin/config/entity-creator-list" component={AdminEntityCreator}/>
                  <Route path="/admin/config/entity-creator/:entityId/form" component={AdminEntityCreatorForm}/>
                  {/* <Route path="/admin/config/entity-creator/:entityId/show" component={AdminEntityCreatorShow}/> */}
                  {/* <Route path="/admin/config/entity-creator/:entityId/taxonomy" component={AdminEntityCreatorTaxonomy}/>
                  <Route path="/admin/config/entity-creator/:entityId/filterMenu" component={AdminEntityCreatorFilterMenu}/> */}
                </Switch>
              </IonRouterOutlet>
            </Route>

            {/* User */}
            <Route path="/admin/user">
              <IonRouterOutlet>
                <Route path="/admin/user/update-data" component={AdminUserSetCustomerData}/>
                <Route path="/admin/user/set-data" render={props => 
                    <AdminUserSetCustomerData 
                      {...props} 
                      mode="register"
                      showGoBack={false}
                      showMenu={false}
                      showGoToProfile={false}
                    />
                  }
                />
                <Route path="/admin/user/data" component={AdminUserCustomerData}/>
                {/* <Route path="/admin/user/profile" component={AdminUserProfile}/> */}
              </IonRouterOutlet>
            </Route>

            {/* Entity */}
            {/* Entity Crud */}
            <Route path="/admin/entity">
              <IonRouterOutlet>
                <Switch>
                  <Route path="/admin/entity/:entityId/:docId/form" exact component={AdminEntityCrudForm}/>
                  <Route path="/admin/entity/:entityId/:docId" exact component={AdminEntityCrudShow}/>
                  <Route path="/admin/entity/:entityId" exact component={AdminEntityCrud}/>
                  <Route path="/admin/entity" exact component={AdminTabEntity} />
                </Switch>
              </IonRouterOutlet>
            </Route>

            {/* Module custom routes */}
            <Route path="/admin/:entitySlug/:action">
              <IonRouterOutlet>
                <Switch>
                  <Route path="/admin/:entitySlug/:action/*" exact component={AdminModulePage}/>            
                  <Route path="/admin/:entitySlug/:action" exact component={AdminModulePage}/>
                </Switch>
              </IonRouterOutlet>
            </Route>

            {/* legacy */}
            <Route path="/admin/outstanding-products" component={AdminOutstandingProducts}/>
          </Switch>
        </IonRouterOutlet>
      </IonSplitPane>
    </PanelProvider>
  );
}

function App() {
  return (
    <div className="text-black h-screen flex font-brand-main">
      <IonApp>
        <AuthProvider>
        <ModuleProvider>
        <CartProvider>
        <LogLocation>
        <IonReactRouter>
          <IonRouterOutlet>
            <Switch>
              <Route name="home" path="/" exact render={props => {
                if (Capacitor.isNativePlatform()) {
                  return <Login {...props} />
                } else {
                  return <PublicHome {...props} />
                }
              }}/>
              
              <Route path="/politica-de-privacidad" component={PoliticaPrivacidad} />
              <Route path="/seguridad-de-datos" component={SeguridadDeDatos} />

              {/* <Route path="/inmuebles/:itemId" component={ItemShow}/> */}
              {/* <Route path="/filter/*" render={() => <FilterUrlSection defaultPath={"/filter"} />}/> */}
              
              <Route name="login" path="/login" component={Login} />
              {/* <Route path="/login-with-phone" component={LoginWithPhone} /> */}
              {/* <Route path="/register" component={<Register />} /> */}
              <Route path="/install" component={Install} />
              
              {/* Module custom routes */}
              <Route path="/m/:entitySlug/:action/*" component={PublicModulePage}/>
              
              <Route path="/admin" component={AdminPanel} />
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
        </LogLocation>
        </CartProvider>
        </ModuleProvider>
        </AuthProvider>
      </IonApp>
      <Toaster 
        toastOptions={{
          duration: 8000,
          position: "bottom-center",
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff'
          }
        }}
      />
      <div className="hidden">
        <div className="
          bg-gray-300 text-gray-300 bg-blue-700 ring-blue-700 border-blue-700 text-blue-700 bg-amber-600 ring-amber-600 border-amber-600 text-amber-600 bg-stone-400 text-stone-400
          opacity-80 cursor-not-allowed
        "></div>
      </div>
    </div>
  );
}

export default App;
