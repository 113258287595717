import React from 'react';

function Modal({ open, setOpen, title, children, classes }) {
  return (
    <div className={`fixed inset-0 z-50 ${open ? 'block' : 'hidden'}`}>
      <div className="flex items-start pt-44 justify-center min-h-screen">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className={`bg-white rounded-lg shadow-lg relative flex flex-col ${classes?.cardContainer}`}>
          <div className="p-3 flex justify-between items-center border-b border-gray-300">
            <h2 className="text-lg font-semibold">{title}</h2>
            <button
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={() => setOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-6 h-6"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="p-3 overflow-x-hidden overflow-y-auto max-h-full">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
