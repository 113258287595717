import { memo, useEffect, useState } from "react";
import _ from 'lodash';
import toast from "react-hot-toast";
import dayjs from 'dayjs';
import { DayPicker } from 'react-day-picker';
import { es } from 'date-fns/locale';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import Model, { GetOneModel } from "../../libs/ModelClass";
import BtnLoading from "../../components/ui/BtnLoading";
import { InstitutionSelector } from "../institution/InstitutionSelector";
import { BillingProductsCardWithTabs, useBillingDetails } from "./BillingProductsCard";
import ModalAlert from "../../components/ui/ModalAlert";
import { priceFormat } from "../../libs/utils";

const BillingModel = Model.extend('customerBillings');


export const RouteCustomerBillingForm = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {  
  const [ dateSetted, setDate ] = useState();
  const [ month, setMonth ] = useState(dateSetted);
  const [ selectedInstitution, setSelectedInstitution ] = useState();
  const [ minDate, setMinDate ] = useState();
  const [ showPaidConfirmation, setShowPaidConfirmation ] = useState(false);
  const billingsDetails = useBillingDetails({ 
    institutionDoc: selectedInstitution,
    dateFrom: dateSetted?.from,
    dateTo: dateSetted?.to,
  });

  const isEditing = parsedParams?.id !== 'new';

  useEffect(() => {
    if (isEditing) {
      fetchDoc();
    }
    if (parsedParams?.minDate) {
      let lastDate = dayjs(parsedParams.minDate).add(1, 'day').toDate(); // local time to use as input
      setMinDate(parsedParams.minDate);
      setDate(lastDate);
      setMonth(lastDate);
      setDate({ from: lastDate, to: dayjs().subtract(1, 'day').toDate() });
    } else {
      const today = dayjs().toDate(); // local time to use as input
      setDate(today);
      setMonth(today);
    }
  }, []);

  const fetchDoc = async () => {
    const billingDoc = await GetOneModel('customerBillings', parsedParams?.id);
    if (billingDoc?.data?.institutionId) {
      const institutionDoc = await GetOneModel('institutions', billingDoc.data.institutionId);
      setSelectedInstitution(institutionDoc);
      setDate({
        from: new Date(billingDoc.data.dateFrom),
        to: new Date(billingDoc.data.dateTo)
      });
      setMonth(new Date(billingDoc.data.dateFrom));
    }
  };

  const calcHiddenDay = (date) => {
    if (parsedParams.minDate) {
      return dayjs(date).isBefore(minDate);
    }
  };

  const handleSave = async () => {
    if (!verifyData()) { return; }
    try {
      const data = {
        institutionId: selectedInstitution.id,
        total: billingsDetails?.productsOfRange?.totalTotal,
        dateFrom: dayjs(dateSetted.from).toISOString(),
        dateTo: dayjs(dateSetted.to).toISOString(),
        isPaid: false,
        paidDate: null
      };
      if (isEditing) {
        data.id = parsedParams?.id;
      }
      const newDoc= await BillingModel.createOrUpdate(data);
      history.push(`/admin/customerBilling/show/id/${newDoc.id}`);
    } catch (error) {
      toast.error('Ocurrió un error: ' + error.message);
      console.error(error);
    }
  };

  const verifyData = () => {
    if (!selectedInstitution) {
      toast.error('Debe seleccionar una Institución');
      return false;
    }
    if (!dateSetted?.from || !dateSetted?.to) {
      toast.error('Debe especificar un rato de fechas');
      return false;
    }
    return true;
  };

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin/customerBilling/list`}
      title="Nueva órden de cobro"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        url: '/admin/customerBilling/list',
        title: 'Cobros a Clientes'
      }, {
        title: 'Agregar'
      }]}
    >
      <div className="ion-padding pb-32">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="md:w-1/2">
            <InstitutionSelector title="Selecciona Institución" {...{ selectedInstitution, setSelectedInstitution, selectedId: parsedParams?.institutionId }} />
          </div>

          <div className="md:w-1/2">
            <h2 className="text-sm font-semibold">Selecciona el rango de fechas</h2>
            <div className="border border-gray-200 rounded-md p-2">
              <DayPicker
                mode="range"
                month={month}
                onMonthChange={setMonth}
                selected={dateSetted}
                onSelect={setDate}
                className="justify-center"
                locale={es}
                hidden={calcHiddenDay}
                modifiersClassNames={{
                  day_selected: 'bg-brand-red'
                }}  
              />
            </div>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-gray-300"></div>

        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <div>
            <BtnLoading
              label={isEditing ? "Actualizar órden de cobro" : "Crear órden de cobro"}
              onClick={() => setShowPaidConfirmation(true)}
              withLoading
              disabled={!dateSetted}
              colorClass="text-white"
              className="px-3 py-1 text-sm text-white hover:scale-105 bg-brand-red shadow-none"
            />
          </div>
          <div>
            <h2 className="mb-1 text-xs font-semibold">Total de la Institución</h2>
            <div className="text-sm text-lime-600">
              {billingsDetails?.productsOfRange?.totalTotal ? priceFormat(billingsDetails?.productsOfRange?.totalTotal) : null}
            </div>
          </div>
          <div>
            <h2 className="mb-1 text-xs font-semibold text-black">Periodo</h2>
            <div className="text-sm text-black">
              Desde: {dayjs(dateSetted?.from).format('DD-MM-YYYY')}
              <br />
              Hasta: {dayjs(dateSetted?.to).format('DD-MM-YYYY')}
            </div>
          </div>
        </div>

        <BillingProductsCardWithTabs {...billingsDetails} />
      </div>
      {showPaidConfirmation && (
        <ModalAlert
          text={isEditing ? "¿Actualizar órden de cobro?" : "¿Registrar órden de cobro?"}
          onConfirm={handleSave}
          onCancel={() => setShowPaidConfirmation(false)}
        />
      )}
    </LayoutAdmin>
  );
};