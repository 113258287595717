import _ from "lodash";
// import { LayoutPublic } from "../components/LayoutPublic";
// import ContactBTN from "../components/ContactBTN";
import HeaderHeroBlock from "../components/Blocks/HeaderHero";
// import TextGridWithTitleBlock from "../components/Blocks/TextGridWithTitle";
// import CtaAccordionBlock from "../components/Blocks/CtaAccordion";
import SuperOutstandingProduct from "../components/Blocks/SuperOutstandingProduct";
import GridOutstandingProductsBlock from "../components/Blocks/GridOutstandingProducts";
// import CtaContactUsBlock from "../components/Blocks/CtaContactUs";
// import CustomerReviewsBlock from "../components/Blocks/CustomerReviews";
import CtaBenefitsBlock from "../components/Blocks/CtaBenefits";
// import FrecuentAnswersBlock from "../components/Blocks/FrecuentAnswers";
import CtaDownloadBlock from "../components/Blocks/CtaDownloadBlock";
import FooterBlock from "../components/Blocks/FooterBlock";
import { blocks } from "../stringsMap";
import { Link as ScrollLink } from 'react-scroll';
import { LayoutHome } from "../components/LayoutHome";
import { IonContent, IonPage } from "@ionic/react";

const HeaderMenu = ({ menuHeight }) => (<>
  <ScrollLink
    className="pl-10 sm:pl-2 sm md:px-3 text-black hover:text-brand-yellow md:font-medium text-sm md:text-base  cursor-pointer"
    to="inicio"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Inicio
  </ScrollLink>
  <ScrollLink
    className="pl-10 sm:pl-2 md:px-3 text-black hover:text-brand-yellow md:font-medium text-sm md:text-base cursor-pointer"
    to="beneficios"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Servicios
  </ScrollLink>
  <ScrollLink
    className="pl-10 sm:pl-2 md:px-3 text-black hover:text-brand-yellow md:font-medium text-sm md:text-base cursor-pointer"
    to="descarga"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Descarga
  </ScrollLink>
  <ScrollLink
    className="pl-10 sm:pl-2 md:px-3 text-black hover:text-brand-yellow md:font-medium text-sm md:text-base cursor-pointer"
    to="footer"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Contactos
  </ScrollLink>
</>)

export function PublicHome (props) {  
  return (
    <IonPage id="main">
      <IonContent className="">
        <LayoutHome mainSectionClass="px-0" HeaderMenu={HeaderMenu} >
          {/* Header Hero */}
          <HeaderHeroBlock
            data={blocks.headerHero}
            UnderText={() => (
              <div className="hidden lg:block px-4 lg:pt-10">
                <CtaDownloadBlock data={blocks.ctaDownload} {...props} classes={{ blockContainer: 'px-4 py-6 bg-brand-red rounded-md' }} />
              </div> 
            )}
          />
          
          {/* <TextGridWithTitleBlock data={blocks.textGridWithTitle} /> */}

          {/* <SuperOutstandingProduct data={blocks.mainOutstandingProduct} /> */}

          {/* <GridOutstandingProductsBlock data={blocks.gridOutstandingProducts} /> */}

          <div className="lg:hidden">
            <CtaDownloadBlock data={blocks.ctaDownload} {...props} classes={{ blockContainer: 'py-14 lg:py-20 px-4 bg-brand-red' }} />
          </div>

          <CtaBenefitsBlock data={blocks.ctaBenefits} />

          {/* <CtaContactUsBlock data={blocks.ctaContactUs} /> */}
          
          {/* 
          <CtaAccordionBlock data={blocks.ctaAccordion} />

          <CustomerReviewsBlock data={blocks.customerReviews} />

          <FrecuentAnswersBlock data={blocks.frecuentAnswers} /> 
          */}

          {/* Footer */}

          <CtaDownloadBlock data={blocks.ctaDownload} {...props} classes={{ blockContainer: 'py-14 lg:py-20 px-4 bg-brand-red' }} />

          <FooterBlock data={blocks.footerSection} />

          {/* <FooterMenuBlock data={blocks.menuFooter} /> */}

          {/* fixed footer 
          <footer className="footer fixed bottom-0 left-0 w-full z-50">
            <div className="flex flex-row-reverse place-content-between mx-auto mb-4 mr-4">
              <ContactBTN className={'ml-2 mb-1 lg:mb-2 bg-brand-dark'} />
            </div>
          </footer> */}
        </LayoutHome>
      </IonContent>
    </IonPage>
  );
}