import {
  FormFieldGPS,
  FormFieldCustom,
  RawInputGPSbyPlaces
} from '../Form';

import {
  // GPS
  FilterRenderGPS,
  FilterCreatorGPS,
} from '../Filters';

export default {
  Render(props) {
    const {
      fieldName,
      taxonomyType,
      overrideParams = {},
      fieldsRequired
    } = props;
    const taxonomyTypeData = taxonomyType?.data || taxonomyType;
    const title = taxonomyTypeData.name;
    const _fieldName = fieldName || taxonomyTypeData.nameSlug;
    const param = { ...taxonomyTypeData?.param, ...overrideParams };
    const style = param?.style;
    // param.disabled = taxonomyTypeData?.readOnly; // TODO
    
    return (
      <FormFieldGPS
        name={_fieldName}
        title={title}
        placeholder={title}
        fieldsRequired={fieldsRequired}
        {...param}
        {...style}
        {...props}
      />
    );
  },

  RenderFilter(props) {
    const {
      fieldName,
      taxonomyType,
      overrideParams = {},
      fieldsRequired
    } = props;
    const taxonomyTypeData = taxonomyType?.data || taxonomyType;
    const title = taxonomyTypeData.name;
    const _fieldName = fieldName || taxonomyTypeData.nameSlug;
    const param = { ...taxonomyTypeData?.param, ...overrideParams };
    const style = param?.style;

    return (
      <FormFieldCustom
        name={_fieldName}
        title={title}
        placeholder={title}
        fieldsRequired={fieldsRequired}
        Render={RawInputGPSbyPlaces}
        {...param}
        {...style}
        {...props}
      />
    )
  },

  RenderFilterParam(props) {
    return <FilterCreatorGPS {...props} />
  },

  RenderFilterPreview({ values }) {
    return <FilterRenderGPS values={values} DataTypeRender={this.Render} />
  },

  RenderInputParams: ({ values, param }) => (null),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderStyleParams: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (
    <FormFieldGPS {...formFieldProps} />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}