import {
  FormField,
  FormFieldDate,
  FormFieldSelect
} from '../Form';

import {
  // Date
  FilterRenderDate,
  FilterCreatorDate,
} from '../Filters';

const valueFormatter = (value) => {
  return new Date(value);
}

export default {
  Render(props) {
    const {
      fieldName,
      taxonomyType,
      overrideParams = {},
      fieldsRequired,
      isFilter
    } = props;
    const _taxonomyType = taxonomyType?.data || taxonomyType;
    const title = _taxonomyType?.name;
    // const design = _taxonomyType?.style?.design
    const _fieldName = fieldName || _taxonomyType?.nameSlug;
    let param = { ..._taxonomyType?.param, ...overrideParams, title, fieldsRequired, formatter: valueFormatter };
    const style = param?.style;
    param.disabled = isFilter ? false : _taxonomyType?.readOnly;
    if (props.paramsByFieldSlug && props.paramsByFieldSlug[_fieldName]) {
      param = {...param, ...props.paramsByFieldSlug[_fieldName]};
    }

    return (
      <FormFieldDate
        name={_fieldName}
        {...param}
        {...style}
        {...props}
      />
    );
  },
  
  RenderFilter(props) {
    return <FilterRenderDate {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorDate {...props} />
  },

  valueFormatter,

  RenderInputParams: ({ values, param }) => (
    <FormField name="param.value" title="Valor" type="date" />
  ),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (
    <>
      <FormFieldSelect
        name="show.date.operator"
        title="Operador"
        placeholder="Operador"
        options={[
          { value: 'eq', label: 'es igual' },
          { value: 'gt', label: 'es posterior' },
          { value: 'lt', label: 'es anterior' }
        ]}
      />
      {values.show?.date?.operator ? (
        <FormField
          type="date"
          name="show.date.value"
          title="Valor"
          placeholder="valor"
        />
      ) : ''}
    </>
  ),
  
  RenderStyleParams: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (
    <FormField {...formFieldProps} type="date" />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}