import { Link } from "react-router-dom";
import { getImageURL } from "../../libs/utils";

export const InsitutionDataCard = ({
  doc,
  showLink,
  classes
}) => {
  return (
    <div className={`p-1.5 border border-gray-300 rounded-md relative ${classes?.cardContainer}`}>
      <div className={`flex flex-row items-stretch w-full`}>
        {/* image xs */}
        <div className="w-[80px] h-[70px] rounded-md overflow-hidden">
          <img src={doc?.data?.images?.length && getImageURL(doc?.data.images[0], 'xs')}
            className="h-full w-full object-cover" 
          /> 
        </div>
        {/* data */}
        <div className="bg-white px-1.5 py-1.5 text-left leading-tight flex-1">
          {showLink ? (
            <Link to={`/admin/entity/institutions/${doc?.id}`} className="block text-sm font-semibold text-gray-900 underline">{doc?.data?.name}</Link>
          ) : (
            <div className="text-sm font-semibold text-gray-900">{doc?.data?.name}</div>
          )}
          <div className="mt-0.5 text-xs text-gray-500">{doc?.data?.location}</div>
          <div className="text-xs text-gray-500">{doc?.data?.city}</div>
        </div>
      </div>
    </div>
  );
}
