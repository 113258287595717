import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton
} from "@ionic/react";
import { 
  arrowBack
} from 'ionicons/icons';

export function LayoutPublic({
  Title,
  mainSectionClass,
  ToolbarLeft,
  defaultHref,
  forceBack = false,
  showGoBack = true,
  children,
  history
}) {
  return (
    <IonPage>
      {/* header */}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {showGoBack ? (
              forceBack ? (
                <IonButton onClick={()=> { history.push(defaultHref); }} >
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              ) : (
                <IonBackButton defaultHref={defaultHref}></IonBackButton>
              )
            ) : null}
            {ToolbarLeft ? (
              <ToolbarLeft />
            ) : null}
          </IonButtons>

          <IonTitle>
            <div className="header-title-logo">
              <div className="mx-auto flex flex-row items-center">
                <img src="/logo-icono-color.svg" className="h-8 md:h-12 mr-1" />
                <img src="/logo-texto-negro.svg" className="h-5 sm:h-8 justify-self-center" />
              </div>
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      {/* content */}
      <IonContent className="">
        {children}
      </IonContent>
    </IonPage>
  );
}