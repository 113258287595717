import { useMemo } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Model from "../libs/ModelClass";
import { useModule } from "../context/ModuleContext";
import { usePartOfModule } from "../components/Module/PartOfModule";
import { parseAttrValParams } from '../libs/utils';
import useBreakpoints from '../libs/useBreakpoints';
import config from "../config";
import Page404 from './Page404';
import { useAuth } from "../modules/user/AuthContext";


export function AdminModulePage({ history, match }) {
  const { isAllowed } = useModule();
  const { isMinBreakpointActive } = useBreakpoints();
  const location = useLocation();
  const { user } = useAuth();

  const { entitySlug, action, 0: attrValParams } = match.params;
  const parsedParams = useMemo(() => parseAttrValParams( attrValParams ), [location]);
  const routeOfModule = usePartOfModule({
    type: 'routeAdmin',
    entitySlug,
    action
  });
  
  return (
    isAllowed(routeOfModule?.permission?.resource, routeOfModule?.permission?.action) ? (
      (routeOfModule?.Content) ? (
        <routeOfModule.Content {...{ 
          parsedParams,
          entitySlug, 
          action,
          config,
          user,
          isAllowed, 
          Model,
          isMinBreakpointActive, 
          location, 
          history,
          match
         }} />)
      : (
        <Page404 />
      )
    ) : null
  );
}
