import _ from "lodash";
import { Link } from "react-router-dom";
import HeaderHeroBlock from "../../components/Blocks/HeaderHero";
import CtaDownloadBlock from "../../components/Blocks/CtaDownloadBlock";
import FooterBlock from "../../components/Blocks/FooterBlock";
import { blocks } from "../../stringsMap";
import { LayoutHome } from "../../components/LayoutHome";
import { IonContent, IonPage } from "@ionic/react";
import config from "../../config";
import { openWhatsApp } from "../../libs/utils";

const Content = () => (
  <div className="mt-8 md:mt-24 px-3 py-16 prose prose-xl max-w-lg mx-auto text-justify">

    <h1 className="text-2xl font-bold">Seguridad de Datos y Eliminación de Datos de Usuario de Work Food App</h1>
    <br />
    <h2 className="text-lg font-semibold mb-2">Fecha de entrada en vigor: 11-Noviembre-2023.</h2>
    <p className="mb-1.5">Nos tomamos en serio la seguridad de sus datos personales y hemos implementado medidas para protegerlos contra accesos no autorizados o cualquier forma de procesamiento indebido. Utilizamos protocolos de seguridad estándar de la industria al recopilar, almacenar y procesar información personal.</p>
    <br />
    <h2 className="text-lg font-semibold mb-2">Proceso de Eliminación de Datos</h2>
    <p className="mb-1.5">Si en algún momento desea que eliminemos sus datos de usuario de nuestra base de datos, puede hacerlo de la siguiente manera:</p>
    <p className="mb-1.5"><u>Contacto por WhatsApp:</u> Puede contactarnos a través de WhatsApp al [número de teléfono] para solicitar la eliminación de sus datos personales. Nuestro equipo de soporte estará encantado de ayudarle en este proceso.</p>
    <p className="mb-1.5">Al contactarnos, asegúrese de proporcionar la información necesaria para que podamos verificar su identidad y procesar su solicitud de manera segura y eficiente. Tenga en cuenta que la eliminación de datos puede estar sujeta a plazos y requisitos legales aplicables.</p>
    <br />
    <h2 className="text-lg font-semibold mb-2">Cambios en la Política de Privacidad</h2>
    <p className="mb-1.5">Esta Política de Privacidad puede actualizarse periódicamente para reflejar cambios en nuestras prácticas de recopilación y uso de datos. Le recomendamos que revise esta política con regularidad. Cualquier cambio significativo se notificará de manera destacada en nuestra aplicación o mediante otros medios de comunicación.</p>
    <br />
    <h2 className="text-lg font-semibold mb-2">Contacto</h2>
    <p className="mb-1.5">Si tiene alguna pregunta o inquietud sobre nuestra política de privacidad, la seguridad de datos o cualquier otro asunto relacionado, no dude en ponerse en contacto con nosotros a través de WhatsApp al <a className="underline" href={openWhatsApp({message: 'Hola WorkFood, solicito la eliminación de mis datos de usuario'}, true)}>{config?.number}</a> o por correo electrónico a <a className="underline" href={`mailto:${config?.email}`}>{config?.email}</a>.</p>
  </div>
);

const HeaderMenu = ({ menuHeight }) => (<>
  <Link
    className="pl-10 sm:pl-2 sm md:px-3 text-black hover:text-brand-yellow md:font-medium text-sm md:text-base  cursor-pointer"
    to="/"
  >
    Inicio
  </Link>
</>)

export function SeguridadDeDatos () {  
  return (
    <IonPage id="main">
      <IonContent className="">
        <LayoutHome mainSectionClass="px-0" HeaderMenu={HeaderMenu} >
          {/* Header Hero */}
        
          <Content />

          <CtaDownloadBlock data={blocks.ctaDownload} />

          <FooterBlock data={blocks.footerSection} />

          {/* <FooterMenuBlock data={blocks.menuFooter} /> */}

          {/* fixed footer 
          <footer className="footer fixed bottom-0 left-0 w-full z-50">
            <div className="flex flex-row-reverse place-content-between mx-auto mb-4 mr-4">
              <ContactBTN className={'ml-2 mb-1 lg:mb-2 bg-brand-dark'} />
            </div>
          </footer> */}
        </LayoutHome>
      </IonContent>
    </IonPage>
  );
}