import config from './config';

export const blocks = {
  headerHero: {
    mainTitle: "Simplifica tu día laboral con las mejores viandas empresariales",
    mainSubTitle: "Con Work Food accedé a platos deliciosos desde nuestra plataforma.", 
    backgroundImageUrl: "/comida-1.jpg"
  },
  textGridWithTitle: {
    items: [
      {
        title: "Variedad y personalización",
        content: "Ofrecemos una amplia variedad de platos y la posibilidad de personalizar las viandas."
      },
      {
        title: "Calidad y frescura",
        content: "Brindamos viandas de alta calidad, preparadas con ingredientes frescos."
      },
      {
        title: "Servicio eficiente",
        content: "Nos comprometemos a entregar las viandas en tiempo y forma."
      }
    ]
  },
  ctaAccordion: {
    title: "Descubre nuestros beneficios",
    subtitle: "",
    items: [
      {
        title: "Menús personalizados",
        content: "Diseñamos menús a medida para adaptarnos a los gustos y necesidades de tu empresa. Nos aseguramos de ofrecer opciones variadas y equilibradas.",
        ctaTitle: "Ver opciones",
        ctaType: "link",
        ctaUrl: "/menus-personalizados"
      },
      {
        title: "Entrega programada",
        content: "Planificamos las entregas de las viandas según tus horarios y necesidades. Nos adaptamos a los turnos de trabajo y garantizamos una entrega puntual y eficiente.",
        ctaTitle: "Contactar",
        ctaType: "link",
        ctaUrl: "/contacto"
      },
      {
        title: "Atención personalizada",
        content: "Nuestro equipo de atención al cliente está siempre dispuesto a ayudarte. Brindamos asesoramiento personalizado y resolvemos cualquier consulta o solicitud que tengas.",
        ctaTitle: "Contactar",
        ctaType: "link",
        ctaUrl: "/contacto"
      }
    ]
  },
  mainOutstandingProduct: {
    title: "Vianda destacada",
    subtitle: "Menú ejecutivo"
  },
  gridOutstandingProducts: {
    title: "Nuestras opciones",
    subtitle: "Descubre nuestros menús"
  },
  ctaBenefits: {
    firstBenefit: {
      title: "Almuerzos planificados a tu gusto",
      content: "Elegí tu menú semanal y disfruta de tus platos favoritos con anticipación.",
      backgroundImageUrl: "/planifica-tu-menu.jpg",
    },
    secondBenefit: {
      title: "¡Te premiamos con un plato gratis!",
      content: "Por cada cinco viandas pagadas por adelantado te enviamos 1 menú GRATIS en la siguiente semana.",
      backgroundImageUrl: "/comida-3.jpg",
    },
    thirdBenefit: {
      title: "Delivery sin costo",
      content: "Enviamos sin costo a tu empresa, arma tu grupo de cinco personas en adelante. Y empezamos..",
      backgroundImageUrl: "/delivery-moto.jpg",
    },
    fourthBenefit: {
      title: "Alimenta grandes equipos con facilidad",
      content: "Con nuestro servicio de pedidos para grupos extensos, simplificamos la organización de almuerzos. ¡Relájate mientras nos hacemos cargo de todo!",
      backgroundImageUrl: "/gente-comiendo-ejemplo.jpg",
      ctaTitle: "¡Contactar con un asesor!",
      ctaUrl: "/"
    },
  },
  ctaContactUs: {
    title: "Hacer pedidos para un grupo",
    content: "Si tienes alguna consulta o deseas hacer pedidos para un grupo, no dudes en contactarnos.",
    backgroundImageUrl: "/mesa-de-comida.jpg",
    ctaIconUrl: '/whatsapp.png',
    ctaTitle: "Whatsapp",
    ctaUrl: "/",
  },
  customerReviews: {
    title: "Opiniones de nuestros clientes",
    subtitle: "Descubre lo que dicen",
    content: "Nuestros clientes valoran nuestra comida deliciosa, el servicio eficiente y la atención que brindamos.",
    items: [
      {
        title: "Comida de calidad",
        content: "Las viandas de Work Food son siempre deliciosas y se nota la calidad de los ingredientes"
      },
      {
        title: "Servicio puntual",
        content: "Siempre recibimos nuestras viandas a tiempo, lo cual es fundamental para nuestro horario de trabajo."
      },
      {
        title: "Variedad y personalización",
        content: "Apreciamos que Work Food ofrezca opciones variadas. Es un servicio muy completo y flexible."
      },
      {
        title: "Atención al cliente excepcional",
        content: "El equipo de Work Food nos brinda una atención personalizada y siempre están dispuestos a ayudarnos."
      }
    ]
  },
  frecuentAnswers: {
    title: "Preguntas frecuentes",
    items: [
      {
        title: "¿Cuál es el costo de las viandas empresariales?",
        content: "El costo de las viandas empresariales puede variar según los menús elegidos y la cantidad de empleados a alimentar. Te invitamos a contactarnos para obtener un presupuesto personalizado."
      },
      {
        title: "¿Cómo se realizan los pedidos?",
        content: "Puedes realizar los pedidos de las viandas empresariales contactándonos por teléfono, correo electrónico o a través de nuestro sitio web. Nos pondremos en contacto contigo para coordinar los detalles."
      },
      {
        title: "¿Ofrecen opciones para diferentes dietas?",
        content: "Sí, en Work Food nos adaptamos a diferentes necesidades dietéticas. Contamos con opciones vegetarianas, veganas, sin gluten y otras, para garantizar que todos tus empleados puedan disfrutar de nuestras viandas."
      },
      {
        title:"¿Cuánto tiempo de antelación necesito para hacer un pedido?",
        content: "Recomendamos realizar los pedidos con al menos 48 horas de antelación para asegurarnos de tener tiempo suficiente para preparar y entregar las viandas de manera puntual."
      }
    ]
  },
  ctaDownload: {
    ctaTitle: "¡Accedé a la plataforma Ahora!",
    webCTAtitle: "Desde el navegador",
    appCTATitle: "Desde la aplicación",
    appStoreCTA: '/',
    playStoreCTA: '/',
  },
  footerSection:{
    facebookUrl:"/",
    instagramUrl:"/",
    whatsappMsg:"",
    footerTitle: "© 2023 Work Food - Todos los derechos reservados. Paraguay",
  }
};
