import { createContext, useContext, useState } from "react";
import { useCartData } from './useCartData';
const cartContext = createContext();

export const useCart = () => {
  const context = useContext(cartContext);
  if (!context) throw new Error("There is no Cart provider");
  return context;
};

export function CartProvider({ children }) {
  const [ isDetailsOpen, setIsDetailsOpen ] = useState(false);

  const cartData = useCartData();

  return (
    <cartContext.Provider
      value={{
        isDetailsOpen, 
        setIsDetailsOpen,
        cartData
      }}
    >
      {children}
    </cartContext.Provider>
  );
}
