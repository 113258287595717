import { Field } from 'react-final-form';
import _ from 'lodash';

import { ShowError } from './formUtils';
import dayjs from 'dayjs';
import { DayPicker } from 'react-day-picker';
import { es } from 'date-fns/locale';

const FormFieldDate = (props) => {
  let {
    name,
    title,
    placeholder,
    fieldsRequired = null,
    showLabel = true,
    showClearBtn = true,
    validate = null,
    disabled = false,
    formatter = (value) => value,
    minValue = null,
    maxValue = null,
    UnderInput = null,
    onChange = null,
    onBlur = null,
  } = props;

  const validateField = (value) => {
    let error;
    
    if (value !== undefined) {
      
    }
    
    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }
    
    return error;
  };

  
  return (
    <Field name={name} validate={(args) => validateField(args)}
      className={`pb-3 ${props?.classes?.fieldContainer || ''}`}
    >
      {({ input, meta }) => {
        const dateValue = dayjs(input.value || new Date()).utc().toDate();
        return (
          <>
            {showLabel ? (
              <label position="floating" className={props?.classes?.fieldLabel}>
                <span
                  className={`font-semibold mr-3 ${
                    meta.touched && meta.error ? 'text-red-600' : 'text-gray-900'
                  }`}
                >
                  {title}
                  {(meta.touched && meta.error) || (_.includes(fieldsRequired, name) && !input.value)
                    ? '*'
                    : ''}
                </span>
                {showClearBtn && input?.value ? (
                  <button
                    type="button"
                    className="px-1.5 py-0.5 font-regular text-xs bg-gray-100 text-gray-400 rounded-full hover:text-gray-800 inline-block"
                    onClick={() => input?.onChange(undefined)}
                  >
                    X
                  </button>
                ) : ''}
              </label>
            ) : ''}
            <div className="p-0.5 border border-gray-200 rounded-md">
              <DayPicker
                mode="single"
                defaultMonth={dateValue}
                selected={dateValue}
                onSelect={(newDate) => {
                  const newDateValue = dayjs(newDate).utc().toDate();
                  input.onChange(newDateValue); 
                  onChange && onChange(newDateValue);
                }}
                className="justify-center"
                locale={es}
                modifiersClassNames={{
                  day_selected: 'bg-brand-red'
                }}  
              />
            </div>
            {/* <input
              className={`border-gray-200 border h-[40px] px-2 py-1.5 rounded-md ${props?.classes?.fieldInput || ''}`}
              type={'date'}
              placeholder={placeholder || title}
              value={input.value ? dayjs(input.value).utc().format('YYYY-MM-DD') : ''}
              onChange={(e) => {
                const formatedValue = formatter(e.target.value);
                input.onChange(formatedValue); 
                onChange && onChange(formatedValue);
              }}
              onBlur={(e) => {input.onBlur(e); onBlur && onBlur(e)}}
              disabled={disabled}
              min={minValue}
              max={maxValue}
            /> */}
            {UnderInput && (<UnderInput input={input} />)}
            {/* error */}
            <ShowError name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} />
          </>
        );
      }}
    </Field>
  );
};

export default FormFieldDate;