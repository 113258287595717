import Model from '../../libs/ModelClass';
import { useState, useEffect } from 'react';

const FilterMenuModel = Model.extend('filterMenu');
const TaxonomyTypeModel = Model.extend('taxonomyTypes');

export default class Entity extends Model {
  static collectionName = 'entities';

  constructor(data) {
    super(data);
  }

  static getMainAttr(taxonomyTypesDocList) {
    return taxonomyTypesDocList
      ?.find(taxonomyType => taxonomyType?.data?.outstanding)
      ?.data?.nameSlug
      || 'name';
  }

  static getMainImgAttr(taxonomyTypesDocList) {
    return taxonomyTypesDocList
      ?.find(taxonomyType => taxonomyType?.data?.outstanding && taxonomyType?.data?.type === 'gallery')
      ?.data?.nameSlug;
  }
}

const nullData = {
  doc: null,
  mainAttr: null,
  mainImgAttr: null,
  fieldsRequired: [],
  entityDoc: null,
  filterMenuDocList: null,
  taxonomyTypesDocList: null,
  filterMenuTaxonomyTypes: null,
  EntityModel: Entity,
  FilterMenuModel,
  TaxonomyTypeModel
};

export const getEntitiesBlueprint = async () => {
  let allEntities = await Entity.getAll();
  allEntities = allEntities.filter(doc => !doc.data?.deleted);
  const entitiesBlueprints = {};
  for (const entityDoc of allEntities) {
    let filterMenuDocList = await FilterMenuModel.filterByAttributes({ entityId: entityDoc.id });
    filterMenuDocList = filterMenuDocList?.filter((doc) => doc.data?.deleted !== true);

    let taxonomyTypesDocList = await TaxonomyTypeModel.filterByAttributes({ entityId: entityDoc.id });
    taxonomyTypesDocList = taxonomyTypesDocList.filter((doc) => doc.data?.deleted !== true);
    taxonomyTypesDocList = Model.sortDocs(taxonomyTypesDocList, 'sort');

    entitiesBlueprints[ entityDoc?.data.nameSlug ] = {
      entity: entityDoc.data,
      filterMenuList: filterMenuDocList.map(doc => doc.data),
      taxonomyTypesList: taxonomyTypesDocList.map(doc => doc.data)
    };
  }
  
  return entitiesBlueprints;
};

export const getEntityFullBySlug = async ({
  docId,
  entityId,
  entitySlug,
  filterMenuSlug,
  includeFilters = true,
  onFetch
}) => {
  try {
    let entityDoc;
    if (entitySlug) {
      let records = await Entity.filterByAttributes({ nameSlug: entitySlug });
      records = records?.filter((doc) => doc.data?.deleted !== true);
      entityDoc = records[0];
    } 
    else if (entityId) {
      let doc = await Entity.findById(entityId);
      entityDoc = doc;
    }
    if (entityDoc) {
      let ExtendedModel = Model.extend(entityDoc?.data?.nameSlug);
      let taxonomyTypesDocList = await TaxonomyTypeModel.filterByAttributes({ entityId: entityDoc.id });
      taxonomyTypesDocList = taxonomyTypesDocList.filter((doc) => doc.data?.deleted !== true);
      taxonomyTypesDocList = Model.sortDocs(taxonomyTypesDocList, 'sort');
      
      const addFieldsRequired = [];
      taxonomyTypesDocList?.forEach((doc) => {
        if (doc.data.required && !doc.data.deleted) {
          addFieldsRequired.push(doc.data.nameSlug);
        }
      });
      
      const mainAttr = Entity.getMainAttr(taxonomyTypesDocList);
      const mainImgAttr = Entity.getMainImgAttr(taxonomyTypesDocList);
      
      let doc = null;
      if (docId) {
        doc = await ExtendedModel.findById(docId);
      }

      let filterMenuDocList = null;
      if (includeFilters) {
        filterMenuDocList = await FilterMenuModel.filterByAttributes({ entityId: entityDoc.id });
        filterMenuDocList = filterMenuDocList?.filter((doc) => doc.data?.deleted !== true);
      }

      let filterMenuTaxonomyTypes = null;
      if (filterMenuSlug) {
        filterMenuTaxonomyTypes = [];
        const filterMenuDoc = filterMenuDocList?.find((filter) => filter.data.nameSlug === filterMenuSlug);
        filterMenuDoc?.data?.filters?.forEach((filter) => {
          const taxonomyType = taxonomyTypesDocList?.find((taxonomyType) => taxonomyType.id === filter.id);
          filterMenuTaxonomyTypes.push({
            id: filter.id,
            filter,
            taxonomyType: taxonomyType?.data
          });
        });
      }

      onFetch && onFetch({ filterMenuDocList, taxonomyTypesDocList });
      
      return {
        doc,
        ExtendedModel,
        mainAttr,
        mainImgAttr,
        fieldsRequired: addFieldsRequired,

        entityDoc,
        filterMenuDocList,
        taxonomyTypesDocList,
        filterMenuTaxonomyTypes,
        
        EntityModel: Entity,
        FilterMenuModel,
        TaxonomyTypeModel
      };
    }

    return nullData;
  } catch (error) {
    console.log('Error fetching:', error);
    return nullData;
  }
};

export const useEntityFullBySlug = ({
  docId,
  entityId,
  entitySlug,
  filterMenuSlug,
  useEffectExtraWatch,
  onFetch
}) => {
  const [data, setData] = useState(nullData);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getEntityFullBySlug({
        docId,
        entityId,
        entitySlug,
        filterMenuSlug,
        includeFilters: !!filterMenuSlug,
        onFetch
      });
      setData(result);
    };
    fetchData();
  }, [docId, entitySlug, filterMenuSlug, useEffectExtraWatch]);

  return data;
};