import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GoogleMap, Marker, InfoWindow, useLoadScript, MarkerClusterer, DirectionsRenderer } from '@react-google-maps/api';
import config from '../../config';

const MapPlanningRoute = (props) => {
  const {
    placesPoints,
    placesSelected,
    optimizeWaypoints = false,
    setDirecctionsRendered,
    InfoWindowRender,
    classes,
    getPointPosition = (p) => p
  } = props;
  const center = useMemo(() => ({ lat: -23.442503, lng: -58.443832 }), []); // Centro de Paraguay
  const zoom = 6; // Nivel de zoom para mostrar todo Paraguay
  const mapRef = useRef();
  const [selectedPlacePoint, setSelectedPlacePoint] = useState(null);
  const [directions, setDirections] = useState();
  const onLoad = useCallback((map) => (mapRef.current = map), []);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapApiKey
  });
  const options = useMemo(
    () => ({
      // disableDefaultUI: false,
      clickableIcons: false,
      zoomControl: true,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      rotateControl: false,
      scaleControl: false,
    }),
    []
  );

  useEffect(() => {
    if (mapRef.current && placesPoints?.length > 0) {
      console.log('placesPoints', placesPoints)
      const bounds = new window.google.maps.LatLngBounds();
      placesPoints.forEach((point) => {
        if (getPointPosition(point)) {
          getPointPosition(point);
          bounds.extend(getPointPosition(point));
        }
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [mapRef.current, placesPoints]);

  useEffect(() => {
    window?.google?.maps && fetchDirections();
  }, [placesSelected, optimizeWaypoints]);

  const fetchDirections = () => {
    const startTravel = placesSelected[0];
    const endTravel = placesSelected[placesSelected.length - 1];
    const waypoints = placesSelected.slice(1, -1).map(place => {
      const { lat, lng } = getPointPosition(place);
      return {
        location: `${lat},${lng}`
      }
    });
    const service = new window.google.maps.DirectionsService();
    service.route(
      {
        origin: getPointPosition(startTravel),
        destination: getPointPosition(endTravel),
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
        optimizeWaypoints
      },
      (result, status) => {
        if (status === "OK" && result) {
          setDirections(result);
          setDirecctionsRendered(result);
        }
      }
    );
  };

  return (
    <div className={`relative ${classes?.mapContainer}`}>
      {isLoaded && !loadError && (
        <GoogleMap
          mapContainerClassName={`w-full rounded-md ${classes?.mapRender} ${classes?.mapHeight || 'h-48'}`}
          center={center}
          zoom={zoom}
          options={options}
          onLoad={onLoad}
        >
          {directions && placesSelected?.length > 1 && (
            <DirectionsRenderer
              directions={directions}
              options={{
                polylineOptions: {
                  zIndex: 200,
                  strokeColor: "#fb9314",
                  strokeWeight: 5,
                },
                markerOptions: {
                  visible: false
                }
              }}
            />
          )}
          <MarkerClusterer>
            {(clusterer) => (
              placesPoints?.map((point, index) => (
                !point?.assigned ? (
                  <Marker
                    key={index}
                    position={getPointPosition(point)}
                    clusterer={clusterer}
                    onClick={() => setSelectedPlacePoint(point)}
                  />
                ) : null
              ))
            )}
          </MarkerClusterer>
          {placesPoints?.map((point, index) => (
            point?.assigned ? (
              <Marker
                key={index}
                position={getPointPosition(point)}
                onClick={() => setSelectedPlacePoint(point)}
                icon={`${window.location.origin}/markers/number_${point.order + 1}.png`}
              />
            ) : null
          ))}
          {selectedPlacePoint && (
            <InfoWindow
              position={getPointPosition(selectedPlacePoint)}
              onCloseClick={() => setSelectedPlacePoint(null)}
              options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
            >
              {InfoWindowRender && <InfoWindowRender point={selectedPlacePoint} />}
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default MapPlanningRoute;
