import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { animated } from 'react-spring'
import { defaultProps } from '../../constants'
import { useSlider } from '../../hooks'
import Dots from '../Dots'
import Slide from '../Slide'
import SlideIndicator from '../SlideIndicator'
import Measure from 'react-measure'
import Lightbox from 'react-image-lightbox'
import {
  SliderContainer as StyledSliderContainer,
  Overlay as StyledOverlay,
  SlideOverlay as StyledSlideOverlay,
  Slider as StyledSlider,
  Button as StyledButton,
  ButtonContainer as StyledButtonContainer
} from './Slider.css'
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const AnimatedOverlay = animated(StyledOverlay)
const AnimatedSlider = animated(StyledSlider)

export default function Slider({
  initialSlide,
  slides,
  slidesData,
  slideOverlay,
  slideOverlayBottom,
  slideIndicatorTimeout,
  activeDotColor,
  dotColor,
  showButtons,
  onResize,
  children
}) {
  const [dimensions, setDimensions] = useState({width: -1, height: -1})
  const [isOpen, setIsOpen] = useState(false);
  const [lightboxSlideIndex, setLightboxSlideIndex] = useState(0);
  const [zooming, scale, currentSlide, bind, x, onScale, setSlide] = useSlider({
    slides,
    initialSlide,
    width: dimensions.width
  })

  // reset slide number
  useEffect(() => {
    if (!slides[currentSlide]) {
      setSlide(0)
    }
  }, [slides])

  const InnerSlider = (
    <>
      <Measure
        bounds
        onResize={contentRect => {
          setDimensions(contentRect.bounds)
          onResize(contentRect);
        }}
      >
        {({ measureRef }) => (
          <StyledSliderContainer zooming={zooming} className="zoom-slider"  ref={measureRef}>
            {zooming ? (
              <AnimatedOverlay
                style={{
                  backgroundColor: scale
                    .interpolate({ range: [1, 2, 10], output: [0, 0.7, 0.7] })
                    .interpolate(opacity => `rgba(0, 0, 0, ${opacity})`),
                }}
              />
            ) : ''}

            <StyledSlideOverlay inFront={!zooming}>
              {slideOverlay && slideOverlay(currentSlide)}
              <SlideIndicator
                slideIndicatorTimeout={slideIndicatorTimeout}
                currentSlide={currentSlide}
                totalSlides={slides.length}
              />
            </StyledSlideOverlay>

            <AnimatedSlider
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...bind()}
              style={{
                transform: x.interpolate(slideX => `translateX(${slideX}px`),
              }}
            >
              {slides.map((slide, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Slide onScale={onScale} key={idx} width={dimensions.width} zooming={zooming} isCurrent={idx === currentSlide}>
                  {slide}
                </Slide>
              ))}
            </AnimatedSlider>

            {showButtons && (
              <StyledButtonContainer inFront={!zooming}>
                {slides.length > 1 ? (
                  <StyledButton type="button" onClick={() => { setSlide((currentSlide + slidesData.length - 1) % slidesData.length) }}>
                    <svg style={{fill: 'currentColor', height: '1.25rem'}} viewBox="-95 0 512 512">
                      <path d="m253.550781 512c-.09375 0-.1875 0-.285156 0-16.136719-.074219-31.246094-6.457031-42.546875-17.972656l-193.515625-196.65625c-11.078125-11.285156-17.1835938-26.1875-17.203125-41.976563-.0195312-15.792969 6.042969-30.710937 17.074219-42.007812l192.421875-195.363281c11.292968-11.5625 26.492187-17.9882818 42.746094-18.023438 16.253906-.0117188 31.480468 6.320312 42.882812 17.898438l9.304688 9.445312c11.25 11.421875 17.378906 26.539062 17.257812 42.570312s-6.480469 31.054688-17.902344 42.304688l-146.152344 143.277344 42.105469 41.246094c7.894531 7.730468 8.023438 20.394531.292969 28.289062-7.730469 7.890625-20.398438 8.019531-28.289062.289062l-56.6875-55.527343c-3.839844-3.761719-6.003907-8.914063-6.007813-14.289063 0-5.375 2.164063-10.523437 6-14.289062l160.699219-157.535156c3.777344-3.714844 5.894531-8.722657 5.9375-14.066407.039062-5.347656-2.003906-10.386719-5.753906-14.191406l-9.304688-9.445313c-3.800781-3.859374-8.867188-5.984374-14.292969-5.964843-5.417969.011719-10.484375 2.152343-14.269531 6.027343l-192.421875 195.363282c-7.480469 7.664062-7.464844 20.21875.097656 27.925781l193.515625 196.65625c3.785156 3.855469 8.820313 5.984375 14.199219 6.007813 5.359375.03125 10.4375-2.054688 14.242187-5.859376l9.152344-9.152343c3.804688-3.804688 5.886719-8.867188 5.859375-14.25-.03125-5.386719-2.164062-10.425781-6.011719-14.191407l-35.941406-35.222656c-7.890625-7.730468-8.015625-20.398437-.28125-28.289062 7.730469-7.890625 20.398438-8.015625 28.289063-.285156l35.929687 35.214843c11.53125 11.289063 17.9375 26.40625 18.023438 42.558594.085937 16.148437-6.15625 31.332031-17.578125 42.753906l-9.148438 9.152344c-11.347656 11.339844-26.40625 17.578125-42.4375 17.578125zm0 0" />
                    </svg>
                  </StyledButton>
                ) : ''}
                <StyledButton type="button" style={{marginLeft: '5px'}} onClick={() => { setIsOpen(true); setLightboxSlideIndex(currentSlide) }}>
                  <svg style={{fill: 'currentColor', height: '1.25rem'}} viewBox="0 0 512 512">
                    <path d="M377,190h-55v-55c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v55h-55c-11.046,0-20,8.954-20,20s8.954,20,20,20h55			v55c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20v-55h55c11.046,0,20-8.954,20-20S388.046,190,377,190z" />		<path d="M302,0C186.206,0,92,94.206,92,210c0,50.944,18.234,97.709,48.519,134.112L5.906,477.81			c-7.837,7.784-7.88,20.447-0.097,28.284C9.719,510.03,14.859,512,20,512c5.095,0,10.192-1.936,14.093-5.81L168.89,372.31			C205.148,402.098,251.523,420,302,420c36.026,0,71.576-9.283,102.804-26.846c9.628-5.414,13.043-17.608,7.628-27.236			c-5.414-9.627-17.608-13.044-27.236-7.628C359.942,372.493,331.173,380,302,380c-93.738,0-170-76.262-170-170S208.262,40,302,40			s170,76.262,170,170c0,29.531-7.683,58.609-22.217,84.091c-5.473,9.595-2.131,21.809,7.463,27.282			c9.595,5.472,21.809,2.131,27.282-7.463C502.5,282.4,512,246.469,512,210C512,94.206,417.794,0,302,0z" />
                  </svg>
                </StyledButton>
                {slides.length > 1 ? (
                  <StyledButton type="button" style={{marginLeft: '5px'}} onClick={() => { setSlide((currentSlide + 1) % slidesData.length) }}>
                    <svg style={{fill: 'currentColor', height: '1.25rem'}} viewBox="-95 0 512 512">
                      <path d="m69.152344 512c-16.027344 0-31.085938-6.234375-42.425782-17.578125l-9.152343-9.148437c-11.417969-11.421876-17.6601565-26.601563-17.574219-42.753907.0859375-16.148437 6.492188-31.265625 18.035156-42.5625l147.238282-143.5625-146.300782-144.195312c-11.414062-11.238281-17.773437-26.261719-17.894531-42.292969s6.007813-31.144531 17.257813-42.566406l9.300781-9.445313c11.40625-11.574219 26.640625-17.89843725 42.882812-17.894531 16.25.0351562 31.449219 6.457031 42.800781 18.085938l192.457032 196.246093c11.0625 11.328125 17.125 26.246094 17.105468 42.035157-.023437 15.789062-6.125 30.6875-17.183593 41.953124l-58.875 59.707032c-7.757813 7.863281-20.421875 7.953125-28.289063.195312-7.863281-7.753906-7.953125-20.417968-.195312-28.285156l58.839844-59.671875c7.535156-7.671875 7.550781-20.226563.007812-27.953125l-192.457031-196.25c-3.816407-3.90625-8.882813-6.046875-14.300781-6.058594-5.429688-.035156-10.492188 2.105469-14.292969 5.964844l-9.300781 9.445312c-3.753907 3.804688-5.792969 8.84375-5.753907 14.1875.042969 5.34375 2.160157 10.351563 5.96875 14.101563l160.835938 158.523437c3.828125 3.773438 5.972656 8.925782 5.960937 14.300782-.015625 5.371094-2.191406 10.515625-6.039062 14.265625l-161.820313 157.78125c-3.820312 3.738281-5.953125 8.777343-5.984375 14.160156-.027344 5.382813 2.050782 10.445313 5.859375 14.25l9.148438 9.152344c3.804687 3.800781 8.863281 5.871093 14.242187 5.859375 5.375-.027344 10.414063-2.152344 14.179688-5.988282.03125-.035156.0625-.066406.09375-.097656l64.363281-64.675781c7.792969-7.832031 20.457031-7.863281 28.285156-.070313 7.832031 7.792969 7.863281 20.457032.070313 28.285157l-64.316406 64.632812c-11.292969 11.480469-26.382813 17.84375-42.492188 17.917969-.09375 0-.191406 0-.285156 0zm0 0" />
                    </svg>
                  </StyledButton>
                ) : ''}
              </StyledButtonContainer>
            )}

            {slideOverlayBottom && slides[currentSlide] ? (
              <StyledSlideOverlay inFront={!zooming}>
                {slideOverlayBottom(currentSlide, setSlide)}
              </StyledSlideOverlay>
            ): ''}

            {slides.length > 1 ? (
              <Dots
                totalSlides={slides.length}
                currentSlide={currentSlide}
                centerDots={slides.length < 6 ? slides.length : undefined}
                dotColor={dotColor}
                activeDotColor={activeDotColor}
              />
            ) : (
              <div className="mb-2"></div>
            )}
          </StyledSliderContainer>
        )}
      </Measure>
      {isOpen && (
        <Lightbox
          mainSrc={slidesData[lightboxSlideIndex].imageUrl || slidesData[lightboxSlideIndex].url}
          nextSrc={slidesData[(lightboxSlideIndex + 1) % slidesData.length].thumbUrl || slidesData[(lightboxSlideIndex + 1) % slidesData.length].url}
          prevSrc={slidesData[(lightboxSlideIndex + slidesData.length - 1) % slidesData.length].thumbUrl || slidesData[(lightboxSlideIndex + slidesData.length - 1) % slidesData.length].url}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            let prevIndex = (lightboxSlideIndex + slidesData.length - 1) % slidesData.length
            setLightboxSlideIndex(prevIndex)
            setSlide(prevIndex)
          }}
          onMoveNextRequest={() => {
            let nextIndex = (lightboxSlideIndex + 1) % slidesData.length
            setLightboxSlideIndex(nextIndex)
            setSlide(nextIndex)
          }}
        />
      )}
    </>
  )

  return (
    children ? children({ 
      InnerSlider,
      currentSlide,
      setSlide,
      dimensions
    }) : (InnerSlider)
  )
}

Slider.propTypes = {
  /** List of slides to render */
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
  slidesData: PropTypes.arrayOf(PropTypes.any),
  /** Maximum zoom level */
  maxScale: PropTypes.number,
  /** Minimum zoom level */
  minScale: PropTypes.number,
  /** Content to overlay on the slider */
  slideOverlay: PropTypes.func,
  slideOverlayBottom: PropTypes.func,
  /** Time in ms until the slide indicator fades out. Set to `null` to disable this behavior. */
  slideIndicatorTimeout: PropTypes.number,
  /** Pagination dot color for the active slide */
  activeDotColor: PropTypes.string,
  /** Pagination dot color for all other slides */
  dotColor: PropTypes.string,
  showButtons: PropTypes.bool
}

Slider.defaultProps = {
  maxScale: defaultProps.maxScale,
  minScale: defaultProps.minScale,
  slideOverlay: null,
  slideOverlayBottom: null,
  slideIndicatorTimeout: defaultProps.slideIndicatorTimeout,
  activeDotColor: defaultProps.activeDotColor,
  dotColor: defaultProps.dotColor,
  showButtons: false
}
