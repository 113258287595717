import { useEffect,useState } from "react";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import config from "../../config";

import dayjs from 'dayjs';
import Model from "../../libs/ModelClass";
import { useEntityFullBySlug } from "../entity/Entity";
import EntityFilterMenuDisplay from "../../components/EntityFilterMenuDisplay";
import dataToQueryFormatter from "../../components/Filters/dataToQueryFormatter";
import BtnLoading from "../../components/ui/BtnLoading";
import EntityGetOneDocument from "../../components/EntityGetOneDocument";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { priceFormat } from "../../libs/utils";

const minimunDaysFromLastBilling = 7;
const filterMenuSlug = "crud";

const BillingsModel = Model.extend('customerBillings');
const InstitutionsModel = Model.extend('institutions');
const CartsModel = Model.extend(config?.modules?.cart?.cartsEntitySlug);

const ListCustomersNextBillings = ({ user, isAllowed, onShow, history }) => {
  const { entityDoc } = useEntityFullBySlug({ entitySlug: 'institutions' });
  const [ processedDocs, setProcessedDocs ] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    fetchFilteredDocs();
  }, []);

  const fetchFilteredDocs = async () => {
    setLoading(true);
    let billingsDocs = await BillingsModel.getAll();
    billingsDocs = billingsDocs.filter(doc => !doc.data?.deleted);
    billingsDocs = billingsDocs.sort((a, b) => new Date(b.data.createdAt) - new Date(a.data.createdAt));
    let institutionsDocs = await InstitutionsModel.getAll();
    institutionsDocs = institutionsDocs.filter(doc => doc.data.deleted !== true);
    let processedDocs = [];
    for (const institutionDoc of institutionsDocs) {
      // conectar última orden de cobro con cada institution
      let billings = billingsDocs.filter(doc => doc.data.institutionId === institutionDoc.id);
      let lastBilling = billings[0];
      // agregar a la lista si está pagado la orden reciente
      if (lastBilling) {
        if (lastBilling.data.isPaid) {
          const daysSince = dayjs().diff(dayjs(lastBilling.data.paidDate), 'days');
          processedDocs.push({
            institutionDoc,
            lastBilling,
            daysSince
          });
        }
      }
      // si no tiene pagos aún, agregar mostrando la fecha del primer cart
      if (!billings.length) {
        let cartDocs = await CartsModel.filterByAttributes({ institutionId: institutionDoc.id });
        cartDocs = cartDocs.filter(doc => !doc.data?.deleted);
        // old first
        cartDocs = cartDocs.sort((a, b) => new Date(a.data.createdAt) - new Date(b.data.createdAt));
        let firstCart = cartDocs[0];
        if (firstCart) {
          const daysSince = dayjs().diff(dayjs(firstCart.data.createdAt), 'days');
          processedDocs.push({
            institutionDoc,
            firstCart,
            daysSince
          });
        }
      }
    }
    processedDocs = _.sortBy(processedDocs, ['daysSince']).reverse().filter(item => item.daysSince >= minimunDaysFromLastBilling);
    setProcessedDocs(processedDocs);
    setLoading(false);
  };

  const handleCreate = ({ institutionDoc, lastBilling, firstCart }) => {
    let minDate = lastBilling 
      ? lastBilling.data.dateTo 
      // to include the first cart
      : dayjs(firstCart.data.createdAt).subtract(1, 'day');
    history.push(`/admin/customerBilling/form/id/new/institutionId/${institutionDoc.id}/minDate/${minDate}`);
  };

  const DaysSince = ({ days }) => (
    days >= 21 ? (
      <span className="px-2 py-0.5 rounded-full bg-red-500 text-white font-semibold">{days}</span>
    ) : (
      days >= 15 ? (
        <span className="px-2 py-0.5 rounded-full bg-amber-500 text-white font-semibold">{days}</span>
      ) : (
        days >= 7 ? (
          <span className="px-2 py-0.5 rounded-full bg-gray-500 text-white font-semibold">{days}</span>
        ) : (
          days
        )
      )
    )
  );

  const ListItems = () => {
    return (
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
        {processedDocs?.map(({
          institutionDoc,
          lastBilling,
          firstCart, 
          daysSince
        }, index) => (
          <div className={`
            p-2 border border-gray-200 rounded-md
          `} key={institutionDoc.id}>
            <div className="">
              {isAllowed('institutions', ['list']) ? (
                <Link to={`/admin/entity/${entityDoc?.id}/${institutionDoc?.id}`} className="block text-sm text-gray-900 underline">{institutionDoc.data?.name}</Link>
              ) : (
                <div className="text-sm text-gray-900">{institutionDoc.data?.name}</div>
              )}
              <div className="text-xs text-gray-900">
                
              </div>
              {lastBilling?.data?.paidDate ? (<>
                <div className="mt-1 text-xs text-gray-500">
                  <DaysSince days={daysSince} />
                  <span className="pl-1.5">días desde último cobro</span>
                </div>
                <div className="text-xs text-gray-500">
                  Último pago: <br /> {dayjs(lastBilling.data?.paidDate).utc().format('ll')}
                </div>
              </>) : null}
              {firstCart?.data?.createdAt ? (<>
                <div className="mt-1 text-xs text-gray-500">
                  <DaysSince days={daysSince} />
                  <span className="pl-1.5">días desde el primer pedido</span>
                </div>
                <div className="mt-1 text-xs text-gray-500">
                  Primer pedido: <br /> {dayjs(firstCart.data?.createdAt).utc().format('lll')}
                </div>
              </>) : null}
            </div>
            <div className="mt-1">
              <BtnLoading label="Registrar órden" onClick={() => handleCreate({ institutionDoc, lastBilling, firstCart })} className="px-2 py-0.5 text-gray-800 bg-brand-yellow/80 hover:scale-105 shadow-sm text-xs" />
            </div>
          </div>
        ))}
      </div>
    )
  };

  return (
    <div className="mt-4">
      {(loading || !processedDocs) ? (
        <div className="pt-10 flex place-content-center content-center items-center">
          <BadgeLoading size="md" />
        </div>
      ) : (<>
        {(processedDocs?.length) ? (<>
          <h2 className="mb-2 mt-4 lg:mt-0 text-base font-semibold text-black">
            Días desde el último cobro
          </h2>
          <ListItems />
        </>) : (
          <div className="text-center"></div>
        )}
      </>)}
    </div>
  );
};

export const ListDocumentsByState = ({
  user,
  isAllowed,
  onShow,
  showFilters = true,
  fixFilters = {}
}) => {
  const { filterMenuTaxonomyTypes } = useEntityFullBySlug({ entitySlug: config?.modules?.cart?.cartsEntitySlug, filterMenuSlug });
  const [filtersSelected, setFiltersSelected] = useState({});
  const [docs, setDocs] = useState({});
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    filterMenuTaxonomyTypes && fetchFilteredDocs();
  }, [filterMenuTaxonomyTypes, filtersSelected]);

  const fetchFilteredDocs = async () => {
    setLoading(true);
    const filterQuery = dataToQueryFormatter({ ...filtersSelected, ...fixFilters }, filterMenuTaxonomyTypes);
    let filteredDocs = await BillingsModel.filterByAttributes(filterQuery);
    filteredDocs = filteredDocs.filter(doc => !doc.data?.deleted);
    // old first
    let pending = filteredDocs.filter(doc => !doc.data.isPaid).sort((a, b) => new Date(a.data.createdAt) - new Date(b.data.createdAt));
    // recent paid first
    let paid = filteredDocs.filter(doc => doc.data.isPaid).sort((a, b) => new Date(b.data.paidDate) - new Date(a.data.paidDate));

    setDocs({ pending, paid });
    setLoading(false);
  };

  const ListItems = ({ items }) => {
    return (
      <div className="px-2 border border-gray-200 rounded-md">
        {items?.map((item, index) => (
          <div className={`
            flex flex-row place-content-between
            py-2 border-b border-gray-200 last:border-none 
          `} key={item.id}>
            <div className="">
              {/* Institutción */}
              <EntityGetOneDocument
                entitySlug={'institutions'}
                docId={item.data?.institutionId}>
                {({ doc, entityDoc }) => (
                  isAllowed('institutions', ['list']) ? (
                    <Link to={`/admin/entity/${entityDoc?.id}/${doc?.id}`} className="block text-sm text-gray-900 underline">{doc.data?.name}</Link>
                  ) : (
                    <div className="text-sm text-gray-900">{doc.data?.name}</div>
                  )
                )}
              </EntityGetOneDocument>
              <div className="text-sm text-gray-600">
                {item?.data?.total ? priceFormat(item.data.total) : null}
              </div>
              <div className="text-xs text-gray-900">
                Desde: {dayjs(item.data?.dateFrom).format('DD-MM-YYYY')}
                <br />
                Hasta: {dayjs(item.data?.dateTo).format('DD-MM-YYYY')}
              </div>
              <div className="text-xs text-gray-500">
                Registrado: {dayjs(item.data?.createdAt).utc().format('lll')}
              </div>
              {item.data?.paidDate ? (
                <div className="text-xs text-gray-500">
                  Pagado: {dayjs(item.data?.paidDate).utc().format('lll')}
                </div>
              ) : null}
            </div>
            <div>
              {isAllowed('customerBillings', ['read', 'group:read']) ? (
                <BtnLoading label="Ver" onClick={() => onShow(item)} withLoading className="px-2 py-0.5 text-gray-800 hover:scale-105 bg-gray-200 shadow-none text-xs" />
              ) : null}
            </div>
          </div>
        ))}
      </div>
    )
  };

  return (
    <div className={`mt-4 flex flex-col ${showFilters ? 'lg:flex-row': ''}`}>
      {showFilters ? (
        <div className="lg:w-2/5">
          <EntityFilterMenuDisplay
            entitySlug={config.modules.cart.cartsEntitySlug}
            filterMenuSlug={filterMenuSlug}
            filterData={filtersSelected}
            onFormChange={(formValues) => {
              setFiltersSelected(formValues);
            }}
            onFormClear={() => {
              setFiltersSelected(null);
            }}
            style={'search-collapsible-panel'}
            paramsByFieldSlug={{
              'createdAt': {
                title: 'Fecha de registro'
              },
              'userId': {
                optionsMapper: (docs) => docs.map((option) => {
                  let label = option?.data?.name;
                  if (option?.data?.ci) {
                    label = label + `, CI: ${option?.data?.ci}`;
                  }
                  if (option?.data?.phone) {
                    label = label + `, ${option?.data?.phone}`;
                  }
                  if (option?.data?.email) {
                    label = label + `, ${option?.data?.email}`;
                  }
                  return {
                    value: option?.id,
                    label
                  };
                })
              },
              'institutionId': {
                optionsMapper: (docs) => docs.map((option) => ({
                  value: option?.id,
                  label: `${option?.data?.name} | ${option?.data?.city || ''}, ${option?.data?.location || ''}`
                }))
              }
            }}
          />
        </div>
      ) : null}

      <div className={`${showFilters ? 'lg:w-3/5' : ''} lg:ml-4`}>
        {(loading || !_.size(docs)) ? (
          <div className="pt-10 flex place-content-center content-center items-center">
            <BadgeLoading size="md" />
          </div>
        ) : (<>
          {(docs.hasOwnProperty('pending') && docs.hasOwnProperty('paid')) ? (<>
            {docs?.pending?.length && (<>
              <h2 className="mt-4 lg:mt-0 text-base font-semibold text-brand-red">Pendientes</h2>
              <h3 className="mb-2 text-xs text-gray-500">Documentos más antiguos primero</h3>
              <ListItems items={docs?.pending} />
            </>) || null}
            {docs?.paid?.length && (<>
              <h2 className="mt-4 text-base font-semibold text-gray-900">Pagados</h2>
              <h3 className="mb-2 text-xs text-gray-500">Documentos pagados recientemente primero</h3>
              <ListItems items={docs?.paid} />
            </>) || null}
          </>) : (
            <div className="text-center">
              No hay documentos
            </div>
          )}
        </>)}
      </div>
    </div>
  );
};

export const RouteCustomerBillingList = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {  
  const handleAdd = () => {
    history.push('/admin/customerBilling/form/id/new');
  };

  const onShow = (doc) => {
    history.push('/admin/customerBilling/show/id/' + doc?.id);
  };

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin/customerBilling/list`}
      title="Cobros a Clientes"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        title: 'Cobros'
      }]}
    >
      <div className="ion-padding">
        <BtnLoading label="Agregar" onClick={handleAdd} className="px-2 py-1 !text-black hover:scale-105 bg-brand-yellow/80" />

        <ListCustomersNextBillings {...{ user, isAllowed, onShow, history }} />

        <div className="my-8 border-b border-gray-300"></div>

        <ListDocumentsByState {...{ user, isAllowed, onShow, history }} />
      </div>
    </LayoutAdmin>
  );
};