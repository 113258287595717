import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import { CustomerBillingShow } from "./RouteCustomerBillingShow";


export const RouteCustomerBillingGroupShow = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {   
  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin/customerBilling/groupList`}
      title="Pago de la Institutición"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        url: '/admin/customerBilling/groupList',
        title: 'Pagos'
      }, {
        title: 'Ver pago'
      }]}
    >
      <div className="ion-padding">
        {parsedParams?.id ? (<CustomerBillingShow docId={parsedParams?.id} {...{ user, isAllowed, history }} />) : null}
      </div>
    </LayoutAdmin>
  );
};