import { useState } from 'react';
import { LayoutAdmin } from "../components/LayoutAdmin";
import SectionCrudForm from "../components/SectionCrudForm";
import Model, { useStateSingleResult } from "../libs/ModelClass";
import useEntityTaxonomyForm from '../components/EntityTaxonomyForm';
import PartOfModule from '../components/Module/PartOfModule';
import { useModule } from '../context/ModuleContext';

export function Content({ entity, history, match }) {
  const entitySlug = entity?.data?.nameSlug;
  const ExtendedModel = Model.extend(entitySlug);
  const { docId } = match.params;
  const { fireEventReducer } = useModule();
  const doc = useStateSingleResult({
    Model: ExtendedModel, 
    id: docId
  });
  
  const [fieldsRequired, setFieldsRequired] = useState([]);
  const { taxonomyTypesDocList, EntityTaxonomyForm } = useEntityTaxonomyForm({ 
    entityId: entity?.id,
    entitySlug,
    fieldsRequired, 
    setFieldsRequired
  });

  const handleSave = async (formValues) => {
    const modifiedFormValues = await fireEventReducer('beforeSave', { entitySlug, taxonomyTypesDocList }, formValues);
    const savedItem = await ExtendedModel.createOrUpdate(modifiedFormValues);
    await fireEventReducer('afterSave', { entitySlug, taxonomyTypesDocList }, savedItem);
    if (docId === 'new') {
      // force save again to ensure that the id is added as a field
      await savedItem.save();
    }
    history.push(`/admin/entity/${entity?.id}/${savedItem.id}`);
  };

  const onValidation = (values, errors) => {
    // all required fields
    fieldsRequired.forEach(field => {
      if (!values[field]) {
        errors[field] = ' '; 
      }
    });
    // [TODO] realiar validación de taxonomías segú tipo y param
  };

  const FormInputFields = (({ values }) => (<>
    <EntityTaxonomyForm
      values={values}
      overrideParams={{
        showLabel: true,
        showClearBtn: true,
        style: {
          classes: { 
            fieldContainer: 'flex-grow',
            fieldLabel: 'block mb-2 flex flex-row place-content-between items-center h-6',
            fieldInput: 'block w-full',
          }
        }
      }}
    />
    <PartOfModule
      type="crud"
      action="UnderForm"
      entitySlug={entitySlug}
      param={{ values }} 
    />
  </>));

  return (
    <div className="">
      {/* Entity Form */}
      <div className="p-3 rounded-md border border-gray-200 shadow-md hover:shadow-lg ">
        {doc ? (
          <SectionCrudForm
            doc={doc}
            onSave={handleSave}
            onValidation={onValidation}
            fieldsRequired={fieldsRequired}
            FormInputFields={FormInputFields}
            editStyle="route"
          />
        ) : null}
      </div>
    </div>
  );
}

export function AdminEntityCrudForm(props) {
  const { entityId } = props.match.params;
  const entity = useStateSingleResult({
    Model: Model.extend('entities'), 
    id: entityId
  });

  let breadcrumbs = [{
    url: '/admin/entity',
    title: 'Listados'
  }];
  if (entity?.data?.showInMenu) {
    breadcrumbs.push({
      url: `/admin/entity/${entityId}`,
      title: entity?.data?.name
    });
  }
  breadcrumbs.push({
    title: "Formulario"
  });

  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={entity?.data?.showInMenu ? `/admin/entity/${entityId}` : `/admin/entity`}
      breadcrumbs={breadcrumbs}
    >
      <div className="ion-padding">
        {entity && <Content entity={entity} {...props} />}
      </div>
    </LayoutAdmin>
  );
}
