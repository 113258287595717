import { useEffect,useState } from "react";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import { numberFormat, priceFormat } from "../../libs/utils";
import config from "../../config";

import dayjs from 'dayjs';
import Model from "../../libs/ModelClass";
import { useEntityFullBySlug } from "../entity/Entity";
import EntityFilterMenuDisplay from "../../components/EntityFilterMenuDisplay";
import dataToQueryFormatter from "../../components/Filters/dataToQueryFormatter";
import BtnLoading from "../../components/ui/BtnLoading";
import EntityGetOneDocument from "../../components/EntityGetOneDocument";
import BadgeLoading from "../../components/ui/BadgeLoading";
import useGPS from "../panel/useGPS";

const filterMenuSlug = "crud";

const DeliveryPacksModel = Model.extend('deliveryPacks');


const ListItemsFiltered = ({ user, isAllowed, onShow }) => {
  const { filterMenuTaxonomyTypes } = useEntityFullBySlug({ entitySlug: config?.modules?.cart?.cartsEntitySlug, filterMenuSlug });
  const [deliveryPacksDocs, setDeliveryPacks] = useState({});
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    filterMenuTaxonomyTypes && fetchFilteredDocs();
  }, [filterMenuTaxonomyTypes]);

  const fetchFilteredDocs = async () => {
    setLoading(true);
    let filteredDocs = await DeliveryPacksModel.filterByAttributes({ userId: user.userDoc.id });
    filteredDocs = filteredDocs.filter(doc => !doc.data?.deleted);
    filteredDocs = filteredDocs.sort((a, b) => new Date(b.data.createdAt) - new Date(a.data.createdAt));

    setDeliveryPacks({
      pending: filteredDocs.filter(doc => !doc.data.delivered),
      delivered: filteredDocs.filter(doc => doc.data.delivered),
    });
    setLoading(false);
  };

  const ListItems = ({ items }) => {
    return (
      <div className="px-2 border border-gray-200 rounded-md">
        {items?.map((item, index) => (
          <div className={`
            flex flex-row place-content-between
            py-2 border-b border-gray-200 last:border-none 
          `} key={item.id}>
            <div className="">
              {/* <span className="text-xl font-semibold tracking-tighter">{item?.data?.delivered ? 'entregado' : 'pendiente'}</span> */}
              <span className="text-xs font-semibold text-gray-900">{dayjs(item.data?.createdAt).utc().format('LL')}</span>
              <span className="ml-2 text-xs font-mono text-gray-500">{item.id}</span>
            </div>
            <div>
              <BtnLoading label="Ver" onClick={() => onShow(item)} withLoading className="px-2 py-0.5 text-gray-800 hover:scale-105 bg-gray-200 shadow-none text-sm" />
            </div>
          </div>
        ))}
      </div>
    )
  };

  return (<>
    {(loading || !_.size(deliveryPacksDocs)) ? (
      <div className="pt-10 flex place-content-center content-center items-center">
        <BadgeLoading size="md" />
      </div>
    ) : (<>
      {(
        deliveryPacksDocs.hasOwnProperty('pending') && deliveryPacksDocs.hasOwnProperty('delivered')
        && (deliveryPacksDocs.pending.length || deliveryPacksDocs.delivered.length)
      ) ? (<>
        <div className="text-center text-xs text-gray-400">
          Órdenes de entregas asignadas al conductor
        </div>
        {deliveryPacksDocs?.pending?.length && (<>
          <h2 className="mb-2 mt-4 text-lg font-semibold text-brand-red">Pendientes</h2>
          <ListItems items={deliveryPacksDocs?.pending} />
        </>) || null}
        {deliveryPacksDocs?.delivered?.length && (<>
          <h2 className="mb-2 mt-4 text-lg font-semibold text-gray-900">Entregados</h2>
          <ListItems items={deliveryPacksDocs?.delivered} />
        </>) || null}
      </>) : (
        <div className="text-center">
          Sin itinerarios asignados a usted
        </div>
      )}
    </>)}
  </>);
};


export const RouteDeliveryPendingOrders = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {  
  const onShow = (doc) => {
    history.push('/admin/delivery/showOrder/id/' + doc.id);
  };

  const { gpsLocation, gpsError } = useGPS();

  console.log('GPS', gpsLocation, gpsError);

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin`}
      title="Itinerarios de entregas"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        title: 'Envíos pendientes'
      }]}
    >
      <div className="ion-padding">
        <div className="mt-4">
          <ListItemsFiltered {...{ user, isAllowed, onShow }} />
        </div>
      </div>
    </LayoutAdmin>
  );
};