import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import { useAuth } from '../../modules/user/AuthContext';
import { useEffect, useState } from 'react';
import Model from '../../libs/ModelClass';
import { UserDataCard } from './UserDataCard';
import { IonButton, IonIcon, IonLabel, useIonViewDidEnter } from '@ionic/react';
import { useLocation } from 'react-router-dom';


export function Content() {
  const { user } = useAuth();
  const { location } = useLocation();
  const [ userData ] = useState(user.userDoc.data);
  const [ selectedInstitution, setSelectedInstitution ] = useState();
  
  useEffect(() => {
    fetchSelectedInstitution();
  }, [user]);

  useIonViewDidEnter(async () => {
    await fetchSelectedInstitution();
  }, []);
  
  const fetchSelectedInstitution = async () => {
    if (selectedInstitution) { return; }
    const InstitutionModel = Model.extend('institutions');
    if (user?.userDoc?.data?.institutionId) {
      const doc = await InstitutionModel.findById(user?.userDoc?.data?.institutionId);
      if (doc.data.deleted) {
        throw new Error('Institución eliminada');
      }
      setSelectedInstitution(doc);
    }
  };

  return (
    <div>
      <UserDataCard {...{userData, selectedInstitution}} />

      <div className="flex place-content-center mt-10">
        <IonButton routerLink="/admin/user/update-data" routerDirection="forward" color="primary" size="medium">
          {/* <IonIcon icon={restaurant} className="text-lg"></IonIcon> */}
          <IonLabel className="ml-2">
            Actualizar datos
          </IonLabel>
        </IonButton>
      </div>
    </div>
  );
}


export function AdminUserCustomerData(props) {
  const { user } = useAuth();

  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={`/admin`}
      title="Mi Perfil"
      // breadcrumbs={[{
      //   url: '/admin',
      //   title: 'Panel'
      // }, {
      //   title: 'Mi Perfil'
      // }]}
    > 
      <div className="ion-padding">
        {user?.userDoc?.id ? <Content/> : null}
      </div>
    </LayoutAdmin>
  );
}
